import React, { useCallback, useEffect, useRef, useState } from "react";

// import RecordingNotification from "./recordingNotification";
// import ViewerMenuList from "./viewerMenuList";
import { defaultCustomParameter } from "../utils/constants";
import Highlighter from "../atoms/highlighter";
import Frame from "../atoms/frame";
import ParticipantsArea from "../atoms/ParticipantsArea";
// import NameArea from "../atoms/nameArea";
// import NoVideoCamImage from "../atoms/noVideoCamImage";
// import MenuIcon from "../atoms/menuIcon";

// 操作がないときメニューアイコンが隠れるまでの ms (3.5 秒)
const MENU_ICON_HIDE_MS = 3.5 * 1000;

interface ViewerProps {
  connectionId: string;
  username?: string;
  participants?: number;
  width: number;
  height: number;
  isScreenShare?: boolean;
  visibleNoVideoCam?: boolean;
  disableMic?: boolean;
  isTheta?: boolean;
  isSelf?: boolean;
  onDoubleClick?: () => void;
  children: React.ReactNode;
}

const useMenu = () => {
  const [visibleMenuIcon, setVisibleMenuIcon] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  // メニューアイコンを一定時間の操作がない場合に非表示にするためのタイマー
  // ref object として扱うため、redux の store ではなく、component 内で管理している
  const menuIconTimer = useRef<number | null>(null);

  const clearMenuIconTimer = useCallback(() => {
    if (menuIconTimer.current) {
      window.clearTimeout(menuIconTimer.current);
      menuIconTimer.current = null;
    }
  }, [menuIconTimer.current]);

  const showMenuIcon = useCallback((): void => {
    // 設定でメニューボタンを非表示にしている場合は、表示しない
    if (defaultCustomParameter.isHiddenVideoMenuButton) return;

    setVisibleMenuIcon(true);
    clearMenuIconTimer();
    menuIconTimer.current = window.setTimeout(() => {
      setVisibleMenuIcon(false);
    }, MENU_ICON_HIDE_MS);
  }, [menuIconTimer.current, clearMenuIconTimer]);

  const openMenu = useCallback(() => {
    setIsOpenMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpenMenu(false);
  }, []);

  useEffect(() => {
    return () => {
      clearMenuIconTimer();
    };
  }, []);

  return { visibleMenuIcon, isOpenMenu, showMenuIcon, openMenu, closeMenu };
};

const Viewer: React.FC<ViewerProps> = (props) => {
  const {
    connectionId,
    onDoubleClick,
    width,
    height,
    children,
    // isTheta,
    // isScreenShare,
    // visibleNoVideoCam,
    // disableMic,
    // username,
    participants,
  } = props;
  // const { visibleMenuIcon, isOpenMenu, showMenuIcon, openMenu, closeMenu } = useMenu();
  // const { isOpenMenu, showMenuIcon, closeMenu } = useMenu();
  const { showMenuIcon } = useMenu();
  const viewerRef = useRef<HTMLDivElement | null>(null);
  // const intervalRef = useRef<number | null>(null);

  {
    /* e: React.MouseEvent<HTMLElement> |  */
  }
  // const handleClickMenuIcon = (
  //   e: React.PointerEvent<HTMLElement> | React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  // ): void => {
  //   e.preventDefault();
  //   openMenu();
  // };

  if (width === 0) {
    return null;
  }

  return (
    <Highlighter connectionId={connectionId}>
      <Frame
        width={width}
        height={height}
        onDoubleClick={onDoubleClick}
        onTouchEnd={showMenuIcon}
        onMouseMove={showMenuIcon}
        ref={viewerRef}
      >
        {children}
        {/* {visibleNoVideoCam && <NoVideoCamImage width={width} height={height} />} */}
        {/* <RecordingNotification connectionId={connectionId} /> */}
        {/* {visibleMenuIcon && <MenuIcon onClick={handleClickMenuIcon} />} */}
        {/* <ViewerMenuList
          connectionId={connectionId}
          isOpen={isOpenMenu}
          isTheta={isTheta}
          onClose={closeMenu}
          parentRef={viewerRef}
          intervalRef={intervalRef}
          isImage={username && username === "image"}
        /> */}
        <ParticipantsArea height={height} participants={participants} />
        {/* <NameArea username={username || ""} disableMic={disableMic} isScreenShare={isScreenShare} /> */}
      </Frame>
    </Highlighter>
  );
};

export default Viewer;
