import React from "react";
import { styled, Button, ButtonProps } from "@mui/material";

const CustomizedButton = styled(Button)<CustomButtonProps>(({ theme, ...props }) => ({
  margin: props.margin ?? "0",
  width: props.width ?? "",
  height: "2.7em",
  "&:hover": {
    opacity: "0.9",
    // HACK:Mui のButton コンポーネントの variant="contained"はデフォルトの背景色が黒なので、ホバー時も黒みがかった色になっている。
    // ホバー時は黒ではなく、設定した色にしたいので、テーマでのオーバーライドを試したができなかったのでここで指定。dark,lightモード機能を追加する場合は別途修正する
    backgroundColor: theme.palette[props.color].main,
  },
  "&:active": {
    boxShadow: "none",
    transform: "translateY(1px)",
  },
}));

interface CustomButtonProps extends ButtonProps {
  margin?: string;
  color: "primary" | "secondary" | "error";
  width?: string;
}

const CustomButton: React.FC<CustomButtonProps> = React.memo(({ children, ...rest }) => {
  return (
    <CustomizedButton variant="contained" {...rest}>
      {children}
    </CustomizedButton>
  );
});

export default CustomButton;
