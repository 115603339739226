import { PayloadAction } from "@reduxjs/toolkit";
import { Client, LSTrack } from "ricoh-ls-sdk";
import { DUMMY_DEVICE_ID } from "../../utils/constants";
import { isDebugPodUser, isDebugThetaUser, stopStream } from "../../utils/liveStreamingHelpers";
import { MainState, initialMainState } from "../slice";

export const startConnect = (state: MainState, action: PayloadAction<{ clientId: string; connectionId: string }>): void => {
  const { clientId, connectionId } = action.payload;
  state.clientId = clientId;

  const newSelf = state.self;
  newSelf.connectionId = connectionId;
  state.self = newSelf;

  stopStream(state.immutable.localVideoAudioStream);
  state.immutable.videoAudioClient && state.immutable.videoAudioClient.disconnect();
  state.immutable.localVideoAudioStream = null;
  state.immutable.videoAudioClient = null;
};

export const successConnect = (
  state: MainState,
  action: PayloadAction<{
    stream: MediaStream;
    username: string;
    connectionId: string;
    client: Client;
    tracks: LSTrack[];
    useDummyDevice: boolean;
    enableAudio: boolean;
    enableVideo: boolean;
  }>
): void => {
  const { stream, username, connectionId, client, tracks, useDummyDevice, enableAudio, enableVideo } = action.payload;

  const audioStreams = new Map(state.immutable.audioStreams);
  const videoStreams = new Map(state.immutable.videoStreams);

  // mediaStreams に set する
  audioStreams.set(connectionId, new MediaStream([...stream.getAudioTracks()]));
  videoStreams.set(connectionId, new MediaStream([...stream.getVideoTracks()]));

  const newSelf = {
    ...state.self,
    username: username,
    connectionId: connectionId,
    enableAudio: enableAudio,
    enableVideo: enableVideo,
    isTheta: isDebugThetaUser(username),
    isPod: isDebugPodUser(username),
  };
  state.self = newSelf;

  state.isJoined = true;

  state.useDummyDevice = useDummyDevice;
  if (useDummyDevice) {
    state.audioDeviceId = DUMMY_DEVICE_ID;
    state.videoDeviceId = DUMMY_DEVICE_ID;
  }

  state.immutable.localVideoAudioStream = stream;

  state.immutable.audioStreams = audioStreams;
  state.immutable.videoStreams = videoStreams;

  state.immutable.videoAudioClient = client;

  // main connection id がなければ自分を main connection id に設定する
  // TODO: なぜmainConnectionIdsにセットする必要があるのか要確認
  if (state.mainConnectionIds.length === 0) {
    state.mainConnectionIds = [connectionId];
  }

  state.immutable.localTracks = tracks;
};

export const failConnect = (state: MainState, action: PayloadAction<string>): void => {
  // const errorMessage = action.payload;
  // console.log("failed to connect Live Streaming Service, error =>", errorMessage);
  console.error("failed to connect Live Streaming Service");

  state.immutable.videoAudioClient && state.immutable.videoAudioClient.disconnect();
  stopStream(state.immutable.localVideoAudioStream);
  state.immutable.videoAudioClient = null;
  state.immutable.localVideoAudioStream = null;
};

export const stopConnect = (state: MainState): void => {
  stopStream(state.immutable.localVideoAudioStream);

  const mediaStreams = new Map(state.immutable.videoStreams);
  if (state.self.connectionId) {
    mediaStreams.delete(state.self.connectionId);
  }

  state.immutable.videoAudioClient && state.immutable.videoAudioClient.disconnect();
  state.immutable.videoAudioClient = null;
  state.immutable.localVideoAudioStream = null;
  state.immutable.videoStreams = mediaStreams;
  state.self.connectionId = null;
};

export const disconnect = (state: MainState): MainState => {
  // 録画中のMediaRecorderの停止
  state.immutable.mediaRecorders.forEach((mediaRecorder) => {
    if (mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }
  });

  state.immutable.localVideoAudioStream && stopStream(state.immutable.localVideoAudioStream);
  state.immutable.localScreenShareStream && stopStream(state.immutable.localScreenShareStream);
  state.immutable.videoAudioClient && state.immutable.videoAudioClient.disconnect();
  state.immutable.screenShareClient && state.immutable.screenShareClient.disconnect();

  // TODO: Android側から会議を切断し、再び入室すると、カメラ映像の設定（フロント側で設定した明るさや逆光補正）が初期化されている。（ハードのグラスも設定を覚えるケースがあるので初期化していた）
  // Android側の仕様に合わせて、接続時に設定をリセットする実装を別プルリクで行う。
  localStorage.removeItem("is_brightness_modeChecked");
  localStorage.removeItem("brightness");
  localStorage.removeItem("is_backlight_compensation");

  return { ...initialMainState };
};

export const setAutoDisconnectTimer = (state: MainState, action: PayloadAction<number>): void => {
  const timer = action.payload;
  state.autoDisconnectTimer = timer;
};

export const clearAutoDisconnectTimer = (state: MainState): void => {
  if (state.autoDisconnectTimer === null) return;

  window.clearTimeout(state.autoDisconnectTimer);
  state.autoDisconnectTimer = null;

  // console.log("Auto disconnect timer has been reset.");
};

export const updateIsReconnecting = (state: MainState, action: PayloadAction<{ isReconnecting: boolean }>): void => {
  const { isReconnecting } = action.payload;
  state.isReconnecting = isReconnecting;
};
