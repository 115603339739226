import React from "react";

import { useTranslation } from "react-i18next";
import { styled, Typography } from "@mui/material";

import { defaultCustomParameter } from "../utils/constants";

interface Props {
  height: number;
  participants?: number;
}

// TODO:bottomの値はレスポンシブ対応時に追加で修正。
const CustomizedDiv = styled("div")(() => ({
  backgroundColor: defaultCustomParameter.theme.components.video.textBackgroundColor,
  color: defaultCustomParameter.theme.components.video.textColor,
  position: "absolute",
  display: "flex",
  bottom: 109,
  left: 0,
  alignItems: "center",
}));

const ParticipantsArea: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <CustomizedDiv>
      <Typography variant="body1" style={{ margin: "6px 12px" }}>
        {t("subView.participant")}
        {props.participants}
        {t("subView.people")}
      </Typography>
    </CustomizedDiv>
  );
};
export default ParticipantsArea;
