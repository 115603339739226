import { PayloadAction } from "@reduxjs/toolkit";
import { ConnectionID } from "ricoh-ls-sdk";
import { SubView } from "../../../lib/@types/ls-conf-sdk";
import { MediaRecorders } from "../../utils/types";
import { MainState } from "../slice";

export const setVisibleRecordingSetting = (
  state: MainState,
  action: PayloadAction<{ connectionId: ConnectionID | null; visible: boolean }>
): void => {
  state.visibleRecordingSetting = action.payload.visible;
  state.recordingSettingConnectionId = action.payload.connectionId;
};

export const setMediaRecorder = (
  state: MainState,
  action: PayloadAction<{ connectionId: ConnectionID; mediaRecorder: MediaRecorder | null }>
): void => {
  const connectionId = action.payload.connectionId;
  const mediaRecorder = action.payload.mediaRecorder;
  const recorders: MediaRecorders = new Map(state.immutable.mediaRecorders);
  if (mediaRecorder) {
    recorders.set(connectionId, mediaRecorder);
  } else {
    recorders.delete(connectionId);
  }
  state.immutable.mediaRecorders = recorders;
};

export const setRecordingStartNotification = (
  state: MainState,
  action: PayloadAction<{ connectionId: ConnectionID; startNotification: boolean }>
): void => {
  const { connectionId, startNotification } = action.payload;
  const recordingSubViews = new Map(state.immutable.recordingSubViews);
  const recordingSubView = recordingSubViews.get(connectionId);
  if (recordingSubView) {
    recordingSubView.startNotification = startNotification;
    recordingSubViews.set(connectionId, recordingSubView);
  }
  state.immutable.recordingSubViews = recordingSubViews;
};

export const addRecordingMember = (
  state: MainState,
  action: PayloadAction<{ subView: SubView; connectionId: ConnectionID }>
): void => {
  const { subView, connectionId } = action.payload;

  // 録画対象SubView存在有無の確認
  let found = false;
  state.immutable.otherMembers.forEach((member) => {
    if (member.connectionId === subView.connectionId || member.screenShareConnectionId === subView.connectionId) {
      found = true;
    }
  });
  if (state.self.connectionId === subView.connectionId || state.self.screenShareConnectionId === subView.connectionId) {
    found = true;
  }

  if (!found) {
    // console.log(`Not found SubView: ${subView.connectionId}`);
    console.warn("Not found SubView");
    return;
  }

  // RecordingMember の追加
  const recordingSubViews = new Map(state.immutable.recordingSubViews);
  const recordingSubView = recordingSubViews.get(subView.connectionId);
  if (recordingSubView) {
    const exist = recordingSubView.connectionIds.includes(connectionId);
    if (!exist) {
      recordingSubView.connectionIds.push(connectionId);
      recordingSubViews.set(subView.connectionId, recordingSubView);
    }
  } else {
    recordingSubViews.set(subView.connectionId, { connectionIds: [connectionId], startNotification: true });
  }
  state.immutable.recordingSubViews = recordingSubViews;
  // console.log("Add recording member.");
};

export const removeRecordingMember = (
  state: MainState,
  action: PayloadAction<{ subView: SubView; connectionId: ConnectionID }>
): void => {
  const { subView, connectionId } = action.payload;
  let found = false;
  const recordingSubViews = new Map(state.immutable.recordingSubViews);
  const recordingSubView = recordingSubViews.get(subView.connectionId);
  if (recordingSubView) {
    const findIndex = recordingSubView.connectionIds.findIndex((id) => id === connectionId);
    if (findIndex !== -1) {
      recordingSubView.connectionIds.splice(findIndex, 1);
      recordingSubViews.set(subView.connectionId, recordingSubView);
      // connectionIds が空の場合は recordingSubView自体を削除する
      if (!recordingSubView.connectionIds.length) {
        recordingSubViews.delete(subView.connectionId);
      }
      state.immutable.recordingSubViews = recordingSubViews;
      found = true;
    }
  }

  if (found) {
    // console.log("Remove recording member.");
  } else {
    // console.log(`Not found Subview: ${subView.connectionId}`);
  }
};
