import React from "react";
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import i18n from "../../locales/i18n";

interface Props {
  onClose: () => void;
  message: string | null;
  open: boolean;
}

const GeneralDialog: React.FC<Props> = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogContent>{props.message}</DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{i18n.t("button.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralDialog;
