import React, { memo } from "react";
import { MenuItem } from "@mui/material";

import { ImageCursor } from "../utils/types";

interface Props {
  cursors: readonly ImageCursor[];
  onClickMenuItem: (cursor: ImageCursor) => void;
}

const CursorMenuItems: React.VFC<Props> = memo(({ cursors, onClickMenuItem }) => {
  return (
    <>
      {cursors.map((cursor, index) => (
        <MenuItem onClick={() => onClickMenuItem(cursor)} style={{ backgroundColor: "black" }} key={index}>
          <img src={cursor.src} height="40" />
          {index}
        </MenuItem>
      ))}
    </>
  );
});

export default CursorMenuItems;
