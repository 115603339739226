import { PayloadAction } from "@reduxjs/toolkit";
import { ConnectionID } from "ricoh-ls-sdk";
import { POV_STATE } from "../../utils/constants";
import { PoV, RoomMembers } from "../../utils/types";
import { MainState } from "../slice";

export const changeMainStatePoV = (
  state: MainState,
  action: PayloadAction<{ connectionId: ConnectionID; poVState: POV_STATE; poV?: PoV }>
): void => {
  const { connectionId, poV, poVState } = action.payload;
  const otherMembers: RoomMembers = new Map(state.immutable.otherMembers);
  const updateMember = otherMembers.get(connectionId);
  if (updateMember) {
    const newMemeber = { ...updateMember };
    newMemeber.poVState = poVState;
    if (poV) {
      newMemeber.poV = poV;
    }
    otherMembers.set(connectionId, newMemeber);
    state.immutable.otherMembers = otherMembers;
  } else if (state.self.connectionId === connectionId) {
    // 基本的にはselfがTHETA画像になることはないはずだが、デバッグ向けにコードを追加
    state.self.poVState = poVState;
    if (poV) {
      state.self.poV = poV;
    }
  }
};

export const setSharingPoV = (state: MainState, action: PayloadAction<ConnectionID>): void => {
  state.sharingPoV = action.payload;
};
