import * as CONST from "./constants";

export const isNumber = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return false;
  }
  return !isNaN(Number(value));
};

export const isBoolean = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return false;
  }
  return value === "false" || value === "true";
};

export const isValidBitrate = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return false;
  }
  return isNumber(value) && Number(value) >= CONST.BITRATE.MIN && Number(value) <= CONST.BITRATE.MAX;
};

export const isValidBitrateReservationMbps = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return false;
  }
  return (
    isNumber(value) && Number(value) >= CONST.BITRATE_RESERVATION_MBPS.MIN && Number(value) <= CONST.BITRATE_RESERVATION_MBPS.MAX
  );
};

export const isValidLayout = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return false;
  }
  return value === CONST.LAYOUT.GALLERY || value === CONST.LAYOUT.FULLSCREEN || value === CONST.LAYOUT.PRESENTATION;
};

export const isValidRoomType = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return false;
  }
  return value === CONST.ROOM_TYPE.SFU || value === CONST.ROOM_TYPE.P2P || value === CONST.ROOM_TYPE.P2P_TURN;
};

export const isValidIDString = (idString: string): boolean => {
  const pattern = /^[a-zA-Z0-9.%+^_"`{|}~<>\\-]{1,255}$/;
  return idString.match(pattern) !== null;
};

export const isValidUsername = (username: string): boolean => {
  const isNotEmptyUsername = username !== "";
  const isNotOverMaxLengthUsername = username.length <= CONST.USERNAME_CHARACTER_LIMIT.upper;

  return isNotEmptyUsername && isNotOverMaxLengthUsername;
};
