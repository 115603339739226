export enum LAYOUT {
  GALLERY = "gallery",
  PRESENTATION = "presentation",
  FULLSCREEN = "fullscreen",
}

export enum PRIORITY {
  NORMAL = "normal",
  HIGH = "high",
}
export const SHARE_PRIORITY = PRIORITY.HIGH;
export const VIDEO_PRIORITY = PRIORITY.NORMAL;

// export enum ICE_TRANSPORT_POLICY {
//   ALL = "all",
//   RELAY = "relay",
// }

export enum ROLE {
  SENDONLY = "sendonly",
  SENDRECV = "sendrecv",
}

export const MUTE_TYPE = {
  UNMUTE: "unmute" as const,
  SOFTMUTE: "softmute" as const,
  HARDMUTE: "hardmute" as const,
};

export const DEFAULT_DEVICE_ID = "default";
export const DUMMY_DEVICE_ID = "dummy-device";

export enum VIDEO_CODEC {
  H264 = "h264",
  VP8 = "vp8",
  VP9 = "vp9",
  H265 = "h265",
  AV1 = "av1",
}

export const DEFAULT_VIDEO_CODEC = VIDEO_CODEC.H264;

export const ROOM_TYPE = {
  SFU: "sfu",
  P2P: "p2p",
  P2P_TURN: "p2p_turn",
};

export const BITRATE = {
  MIN: 100,
  MAX: 20000,
};

export const BITRATE_RESERVATION_MBPS = {
  MIN: 1,
  MAX: 250,
};

export const API_HEADERS = { "Content-Type": "application/json; charset=utf-8" };

export const ROOMID_CHARACTER_LIMIT = {
  upper: 255,
};

export const USERNAME_CHARACTER_LIMIT = {
  upper: 10,
};

// size of annotation object; stroke, arrow, etc.
export const CANVAS_CIRCLE_SIZE = {
  DEFAULT: 40,
  // MIN: 4,
  // MAX: 20,
  MIN: 20,
  MAX: 60,
  STEP: 5,
};

// object; handWriting.
export const HANDWRITING_CANVAS = {
  WIDTH: 640,
  HEIGHT: 400,
  SIZE_OPTIONS: {
    DEFAULT: 8,
    MIN: 6,
    MAX: 20,
    STEP: 2,
  },
  LiveHandWritingImage_SCALE: 85.0,
};

export const CANVAS_PEN_COLOR = {
  // DEFAULT_RGB: "#fccb00", // RGB
  DEFAULT: "#fccb00ff", // RGBA
};

export enum MESSAGE_TYPE {
  CALL = "call",
  CONNECT_REQUEST = "connectRequest",
  BINARY_IMAGE = "binaryImage",
  THETA_POV = "thetaPoV",
  GLASSES_TRANSFORM = "glassesTransform",
  STROKE = "stroke",
  CIRCLE = "circle",
  IMAGE = "image",
  ARROW = "arrow",
  WT_INIT_TRACKING = "WtInstantTrackingInitialize",
  WT_PC_FAILURE = "WtConvertPointCloudFailure",
  WT_NO_TRACKING = "WtInstantTrackingNotStarted",
  ACTIVE_ANNOTATION = "activeAnnotation",
  DELETE_ALL = "deleteAll",
  DELETE_BY_ID = "deleteById",
  DELETE_ARROW = "deleteArrow",
  DELETE_TEXT_MESSAGE = "deleteTextMessage",
  TEXT_MESSAGE = "textMessage",
  OVERLAYIMAGE = "overlayImage",
  TRACKINGOVERLAYIMAGE = "trackingOverlayImage",
  CLEAROVERLAYIMAGE = "clearOverlayImage",
  OUT_OF_CANVAS = "OutOfCanvas",
  REQ_ORIG_VIDEO_IMG = "requestOriginalVideoImage",
  ORIG_VIDEO_IMG = "originalVideoImage",
  GLASS_CALIBRATION = "CalibrationResult",
  GLASS_CALIBRATION_REQUEST = "CalibrationResultRequest",
  CALIBRATION_TARGET_ICON = "CalibrationTargetIcon",
  CALIBRATION_OFFSET_2D = "CalibrationOffset2D",
  CAMERA_CONTROL = "cameraControl",
  ERROR = "error",
}

export enum MIME_TYPE {
  PNG = "image/png",
  JPEG = "image/jpeg",
}

export enum POV_STATE {
  NONE,
  GET,
  SET,
  SHARE,
}

export enum MEDIA_DEVICE_KIND {
  AUDIO_INPUT = "audioinput",
  AUDIO_OUTPUT = "audiooutput",
  VIDEO_INPUT = "videoinput",
}

export const VIDEO_ASPECT_RATIO = {
  WIDTH: 16,
  HEIGHT: 9,
};

export enum GLASS_TYPE {
  UNKNOWN = "UNKNOWN",
  OLED = "OLED",
  GREEN = "GREEN",
  SINGLE = "SINGLE",
  CINDY = "CINDY",
}

export enum DISPLAY_TYPE {
  UNKNOWN = "UNKNOWN",
  BOTH = "Both",
  LEFTONLY = "LeftOnly",
  RIGHTONLY = "RightOnly",
}

export const SEND_SHAREPOV_INTERVAL = 200;

// NOTE: LSConfを使用する際の設定パラメータのデフォルト値
// LSConfの実装移植に伴い一旦ここに定義しておくが、リファクタリング時に削除予定
export const defaultCustomParameter = {
  defaultLayout: "fullscreen",
  toolbar: {
    isHidden: false,
    isHiddenCameraButton: false,
    isHiddenMicButton: false,
    isHiddenParticipantsButton: true,
    isHiddenDeviceSettingButton: false,
    isHiddenExitButton: false,
    isHiddenScreenShareButton: false,
  },
  isHiddenVideoMenuButton: false,
  isHiddenRecordingButton: false,
  isHiddenSharePoVButton: false,
  isHiddenStopSharePoVButton: false,
  thetaZoomMaxRange: 8,
  podCoordinates: {
    upperLeft: [0, 0],
    lowerRight: [1280, 720],
  },
  theme: {
    primary: "#303030",
    secondary: "#661fff",
    background: "rgba(0, 0, 0, 0)",
    surface: "#fff",
    onPrimary: "rgba(255, 255, 255, 1)",
    onSurface: "rgba(0, 0, 0, 0.87)",
    textSecondaryOnBackground: "rgba(0, 0, 0, 0.54)",
    components: {
      participantsVideoContainer: {
        background: "rgba(0, 0, 0, 0.05)",
        subViewSwitchBackgroundColor: "#fff",
        subViewSwitchIconColor: "#000",
      },
      toolbar: {
        background: "#303030",
        iconColor: "rgba(255, 255, 255, 1)",
      },
      video: {
        background: "#000",
        textColor: "#fff",
        textBackgroundColor: "#000",
        iconColor: "#fff",
        menuBackgroundColor: "#fff",
        menuTextColor: "rgba(0, 0, 0, 0.87)",
        highlightBorderColor: "#661fff",
        highlightShadowColor: "#661fff",
      },
    },
  },
  locales: {
    ja: {},
    en: {},
  },
};

export const enum WEBSOCKET_ERROR_CODE {
  INVALID_PARAM = 40001,
  INVALID_TOKEN = 40002,
  UNEXPECTED = 50001,
}

export const AUTO_DISCONNECT_INTERVAL_MSEC = 5000;
