import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, styled, TextField, Typography } from "@mui/material";

import * as CONST from "../utils/constants";
import i18n from "@/locales/i18n";
import { isValidIDString } from "../utils/validators";

import CustomButton from "../atoms/customButton";

// username / roomId のバリデーション
// TODO(kdxu): ログイン時のエラー仕様が決定したら、それを踏襲してこのロジックを修正する
// const validateRoomIdAndUsername = (roomId: string, username: string): Array<{ key: string; message: string }> => {
const validateRoomId = (roomId: string): Array<{ key: string; message: string }> => {
  const errors = [];
  // const isEmptyUsername = username === "";
  const isEmptyRoomId = roomId === "";
  // const isOverMaxLengthUsername = username.length > CONST.USERNAME_CHARACTER_LIMIT.upper;
  const isOverMaxLengthRoomId = roomId.length > CONST.ROOMID_CHARACTER_LIMIT.upper;

  // if (isEmptyUsername) {
  //   errors.push({ key: "username", message: i18n.t("message.error.emptyUsername") });
  // }
  // if (isOverMaxLengthUsername) {
  //   errors.push({
  //     key: "username",
  //     message: i18n.t("message.error.overMaxLengthUsername", { maxLength: CONST.USERNAME_CHARACTER_LIMIT.upper }),
  //   });
  // }

  if (isEmptyRoomId) {
    errors.push({ key: "roomId", message: i18n.t("message.error.emptyRoomId") });
  }
  if (isOverMaxLengthRoomId) {
    errors.push({
      key: "roomId",
      message: i18n.t("message.error.overMaxLengthRoomId", { maxLength: CONST.ROOMID_CHARACTER_LIMIT.upper }),
    });
  }
  if (!isValidIDString(roomId)) {
    errors.push({ key: "roomId", message: i18n.t("message.error.invalidRoomId") });
  }

  return errors;
};

interface LoginEntranceInputItemProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  label: string;
  name: string;
  value: string;
  validateErrors: Array<{ key: string; message: string }>;
}

const CustomizedCard = styled(Card)(() => ({
  width: "20rem",
}));

// const useStyles = makeStyles({
// guide: {
//   lineHeight: 3,
// },
// });

const LoginEntranceInputItem: React.FC<LoginEntranceInputItemProps> = (props) => {
  // const classes = useStyles();
  const error = props.validateErrors.find((v) => v.key === props.name);
  const helpText = error && error.message;
  const isInvalid = error !== undefined;
  // NOTE:外部貸し出し環境の場合:true
  const isDisabled = Boolean(process.env.IS_TRIAL);

  // const title = i18n.t("message.guide.input", { label: props.label });
  return (
    <>
      {/* NOTE: Room名は固定値で運用するように仕様変更。したがって、下記注釈は不要。コメント行に変更  */}
      {/* <Typography variant="body1" color="textSecondary" className={classes.guide}>
        {title}
      </Typography> */}
      <TextField
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        style={{ width: "100%" }}
        variant="outlined"
        error={isInvalid}
        label={props.label}
        name={props.name}
        value={props.value}
        helperText={helpText}
        disabled={isDisabled}
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};

interface LoginEntranceFormFieldGroupProps {
  onChangeRoomId: (roomId: string) => void;
  // onChangeUsername: (username: string) => void;
  onSubmitSuccess: () => void;
  // username: string;
  roomId: string;
}

const LoginEntranceFormFieldGroup: React.FC<LoginEntranceFormFieldGroupProps> = (props) => {
  const [validateErrors, setValidateResult] = useState<Array<{ key: string; message: string }>>([]);
  //const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
  const handleSubmit = (e: React.PointerEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    // const errors = validateRoomIdAndUsername(props.roomId, props.username);
    const errors = validateRoomId(props.roomId);
    setValidateResult(errors);
    if (errors.length > 0) {
      return;
    }
    // validation エラーが無い場合ログイン処理を行う
    props.onSubmitSuccess();
  };
  const pressEnter = (e: any): void => {
    if (e.key === "Enter" && props.roomId) {
      handleSubmit(e);
    }
  };
  const handleChangeRoomId = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    props.onChangeRoomId(e.target.value);
  };
  // const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   e.preventDefault();
  //   props.onChangeUsername(e.target.value);
  // };
  return (
    <CustomizedCard>
      <CardContent>
        <div>
          <Typography variant="h6">{i18n.t("title.app")}</Typography>
          <form>
            <Box component="div" sx={{ mt: "1em" }}>
              <LoginEntranceInputItem
                onChange={handleChangeRoomId}
                onKeyDown={pressEnter}
                label={i18n.t("label.roomId")}
                name="roomId"
                value={props.roomId}
                validateErrors={validateErrors}
              />
              {/* <LoginEntranceInputItem
                onChange={handleChangeUsername}
                onKeyDown={pressEnter}
                label={i18n.t("label.username")}
                name="username"
                value={props.username}
                validateErrors={validateErrors}
              /> */}
            </Box>
            <CustomButton id="joinButton" margin="1em 0 0 0" width="100%" color="primary" onClick={handleSubmit}>
              {i18n.t("button.join")}
            </CustomButton>
          </form>
        </div>
      </CardContent>
    </CustomizedCard>
  );
};
export default LoginEntranceFormFieldGroup;
