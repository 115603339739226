// < videoViewer.tsx , 高さ優先
// import React, { useContext } from "react";
import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";

import NormalVideo from "./normalVideo";
// import ThetaVideo from "./thetaVideo";
// import VRPodVideo from "./vrPodVideo";
import { RootState } from "../redux/store";
// import { LAYOUT, MESSAGE_TYPE, POV_STATE, VIDEO_ASPECT_RATIO } from "../utils/constants";
import { LAYOUT, VIDEO_ASPECT_RATIO } from "../utils/constants";
import { RoomMember, LocalConnectOption } from "../utils/types";
// import { actions } from "../redux/slice";
import Viewer from "./viewer";
// import { WebSocketContext } from "../utils/webSocket";

interface Props {
  stream: MediaStream | null;
  connectionId: string;
  connectOption: LocalConnectOption | null;
  //width: number;
  height: number;
  member: RoomMember;
  participants: number;
  isSelf?: boolean;
  onDoubleClickVideo?: () => void;
  videoSizeReceived?: (width: number, height: number) => void;
}

const VideoViewerH: React.FC<Props> = (props) => {
  //const { stream, member, width, connectionId, onDoubleClickVideo } = props;
  const { stream, member, participants, height, connectionId, connectOption, onDoubleClickVideo } = props;
  // const { sendMessage } = useContext(WebSocketContext);
  // const dispatch = useDispatch();
  const room = useSelector((state: RootState) => state.main);
  const { currentLayout, selectedSpeakerId } = room;
  // fullscreen の場合は aspect ratio を無視して 表示領域まで展開する
  const isFullscreen = currentLayout === LAYOUT.FULLSCREEN;
  //  const height = isFullscreen
  //    ? document.getElementById("ls-container").clientHeight
  //    : Math.floor((width / VIDEO_ASPECT_RATIO.WIDTH) * VIDEO_ASPECT_RATIO.HEIGHT);
  const width = isFullscreen
    ? document.getElementById("ls-container").clientWidth
    : Math.floor((height / VIDEO_ASPECT_RATIO.HEIGHT) * VIDEO_ASPECT_RATIO.WIDTH);

  const isScreenShare = connectionId === member.screenShareConnectionId;
  const visibleNoVideoCam = !isScreenShare && !member.enableVideo;
  const disableMic = !member.enableAudio;

  // console.log(`wxh: videoViewerH =${width},${height}`)

  const videoSizeReceived = (width: number, height: number) => {
    if (props.videoSizeReceived) {
      props.videoSizeReceived(width, height);
    }
  };

  const videoContainer = (
    <NormalVideo
      stream={stream}
      connectOption={connectOption}
      selectedSpeakerId={selectedSpeakerId}
      videoSizeReceived={videoSizeReceived}
    />
  );
  if (member.isPod) {
    // videoContainer = <VRPodVideo stream={stream} />;
  }

  if (member.isTheta) {
    // videoContainer = (
    // <ThetaVideo
    //   width={width}
    //   height={height}
    //   stream={stream}
    //   poVState={member.poVState}
    //   poV={member.poV}
    //   onUpdatePoV={(poV) => {
    //     if (member.poVState === POV_STATE.SHARE) {
    //       const message = { messageType: MESSAGE_TYPE.THETA_POV, pov: poV, connectionId: props.connectionId };
    //       sendMessage(message);
    //       // console.log(message);
    //     }
    //     dispatch(actions.changeMainStatePoV({ connectionId: props.connectionId, poVState: POV_STATE.NONE, poV }));
    //   }}
    // />
    // );
  }

  //if (width === 0) {
  if (height === 0) {
    return null;
  }
  return (
    <Viewer
      connectionId={connectionId}
      username={member.username}
      isScreenShare={isScreenShare}
      isTheta={member.isTheta}
      participants={participants}
      onDoubleClick={onDoubleClickVideo}
      width={width}
      height={height}
      disableMic={disableMic}
      visibleNoVideoCam={visibleNoVideoCam}
    >
      {videoContainer}
    </Viewer>
  );
};

export default VideoViewerH;
