export default {
  message: {
    error: {
      emptyUsername: "ユーザ名を入力してください",
      overMaxLengthUsername: `ユーザ名は{{maxLength}}文字以下で入力してください`,
      emptyRoomId: "ルーム名を入力してください",
      overMaxLengthRoomId: `ルーム名は{{maxLength}}文字以下で入力してください`,
      invalidRoomId: '英数字と一部記号（.%+^_"`{|}~<>\\-）以外の文字列は使用できません',
      cameraMuted: "カメラがミュート状態の場合この機能は利用できません",
      exitedConnectionId: "退出済みのユーザです",
      signinFailed: "ユーザ名またはパスワードが違います",
      invalidToken: "不正なトークンです",
    },
    guide: {
      input: "{{label}}を入力してください",
      connectRequest: "{{caller}}から入室リクエストが来ています。参加する場合はユーザ名を入力してください。",
      sendcompleted: "送信しました",
      askRemoteSideToJoin: "現場側に参加してもらうよう依頼してください",
    },
  },
  label: {
    username: "ユーザ名",
    roomId: "ルーム名",
    capture: "キャプチャ",
    markOnVideo: "動画上マーク",
    selectSubView: "アノテーション画面選択",
    circleSize: "サイズ",
    // circleSize: "円のサイズ",
    password: "パスワード",
    callee: "呼び出し先",
    drawCursorOver: "マウス追従",
    drawClick: "矢印配置",
    drawHandWriting: "手描き図",
    Text: "テキスト",
  },
  title: {
    app: "Ricoh AR Annotator",
  },
  button: {
    join: "参加",
    close: "閉じる",
    return: "戻る",
    ignore: "無視",
    call: "CALL",
    send: "送信",
    signin: "ログイン",
    largeCursorOn: "カーソルを拡大",
    largeCursorOff: "カーソルを元に戻す",
    // stroke: "手描き",
    // circle: "サークル", //circle
    // liveCircle: "追従",
    imageSelect: "その他",
    arrow: "矢印",
    liveArrow: "矢印2",
    createHandwriting: "作成",
    liveHandWritingImage: "移動",
    clearCanvas: "消去",
    disconnect: "切断",
    inputTextPlaceholder: "表示する内容を入力",
  },
  deviceSettingsDialog: {
    title: "設定",
    // Large
    calib: "キャリブレーション",
    cameraImage: "カメラ映像",
    device: "デバイス",
    versionInfo: "バージョン情報",
    // Middle
    brightnessContent: "露出",
    videoContent: "逆光補正",
    mic: "マイク",
    speaker: "スピーカー",
    camera: "カメラ",
    version: "バージョン ： ",
    // content
    calibContent: "スマートグラス上のオブジェクトの配置を調整します",
    calibLeftButtont: "開始(左)",
    calibRightButtont: "開始(右)",
    calibrationProgress: "キャリブレーション実行中です。",
    abortCalibration: "中止する",
    notUsed: "使用しない",
    auto: "自動",
    on: "オン",
    off: "オフ",
    ok: "OK",
    apply: "適用",
    cancel: "キャンセル",
  },
  handWritingDialog: {
    title: "手描きモード",
    send: "送信",
    clear: "消去",
    size: "線の幅",
    SuccessMessage: "送信しました。",
    ErrorMessage: "送信に失敗しました。",
  },
  recordingNotification: {
    recording: "録画中: ",
    noName: "No Name",
  },
  recordingSettingsDialog: {
    title: "録画設定",
    mimeType: "MIME Type",
    audioMixing: "Audio Mixing",
    mixAll: "Mix All",
    targetOnly: "Target Only",
    audioBitrate: "Audio Bitrate",
    videoBitrate: "Video Bitrate",
    start: "開始する",
    cancel: "キャンセル",
  },
  subView: {
    noName: "No Name",
    participant: "参加人数：",
    people: "人",
  },
  subViewMenu: {
    presentation: "拡大表示",
    gallery: "一覧表示",
    fullscreen: "全画面表示",
    exitFullscreen: "全画面解除",
    sharePoV: "視点共有開始",
    stopSharePoV: "視点共有停止",
    startRecording: "録画開始",
    stopRecording: "録画停止",
  },
  presentationLayout: {
    horizontalButtonTips: "横並びに切替",
    verticalButtonTips: "縦並びに切替",
  },
  page404: {
    title: "ご指定のページが見つかりません。",
    content: "移動もしくは削除されたか、URLが正しく入力されていない可能性があります。",
  },
  AlertLatency: {
    content: "会議に遅延が発生しています。安定したインターネット環境で会議を行ってください。",
    connect: "再接続",
  },
};
