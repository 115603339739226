import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { useDispatch } from "react-redux";
import { mainSlice } from "./slice";

enableMapSet();

export const store = configureStore({
  reducer: {
    main: mainSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // immutable な state は main.immutable プロパティに押し込めて、serializable check を切る
        ignoredPaths: ["main.immutable"],
        // action payload が MediaStream など immutable な場合は個別に action を ignore する
        ignoredActions: [
          "main/startConnect",
          "main/successConnect",
          "main/startScreenShare",
          "main/successScreenShare",
          "main/successGetDisplayMedia",
          "main/addRemoteTrack",
          "main/updateLocalAudio",
          "main/updateLocalVideo",
          "main/changeMuteState",
          "main/setMediaRecorder",
        ],
      },
      immutableCheck: {
        // immutable な state は main.immutable プロパティに押し込めて、そこだけ immutable check を切る
        ignoredPaths: ["main.immutable"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type RootThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
