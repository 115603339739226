export const decodeIdToken = (idToken: string): { header: string; payload: string; signature: string } | null => {
  if (!idToken) return null;

  try {
    const decodedToken = [];
    idToken.split(".").forEach((part) => {
      try {
        const parsed = JSON.parse(Buffer.from(part, "base64").toString());
        decodedToken.push(parsed);
      } catch (error) {
        // base64デコードできない場合はそのまま入れる（signatureが該当）
        decodedToken.push(part);
      }
    });
    return { header: decodedToken[0], payload: decodedToken[1], signature: decodedToken[2] };
  } catch (error) {
    // console.log(error.toString());
    console.error("Decoding failed");
    return null;
  }
};

export const getCognitoUsername = (idToken: string): string => {
  const decoded = decodeIdToken(idToken);

  if (!decoded || !decoded.payload || !decoded.payload["cognito:username"]) {
    return "";
  }

  return decoded.payload["cognito:username"];
};
