import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Card, CardContent, styled, TextField, Typography } from "@mui/material";

import i18n from "../../locales/i18n";
import { fetchCognitoTokens } from "../utils/api";
import { AuthenticationState, LocationState } from "../utils/types";

import CustomButton from "../atoms/customButton";

const CustomizedPageLayout = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100 * var(--vh))",
  flexDirection: "column",
}));

const CustomizedCard = styled(Card)(() => ({
  width: "20rem",
}));

const CustomizedTextField = styled(TextField)(() => ({
  width: "100%",
  "&:not(:first-of-type)": {
    margin: "1em 0 0 0",
  },
}));

const CustomizedTypography = styled(Typography)(() => ({
  textAlign: "center",
  color: "#B00020",
}));

const Signin: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authState = location.state as AuthenticationState;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSigninFailed, setIsSigninFailed] = useState<boolean>(false);
  const isTokenInvalid: boolean = authState?.isTokenInvalid || false;

  const pressEnter = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Enter" && username && password) {
      onClick();
    }
  };

  const onClick = async () => {
    const routerState = location.state as LocationState;
    let tokens: { id_token: string; refresh_token: string };
    try {
      tokens = await fetchCognitoTokens(username, password);
    } catch (e) {
      setIsSigninFailed(true);
      return;
    }
    setIsSigninFailed(false);
    sessionStorage.setItem("id_token", tokens.id_token);
    sessionStorage.setItem("refresh_token", tokens.refresh_token);
    const { from } = routerState || { from: { pathname: "/" } };
    navigate(from, { replace: true });
  };

  return (
    <CustomizedPageLayout component="div">
      <CustomizedCard>
        <CardContent>
          <div>
            <Typography variant="h6">{i18n.t("title.app")}</Typography>
          </div>
          <form>
            <CustomizedTextField
              sx={{ mt: "1em" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
              variant="outlined"
              label={i18n.t("label.username")}
              name="username"
              //HACK: この TextInput はControlledなものなので、 defaultValue ではなく value が正しいと思いますが
              // 既存の実装でvalueを使用すると、ブラウザをリロードした直後、ラベルと文字が重なってしまう問題が発生してしまうため、defaultValuewを使用。
              // （ MUIv4→v5のアップデートでvalue プロパティの挙動が変わっていると思われれる。）
              // リロードした直後（初回レンダリング）では、valueの中身がnull,undefindになっていおり、初回レンダリングの状態を参照していると思われるので、その後値を更新してもラベルと入力した値が被ってしまう。
              // 初回レンダリング時にvalueの中身を更新し、初期値を入れることができれば解決できそうですが、時間がなく暫定の対応として、defaultValueを使用。
              defaultValue={username}
              onKeyDown={pressEnter}
            />
            <CustomizedTextField
              type="password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              variant="outlined"
              label={i18n.t("label.password")}
              name="password"
              //HACK: この TextInput はControlledなものなので、 defaultValue ではなく value が正しいと思いますが
              // 既存の実装でvalueを使用すると、ブラウザをリロードした直後、ラベルと文字が重なってしまう問題が発生してしまうため、defaultValuewを使用。
              // （ MUIv4→v5のアップデートでvalue プロパティの挙動が変わっていると思われれる。）
              // リロードした直後（初回レンダリング）では、valueの中身がnull,undefindになっていおり、初回レンダリングの状態を参照していると思われるので、その後値を更新してもラベルと入力した値が被ってしまう。
              // 初回レンダリング時にvalueの中身を更新し、初期値を入れることができれば解決できそうですが、時間がなく暫定の対応として、defaultValueを使用。
              defaultValue={password}
              onKeyDown={pressEnter}
            />
            {isSigninFailed && (
              <CustomizedTypography sx={{ mt: "0.5em" }}>{i18n.t("message.error.signinFailed")}</CustomizedTypography>
            )}
            {isTokenInvalid && (
              <CustomizedTypography sx={{ mt: "0.5em" }}>{i18n.t("message.error.invalidToken")}</CustomizedTypography>
            )}
            <CustomButton margin="1em 0 0 0" color="primary" width="100%" onClick={onClick}>
              {i18n.t("button.signin")}
            </CustomButton>
          </form>
        </CardContent>
      </CustomizedCard>
    </CustomizedPageLayout>
  );
};

export default Signin;
