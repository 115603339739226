import "../styles/index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";

import i18n from "../locales/i18n";
import App from "./pages/app";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";

const rootElement = document.getElementById("root");
// mobile で 100vh だと アドレスバーからはみでてしまうケースに対応
// onload 時に innerHeight から `--vh` を計算
// 参考: https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
document.body.onload = () => {
  if (rootElement) {
    rootElement.style.setProperty("--vh", window.innerHeight / 100 + "px");

    document.title = i18n.t("title.app");
    if (process.env.DISPLAY_ENVIRONMENT_NAME != null) {
      document.title += process.env.DISPLAY_ENVIRONMENT_NAME;
    }
  }
};
// window.resize 時に `--vh` を再計算
window.addEventListener("resize", () => {
  // window.innerHeight の変更に追従するため、少し遅延させる
  window.setTimeout(() => {
    if (rootElement) {
      rootElement.style.setProperty("--vh", window.innerHeight / 100 + "px");
    }
  }, 300);
});

// TODO localstorageに残るtokenを削除する
// 現在、token自体に期限があるため、localstorageに保存したtokenを自動削除する処理は行っていない
// 急ぎの実装は不要だが永続させるべきではないため処理方法が定まり次第追加する

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#303030",
    },
    secondary: {
      main: "#404D60",
    },
    error: {
      // NOTE:元の設定値
      // main: "#b00020",
      main: "#FC0909",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
});

const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={customTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
