import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material";
// import { makeStyles } from "@material-ui/styles";

import { defaultCustomParameter, LAYOUT } from "../utils/constants";
import { actions } from "../redux/slice";
import { RootState } from "../redux/store";

// SubViewの強調表示時間(ms)
const HIGHLIGHT_TIMEOUT = 2000;

interface Props {
  connectionId: string;
  children: React.ReactNode;
}

// HACK:styled(Box)だと、「isFullScreen」というpropsをdiv要素に適用しようとするので下記エラーが発生する。
// react-dom.development.js:86 Warning: React does not recognize the `isFullScreen` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `isfullscreen` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
// styled("div")だと、「isFullScreen」というpropsをdiv要素の属性として認識するようなので、エラーが発生しなかった。なので、styled("div")で指定してエラーを回避。
const CustomizedDiv = styled("div")<{ isFullScreen: boolean }>(({ isFullScreen }) => ({
  ...(isFullScreen
    ? {}
    : {
        boxShadow: `0 0 18px 19px ${defaultCustomParameter.theme.components.video.highlightShadowColor}`,
        transition: "2s",
      }),
}));

// const useStyles = makeStyles({
//   highlight: {
//     boxShadow: `0 0 18px 19px ${defaultCustomParameter.theme.components.video.highlightShadowColor}`,
//   },
//   glow: {
//     transition: "2s",
//   },
// });

const Highlighter: React.FC<Props> = (props) => {
  const { connectionId } = props;
  const { highlightConnectionIds, currentLayout } = useSelector((state: RootState) => state.main);
  const enable = highlightConnectionIds.includes(connectionId);
  // const classes = useStyles();
  const isFullScreen = currentLayout === LAYOUT.FULLSCREEN;
  const dispatch = useDispatch();
  const timer = useRef<number | null>(null);
  useEffect(() => {
    if (enable) {
      if (timer.current) {
        window.clearTimeout(timer.current);
        timer.current = null;
      }
      timer.current = window.setTimeout(() => {
        if (connectionId) {
          dispatch(
            actions.setHighlight({
              targetConnectionId: connectionId,
              highlight: false,
            })
          );
        }
        timer.current = null;
      }, HIGHLIGHT_TIMEOUT);
    }
  }, [connectionId, enable]);
  useEffect(() => {
    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, []);
  return (
    <CustomizedDiv
      id={connectionId}
      isFullScreen={isFullScreen}
      // className={currentLayout === LAYOUT.FULLSCREEN ? "" : `${classes.glow} ${classes.highlight}`}
      style={!enable ? { border: "none", boxShadow: "none" } : {}}
    >
      {props.children}
    </CustomizedDiv>
  );
};

export default Highlighter;
