// NOTE: canvasDialogForVideo.tsx 肥大化を防ぐため、GUIを分離 プロジェクトの規模に応じて都度集約、分解する
// NOTE: memo化しているのでコードを追加する際は、再レンダリングが必要かどうかを確認し、再レンダリングが必要であれば Props に含めるなどして再レンダリングさせる
// Responsibility: Canvasに描写するアノテーション機能のGUIを表示する

import { KeyboardArrowDown, RadioButtonUnchecked, Settings, South } from "@mui/icons-material";
import { Box, Grid, IconButton, Menu, Slider, Typography, styled } from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import { GithubPicker } from "react-color";
import { useDispatch } from "react-redux";
import { actions } from "../redux/slice";

import i18n from "../../locales/i18n";
import * as CONST from "../utils/constants";
import { ImageCursor } from "../utils/types";

import { CursorList } from "../../images/cursors/cursors";

import CursorMenuItems from "../atoms/cursorMenuItems";
import CustomButton from "../atoms/customButton";
import Textarea from "../atoms/textarea";

const CustomizedDrawMenu = styled(Box)(() => ({
  display: "flex",
  borderRadius: "5px",
  background: "#F2F2F2",
  justifyContent: "center",
  minWidth: "1065px",
}));

const CustomizedDrawMenuContent = styled(Box)(() => ({
  margin: "0.5em 0 0.5em",
  padding: "0.5em",
  borderRadius: "5px",
  border: "1px solid #DFDFDF",
}));

const CustomizedIconButton = styled(IconButton)(() => ({
  width: "65px",
  height: "65px",
  ["@media screen and (max-width: 335px)"]: {
    width: "22px",
    height: "22px",
  },
}));

interface ButtonGroupProps {
  onClickHandWriting: () => void;
  onClearHandWriting: () => void;
  onClickHandWritingImage: () => void;
  onClickStroke: () => void;
  onClickImageStroke: (cursor: ImageCursor) => void;
  currentImageCursor: ImageCursor;
  onClickArrow: () => void;
  // onClickLiveArrow: () => void;
  onClear: () => void;
  onTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onTextFocus: () => void;
  onClickCrossIconInText: () => void;
  handlePenColorChange: (value: string) => void;
  circleSize: number;
  onChangeCircleSize: (value: number) => void;
  onClickDeviceSettingIcon: () => void;
  glassType: string;
  penColor: string;
}

const Toolbar: React.FC<ButtonGroupProps> = memo(
  ({
    onClickHandWriting,
    onClearHandWriting,
    onClickHandWritingImage,
    onClickStroke,
    onClickImageStroke,
    currentImageCursor,
    onClickArrow,
    // onClickLiveArrow,
    onClear,
    onTextChange,
    onTextFocus,
    onClickCrossIconInText,
    handlePenColorChange,
    circleSize,
    onChangeCircleSize,
    onClickDeviceSettingIcon,
    glassType,
    penColor,
  }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);

    const alphaValues = ["FF", "4D"];
    const baseColors = ["#B80000", "#DB3E00", "#FCCB00", "#008B02", "#006B76", "#1273DE", "#004DCF", "#5300EB"];
    const colorPalette = alphaValues.flatMap((alphaValue) => baseColors.map((baseColor) => `${baseColor}${alphaValue}`));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    };

    const handleCloseM = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    const handleExit: () => void = () => {
      dispatch(actions.disconnect());
    };

    const combineColorAlpha = (hexColor: string, alpha: number): string => {
      const alphaHex = Math.round(alpha * 255)
        .toString(16)
        .padStart(2, "0");
      return hexColor + alphaHex;
    };

    const handleColorPaletteChange = (penColor: string, selectColor: string) => {
      // NOTE:GithubPicker で生成されるカラーパレット（divタグ）のtitle要素はRGB定義なので、RGBでDOM操作
      //     → 他に操作できるタグがないため、title要素使用
      const beforeColorElem = document.querySelector<HTMLInputElement>(`[title="${penColor.toUpperCase()}"]`);
      beforeColorElem.style.border = "none";

      const currentColorElem = document.querySelector<HTMLInputElement>(`[title="${selectColor.toUpperCase()}"]`);
      currentColorElem.style.border = "3px solid black";
    };

    const onClickMenuItem = useCallback(
      (cursor: ImageCursor) => {
        handleCloseM();
        onClickImageStroke(cursor);
      },
      [onClickImageStroke]
    );

    const showColorPalette = useMemo(() => {
      return glassType !== CONST.GLASS_TYPE.GREEN;
    }, [glassType]);

    return (
      <>
        {/* <Box sx={{ mr: "1em" }} className={classes.drawMenu}>
          <ToolbarItem onClick={onClickHandWriting} buttonId="edit" iconName="edit" />
          <Divider orientation="vertical" flexItem style={{ margin: "8px 0" }} />
          <ToolbarItem onClick={onClearHandWriting} buttonId="edit_off" iconName="edit_off" />
        </Box> */}
        <CustomizedDrawMenu sx={{ p: "0.6em" }}>
          <CustomizedDrawMenuContent>
            <Box component="div" sx={{ pt: "0.1em", pb: "0.4em" }}>
              <Typography variant="body1">{i18n.t("label.drawClick")}</Typography>
            </Box>
            <CustomButton id="arrowButton" margin="0 0.7em" color="secondary" onClick={onClickArrow}>
              <South />
            </CustomButton>
            {/* NOTE: 3Dof の矢印２はまだデモしないため、コメント
          <CustomButton id="liveArrowButton" className={classes.clickButton} onClick={onClickLiveArrow}>
            {i18n.t("button.liveArrow")}
          </CustomButton> */}
            <CustomButton margin="0 0.7em" color="secondary" onClick={onClear}>
              {i18n.t("button.clearCanvas")}
            </CustomButton>
          </CustomizedDrawMenuContent>
          <CustomizedDrawMenuContent sx={{ ml: "0.8em" }}>
            <Box component="div" sx={{ pt: "0.1em", pb: "0.4em" }}>
              <Typography variant="body1">{i18n.t("label.drawCursorOver")}</Typography>
            </Box>
            <CustomButton id="liveCircleButton" margin="0 0.7em" color="secondary" onClick={onClickStroke}>
              <RadioButtonUnchecked />
            </CustomButton>
            <CustomButton
              id="imageButton"
              margin="0 0.7em"
              color="secondary"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {currentImageCursor === null ? (
                <Box component="div" display="flex" alignItems="center">
                  <Box component="span">{i18n.t("button.imageSelect")}</Box>
                  <Box component="span" sx={{ pt: "0.5em", pl: "0.3em" }}>
                    <KeyboardArrowDown />
                  </Box>
                </Box>
              ) : (
                <Box component="div" display="flex" alignItems="center">
                  <img src={currentImageCursor.src} height="30" />
                  <Box component="span" sx={{ pt: "0.5em", pl: "0.3em" }}>
                    <KeyboardArrowDown />
                  </Box>
                </Box>
              )}
            </CustomButton>
            <Menu id="image-select-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseM}>
              <KeyboardArrowDown />
              <CursorMenuItems cursors={CursorList} onClickMenuItem={onClickMenuItem} />
            </Menu>
          </CustomizedDrawMenuContent>
          <CustomizedDrawMenuContent sx={{ ml: "1.7em" }}>
            <Box component="div" sx={{ pt: "0.1em", pb: "0.4em" }}>
              <Typography variant="body1">{i18n.t("label.drawHandWriting")}</Typography>
            </Box>
            <CustomButton id="arrowButton" margin="0 0.7em" color="secondary" onClick={onClickHandWriting}>
              {i18n.t("button.createHandwriting")}
            </CustomButton>
            <CustomButton id="liveHandWritingImageButton" margin="0 0.7em" color="secondary" onClick={onClickHandWritingImage}>
              {i18n.t("button.liveHandWritingImage")}
            </CustomButton>
            <CustomButton margin="0 0.7em" color="secondary" onClick={onClearHandWriting}>
              {i18n.t("button.clearCanvas")}
            </CustomButton>
          </CustomizedDrawMenuContent>
          <CustomizedDrawMenuContent sx={{ ml: "1.7em" }}>
            <Box component="div" sx={{ pt: "0.1em", pb: "0.4em" }}>
              <Typography variant="body1"> {i18n.t("label.Text")}</Typography>
            </Box>
            <Textarea
              onChange={onTextChange}
              onFocus={onTextFocus}
              onClickClear={onClickCrossIconInText}
              placeholder={i18n.t("button.inputTextPlaceholder")}
            />
          </CustomizedDrawMenuContent>
        </CustomizedDrawMenu>

        <Box sx={{ ml: "1.5em", display: "flex", alignItems: "center" }}>
          {showColorPalette && (
            <GithubPicker
              width="212px"
              triangle="hide"
              colors={colorPalette}
              onChange={(value) => {
                const selectColRGBA = combineColorAlpha(value.hex, value.rgb.a);
                handleColorPaletteChange(penColor, selectColRGBA);
                handlePenColorChange(selectColRGBA);
              }}
            />
          )}
          <Box sx={{ width: 250, ml: "1.5em" }}>
            <Typography variant="body1">{i18n.t("label.circleSize")}</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  size="small"
                  valueLabelDisplay="auto"
                  step={CONST.CANVAS_CIRCLE_SIZE.STEP}
                  min={CONST.CANVAS_CIRCLE_SIZE.MIN}
                  max={CONST.CANVAS_CIRCLE_SIZE.MAX}
                  value={circleSize}
                  marks
                  onChange={(_event: Event, value: number) => {
                    onChangeCircleSize(value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ ml: "1em" }}>
            <CustomizedIconButton
              color="inherit"
              onClick={() => {
                onClickDeviceSettingIcon();
              }}
            >
              <Settings fontSize="large" />
            </CustomizedIconButton>
          </Box>
          <CustomButton id="disconnectButton" margin="0 1em" color="error" onClick={handleExit}>
            {i18n.t("button.disconnect")}
          </CustomButton>
        </Box>
      </>
    );
  }
);

export default Toolbar;
