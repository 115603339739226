import { PayloadAction } from "@reduxjs/toolkit";
import { MuteType } from "ricoh-ls-sdk";
import { LAYOUT } from "../../utils/constants";
import { stopStream } from "../../utils/liveStreamingHelpers";
import { ConnectionMetadata, MediaStreams, MEDIA_TYPES, TrackMetadata } from "../../utils/types";
import { initialRoomMember, MainState } from "../slice";

export const addRemoteTrack = (
  state: MainState,
  action: PayloadAction<{
    connectionId: string;
    mediaStreamTrack: MediaStreamTrack;
    stream: MediaStream;
    meta: TrackMetadata;
    mute: MuteType;
  }>
): void => {
  // client.addremotetrack が発火したときの処理。
  const { connectionId, mediaStreamTrack, stream, meta, mute } = action.payload;
  const audioStreams: MediaStreams = new Map(state.immutable.audioStreams);
  const videoStreams: MediaStreams = new Map(state.immutable.videoStreams);
  const screenShareStreams: MediaStreams = new Map(state.immutable.screenShareStreams);
  // console.log(`addRemoteTrack. meta=%O`,meta);
  // metadata に含まれている media_type から screenshare の stream か識別して
  // それぞれの Map にセットする。
  if (meta.mediaType === MEDIA_TYPES.SCREEN_SHARE) {
    screenShareStreams.set(connectionId, stream);
  } else if (meta.mediaType === MEDIA_TYPES.VIDEO_AUDIO) {
    if (mediaStreamTrack.kind === "audio") {
      const stream = new MediaStream([mediaStreamTrack]);
      audioStreams.set(connectionId, stream);
    } else if (mediaStreamTrack.kind === "video") {
      // フロントエンドの VideoStreamTrack は mute === hardmute としている.
      if (mute === "hardmute") {
        console.log("Skip hardmuted track", connectionId, mute);
      } else {
        const stream = new MediaStream([mediaStreamTrack]);
        videoStreams.set(connectionId, stream);
      }
    }
  } else {
    videoStreams.set(connectionId, stream);
  }

  if (meta.isTheta) {
    const otherMembers = new Map(state.immutable.otherMembers);
    const updateMember = otherMembers.get(connectionId);
    if (updateMember) {
      // THETA track が上がってきた場合 member の theta 情報を更新する
      otherMembers.set(connectionId, { ...updateMember, isTheta: true, thetaVideoFormat: meta.thetaVideoFormat });
      state.immutable.otherMembers = otherMembers;
    }
  }
  if (meta.isPod) {
    const otherMembers = new Map(state.immutable.otherMembers);
    const updateMember = otherMembers.get(connectionId);
    if (updateMember) {
      // VRPod track が上がってきた場合 member の 情報を更新する
      otherMembers.set(connectionId, { ...updateMember, isPod: true });
      state.immutable.otherMembers = otherMembers;
    }
  }

  // 最初に見つけた video unmute 拠点を1拠点表示のデフォルト選択の条件とする
  if (!state.hasReceivedRemoteTrack && mediaStreamTrack.kind === "video" && mute === "unmute") {
    state.mainConnectionIds = [connectionId];
    state.hasReceivedRemoteTrack = true;
    // console.log(`addRemoteTrack. state.hasReceivedRemoteTrack111=%O`, state.hasReceivedRemoteTrack);
  }

  state.immutable.audioStreams = audioStreams;
  state.immutable.videoStreams = videoStreams;
  state.immutable.screenShareStreams = screenShareStreams;

  const otherMembers = new Map(state.immutable.otherMembers);
  const updateMember = otherMembers.get(connectionId);

  if (updateMember) {
    // member の mute 更新
    if (mediaStreamTrack.kind === "audio") {
      const enableAudio = mute === "unmute";
      otherMembers.set(connectionId, { ...updateMember, enableAudio });
    } else if (mediaStreamTrack.kind === "video") {
      const enableVideo = mute === "unmute";
      otherMembers.set(connectionId, { ...updateMember, enableVideo });
    }
    state.immutable.otherMembers = otherMembers;
  }
};

export const addRemoteConnection = (
  state: MainState,
  action: PayloadAction<{ connectionId: string; meta: ConnectionMetadata }>
): void => {
  // client.addremoteconnection が発火したときの処理。
  // cappella-web v0.0.8 からこちらの callback で stream は受け取ることができなくなったので、
  // member の connection_id, username, video/audio の可否のみ更新する。
  const { connectionId, meta } = action.payload;
  const { username, mediaType, parentConnectionId } = meta;
  const isScreenShare = mediaType === MEDIA_TYPES.SCREEN_SHARE;
  const otherMembers = new Map(state.immutable.otherMembers);

  // console.log(`addRemoteConnection state=%O`, state);
  // console.log(`addRemoteConnection meta=%O`, meta);

  // 自分のconnectionIdの場合は無視する
  if (state.self.connectionId === connectionId || state.self.screenShareConnectionId === connectionId) {
    return;
  }
  // 親のconnectionIdが設定されている場合
  if (parentConnectionId) {
    const updateMember = state.immutable.otherMembers.get(parentConnectionId);
    // 親がotherMembersに存在する、かつConnectionが画面共有の場合、screenShareConnectionIdを設定
    if (updateMember && isScreenShare) {
      otherMembers.set(parentConnectionId, { ...updateMember, screenShareConnectionId: connectionId });
    } else if (isScreenShare) {
      // 親が存在しない場合は暫定的にmemberとして追加する
      otherMembers.set(parentConnectionId, { ...initialRoomMember, screenShareConnectionId: connectionId });
    }
  } else {
    // 既にmemberが存在する場合はマージする
    const existMember = state.immutable.otherMembers.get(connectionId) || initialRoomMember;
    const newMember = { ...existMember, connectionId, username, mediaType };
    otherMembers.set(connectionId, newMember);
  }
  state.immutable.otherMembers = otherMembers;
};

export const leave = (state: MainState, action: PayloadAction<{ connectionId: string; meta: ConnectionMetadata }>): void => {
  const { connectionId, meta } = action.payload;
  const { mediaType, parentConnectionId } = meta;
  const isScreenShare = mediaType === MEDIA_TYPES.SCREEN_SHARE;
  const otherMembers = new Map(state.immutable.otherMembers);

  // 自分のconnectionIdの場合はリセットする
  const newSelf = { ...state.self };
  if (state.self.connectionId === connectionId) {
    newSelf.connectionId = null;
    state.self = newSelf;
  } else if (state.self.screenShareConnectionId === connectionId) {
    newSelf.screenShareConnectionId = null;
    state.self = newSelf;
  }

  // otherMembersの更新
  if (parentConnectionId) {
    const updateMember = state.immutable.otherMembers.get(parentConnectionId);
    // 親がotherMembersに存在する、かつConnectionが画面共有の場合、screenShareConnectionIdをリセット
    if (updateMember && isScreenShare) {
      otherMembers.set(parentConnectionId, { ...updateMember, screenShareConnectionId: null });
    }
  } else {
    otherMembers.delete(connectionId);
  }
  state.immutable.otherMembers = otherMembers;

  // audioStreamの更新
  const audioStreams = new Map(state.immutable.audioStreams);
  const audioStream = audioStreams.get(connectionId);
  if (audioStream) {
    stopStream(audioStream);
    audioStreams.delete(connectionId);
  }
  state.immutable.audioStreams = audioStreams;

  // videoStreamの更新
  const videoStreams = new Map(state.immutable.videoStreams);
  const videoStream = videoStreams.get(connectionId);
  if (videoStream) {
    stopStream(videoStream);
    videoStreams.delete(connectionId);
  }
  state.immutable.videoStreams = videoStreams;

  // screenShareStreamsの更新
  const screenShareStreams = new Map(state.immutable.screenShareStreams);
  const screenShareStream = screenShareStreams.get(connectionId);
  if (screenShareStream) {
    stopStream(screenShareStream);
    screenShareStreams.delete(connectionId);
  }
  state.immutable.screenShareStreams = screenShareStreams;

  // RecordingSubViews の更新
  const recordingSubViews = new Map(state.immutable.recordingSubViews);
  recordingSubViews.delete(connectionId);
  recordingSubViews.forEach((recordingSubView, targetConnectionId) => {
    recordingSubView.connectionIds = recordingSubView.connectionIds.filter((id) => id !== connectionId);
    // connectionIds が空の場合は recordingSubView自体を削除する
    if (!recordingSubView.connectionIds.length) {
      recordingSubViews.delete(targetConnectionId);
    }
  });
  state.immutable.recordingSubViews = recordingSubViews;

  // MediaRecorders の更新
  const mediaRecorder = state.immutable.mediaRecorders.get(connectionId);
  if (mediaRecorder) {
    if (mediaRecorder.state !== "inactive") {
      // 切断したconnectionIdを録画中の場合は明示的に停止させる
      // 音声MIX録画時には他拠点のAudioTrackがまだ有効でMediaRecorder.onstopが自動的に呼ばれないため
      // 切断時は対象のSubView自体が削除されるため親windowへのstopRecordingイベントによるSubViewの通知は行わない
      mediaRecorder.stop();
    }
    const mediaRecorders = new Map(state.immutable.mediaRecorders);
    mediaRecorders.delete(connectionId);
    state.immutable.mediaRecorders = mediaRecorders;
  }

  // PresentationLayoutのmainConnectionIdsの更新
  const mainConnectionIds = state.mainConnectionIds;
  const filteredMainConnectionIds = mainConnectionIds.filter((id) => id !== connectionId);
  if (filteredMainConnectionIds.length === 0) {
    state.currentLayout = LAYOUT.GALLERY;
  }
  state.mainConnectionIds = filteredMainConnectionIds;
};
