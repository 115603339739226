// import React, { useEffect, useRef, useState } from "react";
import React, { useEffect, useRef } from "react";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import Tooltip from "@material-ui/core/Tooltip";
// import Fab from "@material-ui/core/Fab";
// import { makeStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";

import { actions } from "../redux/slice";
// import PresentationLayout from "../layouts/presentationLayout";
// import DeviceSettingDialog from "../components/deviceSettingDialog";
import InvisibleAudios from "../components/invisibleAudios";
// import RecordSettingDialog from "../components/recordingSettingDialog";
// import FullscreenLayout from "../layouts/fullscreenLayout";
// import GalleryLayout from "../layouts/galleryLayout";
import { RootState } from "../redux/store";
// import { defaultCustomParameter, LAYOUT } from "../utils/constants";
import { LAYOUT } from "../utils/constants";
// import Toolbar from "../components/toolbar";

// const useStyles = makeStyles({
//   fitParentWrapper: {
//     width: "100%",
//     height: "100%",
//     position: "relative",
//   },
//   subviewSwitch: {
//     position: "absolute",
//     bottom: "8px",
//     right: "8px",
//     color: defaultCustomParameter.theme.components.participantsVideoContainer.subViewSwitchIconColor,
//     backgroundColor: defaultCustomParameter.theme.components.participantsVideoContainer.subViewSwitchBackgroundColor,
//     "&:hover": {
//       color: defaultCustomParameter.theme.components.participantsVideoContainer.subViewSwitchIconColor,
//       backgroundColor: defaultCustomParameter.theme.components.participantsVideoContainer.subViewSwitchBackgroundColor,
//     },
//   },
// });

// type SubViewSwitchProps = {
//   onClick: () => void;
//   isHorizontal: boolean;
// };

// const SubViewSwitch: React.FC<SubViewSwitchProps> = (props) => {
//   const classes = useStyles();
//   const { t } = useTranslation();
//   const { onClick, isHorizontal } = props;
//   return (
//     <Tooltip
//       title={
//         <Typography variant="caption">
//           {isHorizontal ? t("presentationLayout.verticalButtonTips") : t("presentationLayout.horizontalButtonTips")}
//         </Typography>
//       }
//       placement="left"
//       style={{ margin: 0 }}
//     >
//       <Fab className={classes.subviewSwitch} onClick={onClick}>
//         <p className="material-icons-outlined">{isHorizontal ? "table_rows" : "view_week"}</p>
//       </Fab>
//     </Tooltip>
//   );
// };

// 操作がないときのツールバーが隠れるまでの ms (3.5 秒)
const TOOLBAR_HIDE_MS = 3.5 * 1000;

const Room: React.FC<{ username: string; roomId: string }> = (props) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const main = useSelector((state: RootState) => state.main);
  // const { currentLayout, isJoined, visibleRecordingSetting, isSubViewHorizontal } = main;
  const { currentLayout } = main;
  // const [showToolbar, setShowToolbar] = useState(true);
  // const [showSubViewSwitch, setShowSubViewSwitch] = useState(true);
  // ツールバーを一定時間の操作がない場合に非表示にするためのタイマー
  // ref object として扱うため、redux の store ではなく、component 内で管理している
  const toolbarTimer = useRef<number | null>(null);
  const handleShowToolbar = (): void => {
    // setShowToolbar(true);
    // setShowSubViewSwitch(true);
    if (toolbarTimer.current) {
      window.clearTimeout(toolbarTimer.current);
      toolbarTimer.current = null;
    }
    toolbarTimer.current = window.setTimeout(() => {
      // setShowToolbar(false);
      // setShowSubViewSwitch(false);
    }, TOOLBAR_HIDE_MS);
  };
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    handleShowToolbar();
  };
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
    e.preventDefault();
    handleShowToolbar();
  };

  // const handleToggleSwitch: () => void = () => {
  //   dispatch(actions.setIsSubViewHorizontal(!isSubViewHorizontal));
  // };

  // const [showDeviceSetting, setShowDeviceSetting] = useState(false);
  useEffect(() => {
    // 開始時に toolbar のタイマーをセットする
    handleShowToolbar();
    return () => {
      if (toolbarTimer.current) {
        window.clearTimeout(toolbarTimer.current);
        toolbarTimer.current = null;
      }
      dispatch(actions.stopConnect());
      dispatch(actions.stopScreenShare());
    };
  }, []);
  // let mainLayout = <PresentationLayout />;
  if (currentLayout === LAYOUT.GALLERY) {
    return (
      <div>
        <InvisibleAudios />
      </div>
    );
    // mainLayout = <GalleryLayout />;
  }
  // NOTE:本サービスでは常に currentLayout === LAYOUT.FULLSCREEN が true なので、<InvisibleAudios />のみ使用される。
  if (currentLayout === LAYOUT.FULLSCREEN) {
    return (
      <div>
        <InvisibleAudios />
      </div>
    );
    // mainLayout = <FullscreenLayout />;
  }
  // join するまでは何も表示しない
  // if (!isJoined) {
  //   return null;
  // }
  return (
    // <div onMouseMove={handleMouseMove} onTouchStart={handleTouchStart} className={classes.fitParentWrapper}>
    <div onMouseMove={handleMouseMove} onTouchStart={handleTouchStart}>
      {/* {mainLayout}
      {showToolbar && (
        <Toolbar handleDeviceSetting={() => setShowDeviceSetting(true)} username={props.username} roomId={props.roomId} />
      )}
      <SubViewSwitch onClick={handleToggleSwitch} isHorizontal={isSubViewHorizontal} />
      {!showSubViewSwitch && <SubViewSwitch onClick={handleToggleSwitch} isHorizontal={isSubViewHorizontal} />}
      <DeviceSettingDialog open={showDeviceSetting} onClose={() => setShowDeviceSetting(false)} />
      <RecordSettingDialog
        open={visibleRecordingSetting}
        onClose={() => dispatch(actions.setVisibleRecordingSetting({ connectionId: null, visible: false }))}
      />
      <InvisibleAudios /> */}
    </div>
  );
};

export default Room;
