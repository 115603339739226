import { Layout, RoomType } from "./types";
import { isBoolean, isValidBitrate, isValidBitrateReservationMbps, isValidLayout, isValidRoomType } from "./validators";

export const convert2NumberBitrate = (value: string | string[] | undefined): number | undefined => {
  return isValidBitrate(value) ? Number(value) : undefined;
};

export const convert2String = (value: string | string[] | undefined): string | undefined => {
  return !Array.isArray(value) ? value : undefined;
};

export const convert2Boolean = (value: string | string[] | undefined): boolean | undefined => {
  return isBoolean(value) ? Boolean(value) : undefined;
};

export const convert2Layout = (value: string | string[] | undefined): Layout | undefined => {
  return isValidLayout(value) ? (value as Layout) : undefined;
};

export const convert2RoomType = (value: string | string[] | undefined): RoomType | undefined => {
  return isValidRoomType(value) ? (value as RoomType) : undefined;
};

export const convert2NumberBitrateReservation = (value: string | string[] | undefined): number | undefined => {
  return isValidBitrateReservationMbps(value) ? Number(value) : undefined;
};

/**
 * 全角の英語を半角の英語に変換する関数
 * @param str - 全角の英語文字列
 * @returns 半角の英語文字列
 *
 * @example
 * // returns "Hello World"
 * convertFullWidthToHalfWidth("Ｈｅｌｌｏ Ｗｏｒｌｄ")
 */
export const convertFullWidthToHalfWidth = (str: string) => {
  return str.replace(/[Ａ-Ｚａ-ｚ]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
};
