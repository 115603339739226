// import React, { useContext, useEffect, useState } from "react";
import React, { useEffect, useState, useContext } from "react";
// import Button from "@material-ui/core/Button";
// import { makeStyles } from "@material-ui/core/styles";
import { Box, styled } from "@mui/material";

import LoginEntranceFormFieldGroup from "@/scripts/components/loginEntranceFormFieldGroup";
// import ConnectRequestDialog from "../components/connectRequestDialog";
// import i18n from "../../locales/i18n";
import { WebSocketContext } from "../utils/webSocket";
import { openMeetingWindow } from "../utils/transition";
// import { MESSAGE_TYPE } from "../utils/constants";
import { getCognitoUsername } from "../utils/cognito";
// import { Card, CardContent, MenuItem, TextField } from "@material-ui/core";
// import { fetchCognitoUsers } from "../utils/api";
// import { CognitoUser } from "../utils/types";

const CustomizedBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100 * var(--vh))",
  flexDirection: "column",
}));

// const useStyles = makeStyles({
//   callButton: {
//     width: "18rem",
//     margin: "1rem",
//   },
//   callCard: {
//     margin: "1em",
//   },
//   callCardContent: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: "1em 0",
//   },
//   calleeSelector: {
//     width: "18rem",
//     margin: "1em 0 0 0",
//   },
// });

// const useCallees = () => {
//   const [selectedCalleeUsername, setSelectedCalleeUsername] = useState<string>("");
//   const [callees, setCallees] = useState<CognitoUser[]>([]);
//   const controller = new AbortController();

//   useEffect(() => {
//     const currentUsername = getCognitoUsername(localStorage.getItem("id_token"));
//     fetchCognitoUsers(controller.signal)
//       .then((result) => {
//         if (result.users.length > 0) {
//           const callees = result.users.filter((user) => user.name !== currentUsername);
//           setCallees(callees);
//           setSelectedCalleeUsername(callees[0].name);
//         }
//       })
//       .catch((error) => {
//         // 現状はログ出力のみ
//         console.log(error.toString());
//       });
//     return () => {
//       controller.abort();
//     };
//   }, []);

//   return { callees, selectedCalleeUsername, setSelectedCalleeUsername };
// };

const Entrance: React.FC<Record<string, never>> = () => {
  // const classes = useStyles();
  const [roomId, setRoomId] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const { createUuid } = useContext(WebSocketContext);
  // const { sendMessage } = useContext(WebSocketContext);
  // const { callees, selectedCalleeUsername, setSelectedCalleeUsername } = useCallees();

  const onSubmitSuccess = (): void => {
    const myFrontendId = createUuid();
    sessionStorage.setItem("frontendId", myFrontendId);
    openMeetingWindow({ roomId, username });
  };
  // const onClickCall = () => {
  //   // TODO: 暫定的に現場作業者固定でCALLする（AGI_BRIG-300で対応）
  //   sendMessage({ messageType: MESSAGE_TYPE.CALL, callee: "worker" });
  // };

  useEffect(() => {
    const roomId = sessionStorage.getItem("myRoomId") || "Room-A";
    setRoomId(roomId);
    const idToken = sessionStorage.getItem("id_token") || "";
    const username = getCognitoUsername(idToken);
    setUsername(username);
  }, []);

  return (
    <>
      <CustomizedBox component="div">
        <LoginEntranceFormFieldGroup
          // username={username}
          roomId={roomId}
          onChangeRoomId={setRoomId}
          // onChangeUsername={setUsername}
          onSubmitSuccess={onSubmitSuccess}
        />
        {/* <Card className={classes.callCard}>
          <CardContent className={classes.callCardContent}>
            <TextField
              className={classes.calleeSelector}
              label={i18n.t("label.callee")}
              value={selectedCalleeUsername}
              onChange={(event) => setSelectedCalleeUsername(event.target.value)}
              variant="outlined"
              color="secondary"
              select
            >
              {callees.map((callee) => {
                return (
                  <MenuItem key={callee.name} value={callee.name}>
                    {callee.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <Button variant="contained" onClick={onClickCall} className={classes.callButton} color="primary">
              {i18n.t("button.call")}
            </Button>
          </CardContent>
        </Card> */}
      </CustomizedBox>
      {/* <ConnectRequestDialog /> */}
    </>
  );
};

export default Entrance;
