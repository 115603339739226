import { getAudioTracks, getVideoTracks } from "../../utils/liveStreamingHelpers";
import { actions } from "../slice";
import { AppDispatch, RootState, RootThunk } from "../store";

export const asyncReplaceVideoWithAutoReboot = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  videoDeviceId?: string
): Promise<void> => {
  const { immutable, self } = getState().main;
  if (!immutable.videoAudioClient) {
    return;
  }

  const videoTracks = self.enableVideo ? await getVideoTracks(videoDeviceId) : [];
  const videoTrack = videoTracks.length > 0 ? videoTracks[0] : undefined;
  for (const lsTrack of immutable.localTracks) {
    if (lsTrack.mediaStreamTrack.kind === "video" && videoTrack) {
      lsTrack.mediaStreamTrack.stop();
      await immutable.videoAudioClient.replaceMediaStreamTrack(lsTrack, videoTrack);
    }
  }
  if (videoTrack) {
    videoTrack.addEventListener("ended", () => {
      // iOS@14.2 + Safari@14.0.1 にてカメラストリームとHTMLビデオ要素を作成/停止/再作成/アタッチ/デタッチ/再アタッチすると
      // ビデオが停止状態になる問題に対処するため、ended イベントを受信したら再割り当てを行う
      asyncReplaceVideoWithAutoReboot(dispatch, getState, videoDeviceId);
    });
  }

  dispatch(actions.updateLocalVideo({ videoTrack, videoDeviceId }));
};

export const asyncReplaceAudio = async (
  dispatch: AppDispatch,
  getState: () => RootState,
  audioDeviceId?: string
): Promise<void> => {
  const { immutable, self } = getState().main;
  if (!immutable.videoAudioClient) {
    return;
  }

  const audioTracks = self.enableAudio ? await getAudioTracks(audioDeviceId) : [];
  const audioTrack = audioTracks.length > 0 ? audioTracks[0] : undefined;
  for (const lsTrack of immutable.localTracks) {
    if (lsTrack.mediaStreamTrack.kind === "audio" && audioTrack) {
      lsTrack.mediaStreamTrack.stop();
      await immutable.videoAudioClient.replaceMediaStreamTrack(lsTrack, audioTrack);
    }
  }
  dispatch(actions.updateLocalAudio({ audioTrack, audioDeviceId }));
};

export const asyncReplaceVideoAudio =
  (videoDeviceId?: string, audioDeviceId?: string): RootThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await asyncReplaceAudio(dispatch, getState, audioDeviceId);
    await asyncReplaceVideoWithAutoReboot(dispatch, getState, videoDeviceId);
  };

export const asyncAudioReplace =
  (audioDeviceId?: string): RootThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await asyncReplaceAudio(dispatch, getState, audioDeviceId);
  };
