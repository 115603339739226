import React, { useContext } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import Entrance from "./entrance";
import IframePage from "./iframePage";
import Signin from "./signin";
import Page404 from "./Page404";

import { useWebSocket, WebSocketContext } from "../utils/webSocket";
import { openMeetingWindow } from "../utils/transition";
import { WebSocketContextProps, RouteProps } from "../utils/types";
import { getCognitoUsername } from "../utils/cognito";
import { WEBSOCKET_ERROR_CODE } from "../utils/constants";

const AuthenticatedRoute: React.FC<RouteProps> = (props) => {
  const { isConnected, connectWebSocket } = useContext(WebSocketContext);
  const savedIdToken = sessionStorage.getItem("id_token");
  // IDトークンの有効期限のチェックなどが必要だが
  // 現時点ではbackend APIを叩くときにIDトークンを渡していないので未対応
  if (savedIdToken) {
    if (!isConnected) {
      connectWebSocket(savedIdToken);
    }
    return <>{props.element}</>;
  }
  return <Navigate to="/signin" state={{ from: location.pathname + location.search }} />;
};

const UnauthenticatedRoute: React.FC<RouteProps> = (props) => {
  const savedIdToken = sessionStorage.getItem("id_token");
  // IDトークンの有効期限のチェックなどが必要だが
  // 現時点ではbackend APIを叩くときにIDトークンを渡していないので未対応
  if (savedIdToken) {
    return <Navigate to="/" />;
  }
  return <>{props.element}</>;
};

const App: React.FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const webSocket: WebSocketContextProps = useWebSocket({
    initialMessages: {},
    onCallSuccess: (roomId: string) => {
      const myFrontendId = webSocket.createUuid();
      sessionStorage.setItem("frontendId", myFrontendId);
      openMeetingWindow({ roomId, username: getCognitoUsername(sessionStorage.getItem("id_token")) });
    },
    onError: (message, closeConnect) => {
      switch (message.code) {
        case WEBSOCKET_ERROR_CODE.INVALID_TOKEN:
          closeConnect();
          sessionStorage.removeItem("id_token");
          sessionStorage.removeItem("refresh_token");
          navigate("/signin", { state: { isTokenInvalid: true } });
          break;
        case WEBSOCKET_ERROR_CODE.INVALID_PARAM:
        case WEBSOCKET_ERROR_CODE.UNEXPECTED:
          // 現状は実装ミスの場合しか発生しないのでログ出力のみ行う
          // console.log(`Error code ${message.code}: ${message.detail}`);
          console.error(`Error code ${message.code}`);
          break;
        default:
          // console.log("Unexpected error code.");
          console.error("Unexpected error code.");
      }
    },
  });

  return (
    <WebSocketContext.Provider value={{ ...webSocket }}>
      <Routes>
        <Route path="/" element={<AuthenticatedRoute element={<Entrance />} />} />
        <Route path="/entrance" element={<AuthenticatedRoute element={<Entrance />} />} />
        <Route path="/room/:roomId" element={<AuthenticatedRoute element={<IframePage />} />} />
        <Route path="/signin" element={<UnauthenticatedRoute element={<Signin />} />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </WebSocketContext.Provider>
  );
};

export default App;
