// isTheta = false && isPod = false のときの video view
// 普通に video.srcObject stream を流す
// mute && volume = 0 にする
import React, { MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { styled } from "@mui/material";

import { LocalConnectOption } from "../utils/types";
import AlertLatency from "../atoms/AlertLatency";
/*
  TODO (TsukasaSeto): setSinkId は HTMLMediaElement のExperimentalな機能であるため、型定義がない
  HTMLMediaElement に setSinkId の型定義が追加されたら CustomHTMLVideoを削除する
*/
type CustomHTMLVideoElement = HTMLVideoElement & {
  setSinkId: (id: string) => Promise<void>;
};

interface NormalVideoViewProps {
  stream: MediaStream | null;
  connectOption?: LocalConnectOption | null;
  selectedSpeakerId?: string | null;
  videoSizeReceived?: (width: number, height: number) => void;
}

const CustomizedVideo = styled("video")(() => ({
  width: "100%",
  height: "100%",
  display: "block",
  backgroundColor: "var(--lsconf-theme-video-background)",
  objectFit: "contain",
}));

const NormalVideo: React.FC<NormalVideoViewProps> = (props) => {
  const { stream, selectedSpeakerId } = props;
  const videoRef: MutableRefObject<CustomHTMLVideoElement> = useRef() as MutableRefObject<CustomHTMLVideoElement>;
  const [enableLatencyHandling, setEnableLatencyHandling] = useState<boolean>(true);
  const [showLatencyAlert, setShowLatencyAlert] = useState<boolean>(false);

  const isTestFeatureVisible = Boolean(process.env.IS_TEST_FEATURE_VISIBLE);

  // 遅延判定用の変数
  const [checkCount, setCheckCount] = useState<number>(0);
  const [latencyCount, setLatencyCount] = useState<number>(0);
  const maxCheckCount = 300;
  const latencyAlertThreshold = maxCheckCount / 2;

  //デバック用
  const [latencyResults, setLatencyResults] = useState<number[]>([]);

  // 閾値。値は仮。動画のfpsによって閾値は変えた方が良いかもしれません.
  let maxAcceptableFPS: number;
  if (process.env.MAX_ACCEPTABLE_FPS != null) {
    maxAcceptableFPS = Number(process.env.MAX_ACCEPTABLE_FPS);
  } else {
    maxAcceptableFPS = 10;
  }

  let lastFrameTime: number;
  let lastPresentedFrames: number;
  useEffect(() => {
    const handleVideoFrame = (now: DOMHighResTimeStamp, metadata) => {
      const { presentedFrames } = metadata;
      if (lastPresentedFrames && lastFrameTime) {
        // framesElapsed :前回のフレームからの経過したフレーム数
        const framesElapsed = presentedFrames - lastPresentedFrames;
        // timeElapsedMicros :前回のフレームからの経過した時間をマイクロ秒単位で計算。
        const timeElapsedMicros = (now - lastFrameTime) * 1000;
        const frameTimeMicros = timeElapsedMicros / framesElapsed;
        const fps = 1000000 / frameTimeMicros;
        // console.log(`FPS: ${fps.toFixed(2)}`);

        if (fps < maxAcceptableFPS) {
          setLatencyCount((prevCount) => prevCount + 1);
        }

        setLatencyResults((prevResults) => {
          const updatedResults = [...prevResults, fps];
          if (updatedResults.length > maxCheckCount) {
            updatedResults.length = 0;
          }
          return updatedResults;
        });

        setCheckCount((prevCount) => prevCount + 1);
      }

      lastPresentedFrames = presentedFrames;
      lastFrameTime = now;

      videoRef.current.requestVideoFrameCallback(handleVideoFrame);
    };

    videoRef.current.requestVideoFrameCallback(handleVideoFrame);
    return;
  }, []);

  useEffect(() => {
    if (checkCount >= maxCheckCount) {
      const currentTime = new Date().toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      console.log(
        "checkCount:",
        checkCount,
        "latencyCount:",
        latencyCount,
        "maxAcceptableFPS:",
        maxAcceptableFPS,
        "currentTime:",
        currentTime,
        "latencyResults(fps):",
        latencyResults
      );
      setLatencyResults([]);

      if (latencyCount >= latencyAlertThreshold) {
        setShowLatencyAlert(true);
      } else {
        setShowLatencyAlert(false);
      }
      setCheckCount(0);
      setLatencyCount(0);
    }
  }, [checkCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current.readyState >= HTMLMediaElement.HAVE_METADATA) {
        // clearInterval(interval);
        if (props.videoSizeReceived) {
          props.videoSizeReceived(videoRef.current.videoWidth, videoRef.current.videoHeight);
        }
      }
    }, 300);
    return () => clearInterval(interval);
  }, []);

  useLayoutEffect(() => {
    if (!videoRef.current || !stream) {
      return;
    }
    videoRef.current.srcObject = stream;
    videoRef.current.volume = 0;
    videoRef.current.muted = true;
    if (videoRef.current.setSinkId && selectedSpeakerId !== undefined && selectedSpeakerId !== null) {
      videoRef.current.setSinkId(selectedSpeakerId);
    }

    // iOSにて、suspend -> canplayイベント発火時に自動再生(autoPlay)されない場合があるため
    videoRef.current.addEventListener("canplay", () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    });

    // unmount時にvideoRef.currentが変化している可能性があるため、別変数で参照を保持する
    const currentEl = videoRef.current;

    // Chrome 92 から WebMediaPlayer数の上限(PCは75,Mobileは40)が設定され、それを超えるとWebMediaPlayerの生成がブロックされることになった
    // cf: https://chromium-review.googlesource.com/c/chromium/src/+/2816118
    // この対応として、unmount時にsrcObjectを都度解放することで再描画のたびにWebMediaPlayer数がカウントアップされるのを防ぐ
    return () => {
      if (currentEl) {
        currentEl.srcObject = null;
      }
    };
  }, [videoRef, stream, selectedSpeakerId]);

  const handleClose = useCallback((isCloseButtonClicked: boolean) => {
    setCheckCount(0);
    setShowLatencyAlert(false);
    if (isCloseButtonClicked) setEnableLatencyHandling(false);
  }, []);

  return (
    <>
      {isTestFeatureVisible && (
        <AlertLatency
          enableLatencyHandling={enableLatencyHandling}
          showLatencyAlert={showLatencyAlert}
          connectOption={props.connectOption}
          onClose={handleClose}
        />
      )}
      <CustomizedVideo ref={videoRef} controls={false} autoPlay playsInline preload="metadata" />;
    </>
  );
};

export default React.memo(NormalVideo);
