import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  MenuItem,
  Icon,
  Switch,
  Stack,
  Slider,
  TextField,
  Divider,
} from "@mui/material";
import { DUMMY_DEVICE_ID, MEDIA_DEVICE_KIND } from "../utils/constants";
import { asyncReplaceVideoAudio, asyncAudioReplace } from "../redux/asyncActionCreators/videoReplace";
import { actions } from "../redux/slice";
import { RootState } from "../redux/store";
import { CanvasElement } from "../utils/types";

import CustomButton from "../atoms/customButton";

interface DeviceSettingDialogProps {
  open?: boolean;
  onClose?: () => void;
  onCloseCalib?: (direction: string) => void;
  handleApplyCameraControl?: (isBacklightCompensation: boolean, brightness: number, brightnessCalculationMode: number) => void;
}

// const useStyles = makeStyles({
//   cameraPreviewContainer: {
//     position: "relative",
//     width: "100%",
//     maxWidth: "432px",
//     marginTop: "0.5em",
//     paddingBottom: "46.25%",
//     "& video": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//     },
//   },
//   SettingName: {
//     marginTop: "1.5em",
//   },
//   deviceSelectorContainer: {
//     margin: "0.5em 0",
//   },
//   deviceSelect: {
//     margin: "0.5em 0",
//     width: "100%",
//   },
//   calibButtonIcon: {
//     marginLeft: "0.2em",
//   },
//   dialogContent: {
//     overflowY: "hidden",
//   },
// });

const DeviceSettingDialog: React.FC<DeviceSettingDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const latestOpen = useRef(props.open);
  const { immutable, selectedSpeakerId, useDummyDevice } = useSelector((state: RootState) => state.main);

  const [selectedVideo, setSelectedVideo] = useState<DeviceInfo | undefined>(undefined);
  const [selectedMic, setSelectedMic] = useState<DeviceInfo | undefined>(undefined);
  const [selectedSpeaker, setSelectedSpeaker] = useState<DeviceInfo | undefined>(undefined);
  const [selectableDevices, setSelectableDevices] = useState<DeviceInfo[]>([]);

  const videoRef: MutableRefObject<HTMLVideoElement> = useRef() as MutableRefObject<HTMLVideoElement>;
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);

  const ua = navigator.userAgent.toLowerCase();
  const isSafari = ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1 && ua.indexOf("edge") === -1;

  // NOTE:バージョン表示
  const versionToDisplay = process.env.VERSION;

  const localStorageBrightnessMode = localStorage.getItem("is_brightness_modeChecked") || null;
  const initialIsBrightnessMode = localStorageBrightnessMode === "false" ? false : true;
  const [isBrightnessModeChecked, setIsBrightnessModeChecked] = useState(initialIsBrightnessMode);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBrightnessModeChecked(event.target.checked);
  };

  const localStorageBrightness = localStorage.getItem("brightness") || null;
  const initialBrightness = localStorageBrightness === null ? 24 : Number(localStorageBrightness);
  const [brightness, setBrightness] = useState(initialBrightness);
  const handleBrightnessChange = (event: Event, newBrightness: number) => {
    setBrightness(newBrightness);
  };

  const localStorageIsBacklightCompensation = localStorage.getItem("is_backlight_compensation") || null;
  const initialIsBacklightCompensation = localStorageIsBacklightCompensation === "false" ? false : true;
  const [isBacklightCompensation, setIsBacklightCompensation] = useState(initialIsBacklightCompensation);
  const onSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBacklightCompensation(event.target.checked);
  };

  type DeviceInfo = {
    label: string;
    kind: MediaDeviceKind;
    value: string;
  };

  const getConnectedDevices = async (kind?: MediaDeviceKind): Promise<DeviceInfo[]> => {
    let devices = await navigator.mediaDevices.enumerateDevices();
    if (kind) {
      devices = devices.filter((device) => device.kind === kind);
    }
    const options: DeviceInfo[] = [];
    devices.forEach((device) => options.push({ label: device.label, kind: device.kind, value: device.deviceId }));

    // ダミーデバイス追加
    if (useDummyDevice) {
      options.push({ label: t("deviceSettingsDialog.notUsed"), kind: "videoinput", value: DUMMY_DEVICE_ID });
      options.push({ label: t("deviceSettingsDialog.notUsed"), kind: "audioinput", value: DUMMY_DEVICE_ID });
    }

    return options;
  };

  const getCurrentInputDevice = async (kind: MediaDeviceKind): Promise<DeviceInfo | undefined> => {
    if (kind === MEDIA_DEVICE_KIND.AUDIO_OUTPUT) {
      return undefined;
    }
    if (!immutable.localTracks) {
      return undefined;
    }
    let currentDevice = undefined;
    const targetTrackKind = kind === MEDIA_DEVICE_KIND.VIDEO_INPUT ? "video" : "audio";
    const track = immutable.localTracks.find((track) => track.mediaStreamTrack.kind === targetTrackKind);

    const micStorage = localStorage.getItem("selected_mic") || undefined;
    if (targetTrackKind === "audio" && micStorage !== track.mediaStreamTrack.getSettings().deviceId) {
      dispatch(asyncAudioReplace(micStorage));
    }
    if (track) {
      const options = await getConnectedDevices(kind);
      options.forEach((option) => {
        if (option.value === track.mediaStreamTrack.getSettings().deviceId) {
          currentDevice = option;
          return;
        }
      });

      // ダミーデバイス選択
      if (!currentDevice && options.findIndex((option) => option.value === DUMMY_DEVICE_ID) !== -1) {
        currentDevice = options.find((option) => option.value === DUMMY_DEVICE_ID);
      }
    }
    return currentDevice;
  };

  const getCurrentSpeaker = async (): Promise<DeviceInfo | undefined> => {
    let currentSpeaker = undefined;
    const options = await getConnectedDevices(MEDIA_DEVICE_KIND.AUDIO_OUTPUT);

    const speakerStorage = localStorage.getItem("selected_speaker") || undefined;
    if (speakerStorage) {
      dispatch(actions.changeSpeakerDevice(speakerStorage));

      for (const option of options) {
        if (option.value === speakerStorage) {
          currentSpeaker = option;
          break;
        }
      }
    } else if (selectedSpeakerId) {
      for (const option of options) {
        if (option.value === selectedSpeakerId) {
          currentSpeaker = option;
          break;
        }
      }
    } else {
      if (!immutable.localTracks) {
        return undefined;
      }
      const track = immutable.localTracks.find((track) => track.mediaStreamTrack.kind === "audio");
      if (track) {
        for (const option of options) {
          if (option.value === track.mediaStreamTrack.getSettings().deviceId) {
            currentSpeaker = option;
            break;
          }
        }
      }
    }
    return currentSpeaker;
  };

  const getDummyMedia = async (): Promise<MediaStream> => {
    const canvas = document.createElement("canvas");
    canvas.getContext("2d");
    return (canvas as CanvasElement).captureStream();
  };

  const playVideo = async (deviceId: string): Promise<void> => {
    if (deviceId !== DUMMY_DEVICE_ID) {
      const constraints: MediaStreamConstraints = {
        video: {
          deviceId: { exact: deviceId },
          aspectRatio: 16 / 9,
        },
        audio: false,
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (latestOpen.current && !videoRef.current.srcObject) {
        setVideoStream(stream);
      } else {
        // 既にダイアログが閉じられている場合または既に videoStream が取得されている場合は stream を停止する
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      }
    } else {
      const stream = await getDummyMedia();
      setVideoStream(stream);
    }
  };

  const stopVideo = async (): Promise<void> => {
    if (!videoStream) {
      return;
    }
    videoStream.getTracks().forEach((track) => track.stop());
    setVideoStream(null);
  };

  const handleOpen = async (): Promise<void> => {
    latestOpen.current = true;
    try {
      // enumerateDevices は、getUserMedia が呼び出されるまで、デフォルトのデバイスのみを取得するため
      // 権限を付与するために getUserMedia を呼び出す
      // cf: https://stackoverflow.com/questions/61629512/safari-13-1-navigator-mediadevices-enumeratedevices-return-only-audio-devices
      if (useDummyDevice) {
        const constraints: MediaStreamConstraints = {
          video: {
            aspectRatio: 16 / 9,
          },
          audio: true,
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      }
      const connectedDevices = await getConnectedDevices();
      const currentVideo = await getCurrentInputDevice(MEDIA_DEVICE_KIND.VIDEO_INPUT);
      const currentMic = await getCurrentInputDevice(MEDIA_DEVICE_KIND.AUDIO_INPUT);
      const currentSpeaker = await getCurrentSpeaker();
      setSelectableDevices(connectedDevices);
      setSelectedVideo(currentVideo);
      setSelectedMic(currentMic);
      setSelectedSpeaker(currentSpeaker);

      // iOS@14.2 + Safari@14.0.1 にて getUserMedia 呼び出し時に自拠点の video が強制終了されるため
      // プレビューを開始する前にメディアを停止する
      if (isSafari && immutable.localVideoAudioStream) {
        immutable.localVideoAudioStream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      if (currentVideo && currentVideo.value) {
        await playVideo(currentVideo.value);
      }
    } catch (e) {
      return;
    }
  };

  const handleClose = async (reason?: string): Promise<void> => {
    latestOpen.current = false;
    setSelectableDevices([]);
    setSelectedVideo(undefined);
    setSelectedMic(undefined);
    setSelectedSpeaker(undefined);
    await stopVideo();

    // iOS@14.2 + Safari@14.0.1 の場合はキャンセル時にメディアを再開する
    if (isSafari && reason !== "escapeKeyDown" && reason !== "backdropClick") {
      const currentVideo = await getCurrentInputDevice(MEDIA_DEVICE_KIND.VIDEO_INPUT);
      const currentMic = await getCurrentInputDevice(MEDIA_DEVICE_KIND.AUDIO_INPUT);
      const videoDeviceId = currentVideo ? currentVideo.value : undefined;
      const audioDeviceId = currentMic ? currentMic.value : undefined;
      dispatch(asyncReplaceVideoAudio(videoDeviceId, audioDeviceId));
    }

    props.onClose();
  };

  const handleApply = (): void => {
    console.log(selectedVideo);
    // if (selectedVideo && selectedMic) {
    //   dispatch(asyncReplaceVideoAudio(selectedVideo.value, selectedMic.value));
    // }
    // NOTE: マイクとスピーカーはReduxの値を取得して表示しているので、ブラウザに保存せず、Reduxの値を永続化する方向で実装するのが良いかもしれない
    if (selectedMic) {
      localStorage.setItem("selected_mic", selectedMic.value);
      dispatch(asyncAudioReplace(selectedMic.value));
    }
    if (selectedSpeaker) {
      localStorage.setItem("selected_speaker", selectedSpeaker.value);
      dispatch(actions.changeSpeakerDevice(selectedSpeaker.value || null));
    }

    if (
      initialIsBrightnessMode === isBrightnessModeChecked &&
      initialBrightness === brightness &&
      initialIsBacklightCompensation === isBacklightCompensation
    ) {
      return;
    }

    // NOTE: AUTO_EXPOSURE の初期値は 8 で、カメラの通常状態の自動露出制御は 0 ではないようなので、0 ではなく null でデフォルト値に戻す。
    const brightnessCalculationMode = isBrightnessModeChecked ? null : 1;
    props.handleApplyCameraControl(isBacklightCompensation, brightness, brightnessCalculationMode);

    localStorage.setItem("is_brightness_modeChecked", String(isBrightnessModeChecked));
    localStorage.setItem("brightness", String(brightness));
    localStorage.setItem("is_backlight_compensation", String(isBacklightCompensation));
  };

  // HACK: マイクやスピーカーと同じように、カメラ映像の設定値をReduxに移した方が良いかもしれない。
  // → 公式では、必ずしも全部のステートをReduxに置く必要はなく、UI状態などは useState() を使うのもありとなっていた。
  // Ref : https://redux.js.org/faq/organizing-state#do-i-have-to-put-all-my-state-into-redux-should-i-ever-use-reacts-usestate-or-usereducer
  // 今はダイアログが非表示でもコンポーネントはrenderされていてステートを保持できていますが、ダイアログが保持する値ではないと思うのでReduxに移す方向が良さそう。
  // また、今の適用前の brightness などはダイアログ表示のためのステートなので useState() のままで、確定後の prevBrightness はダイアログの値ではないのでReduxに移す。
  const handleCameraSettingsCancel = (): void => {
    setIsBrightnessModeChecked(initialIsBrightnessMode);
    setBrightness(initialBrightness);
    setIsBacklightCompensation(initialIsBacklightCompensation);
  };

  const handleChangeDevice = async (kind: MediaDeviceKind, deviceId: string): Promise<void> => {
    const options = selectableDevices.filter((device) => device.kind === kind);
    options.forEach((option) => {
      if (option.value === deviceId) {
        switch (kind) {
          case "videoinput":
            setSelectedVideo({ label: option.label, value: deviceId, kind: option.kind });
            break;
          case "audioinput":
            setSelectedMic({ label: option.label, value: deviceId, kind: option.kind });
            break;
          case "audiooutput":
            setSelectedSpeaker({ label: option.label, value: deviceId, kind: option.kind });
            break;
          default:
            break;
        }
      }
    });
    if (kind === "videoinput") {
      await stopVideo();
      await playVideo(deviceId);
    }
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.srcObject = videoStream;

    // unmount時にvideoRef.currentが変化している可能性があるため、別変数で参照を保持する
    const currentEl = videoRef.current;

    // Chrome 92 から WebMediaPlayer数の上限(PCは75,Mobileは40)が設定され、それを超えるとWebMediaPlayerの生成がブロックされることになった
    // cf: https://chromium-review.googlesource.com/c/chromium/src/+/2816118
    // この対応として、unmount時にsrcObjectを都度解放することで再描画のたびにWebMediaPlayer数がカウントアップされるのを防ぐ
    return () => {
      if (currentEl) {
        currentEl.srcObject = null;
      }
    };
  }, [videoRef, videoStream]);

  return (
    <Dialog
      sx={{ "& .MuiBackdrop-root": { backgroundColor: "transparent" } }}
      open={props.open}
      TransitionProps={{ onEnter: handleOpen }}
      onClose={(_event, reason) => {
        handleClose(reason);
        setTimeout(() => {
          handleCameraSettingsCancel();
        }, 500);
      }}
      // NOTE: TextFieldのプルダウンを変更した際にダイアログの横の長さも変更されてしまうので、pxで固定
      PaperProps={{
        style: { maxWidth: "720px", minWidth: "720px" },
      }}
    >
      <DialogTitle>{t("deviceSettingsDialog.title")}</DialogTitle>
      <DialogContent sx={{ overflowY: "Hidden" }} dividers>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            {t("deviceSettingsDialog.calib")}
            <DialogContentText id="alert-dialog-slide-description" sx={{ mt: "0.5em", ml: "0.5em" }}>
              {t("deviceSettingsDialog.calibContent")}
            </DialogContentText>
          </Box>

          <Box
            component="div"
            sx={{ mt: "0.5em", ml: "0.5em" }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomButton
              id="calib_L"
              color="secondary"
              margin="0 1.2em 0 0.2em"
              onClick={() => {
                props.onCloseCalib("left");
              }}
            >
              {t("deviceSettingsDialog.calibLeftButtont")}
              <Icon sx={{ ml: "0.3em" }}>crop_free</Icon>
            </CustomButton>
            <CustomButton
              id="calib_R"
              color="secondary"
              margin="0 1.2em 0 0.2em"
              onClick={() => {
                props.onCloseCalib("right");
              }}
            >
              {t("deviceSettingsDialog.calibRightButtont")}
              <Icon sx={{ ml: "0.3em" }}>crop_free</Icon>
            </CustomButton>
          </Box>
        </Box>

        <Box component="div" sx={{ mt: "1em", mr: "1em" }}>
          <Divider />
          <Box sx={{ mt: "1em" }} display="flex" alignItems="center" justifyContent="space-between">
            {t("deviceSettingsDialog.cameraImage")}
          </Box>

          <Box component="div" sx={{ mt: "0.4em", ml: "1em" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {t("deviceSettingsDialog.brightnessContent")}
              <Stack sx={{ width: "20rem" }} spacing={3} direction="row" alignItems="center">
                <FormControlLabel
                  sx={{ width: "7rem" }}
                  control={<Checkbox checked={isBrightnessModeChecked} onChange={handleChange} color="secondary" />}
                  label={t("deviceSettingsDialog.auto")}
                  labelPlacement="start"
                />
                <Slider
                  disabled={isBrightnessModeChecked}
                  size="small"
                  min={1}
                  max={38}
                  valueLabelDisplay="auto"
                  aria-label="Volume"
                  value={brightness}
                  onChange={handleBrightnessChange}
                  color="secondary"
                />
              </Stack>
            </Box>
          </Box>

          <Box component="div" sx={{ mt: "0.4em", ml: "1em" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {t("deviceSettingsDialog.videoContent")}
              <Box>
                <FormControlLabel
                  control={<Switch checked={isBacklightCompensation} onChange={onSwitch} color="secondary" />}
                  label={isBacklightCompensation ? t("deviceSettingsDialog.on") : t("deviceSettingsDialog.off")}
                  labelPlacement="start"
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box component="div" sx={{ mt: "1em", mr: "1em" }}>
          <Divider />
          <Box component="div" sx={{ mt: "1em" }}>
            {t("deviceSettingsDialog.device")}
          </Box>
          {/* <div className={classes.cameraPreviewContainer}>
          <video ref={videoRef} muted autoPlay playsInline />
        </div> */}
          <Box component="div" sx={{ mt: "1em", mb: "0.5em", ml: "1em" }}>
            {/* <TextField
            className={classes.deviceSelect}
            variant="outlined"
            label={t("deviceSettingsDialog.camera")}
            disabled={selectableDevices.filter((device) => device.kind === MEDIA_DEVICE_KIND.VIDEO_INPUT).length < 1}
            value={selectedVideo ? selectedVideo.value : ""}
            onChange={(event) => handleChangeDevice(MEDIA_DEVICE_KIND.VIDEO_INPUT, event.target.value as string)}
            select
          >
            {selectableDevices
              .filter((device) => device.kind === MEDIA_DEVICE_KIND.VIDEO_INPUT)
              .map((device) => {
                return (
                  <MenuItem key={device.value} value={device.value}>
                    {device.label}
                  </MenuItem>
                );
              })}
          </TextField> */}
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              label={t("deviceSettingsDialog.mic")}
              disabled={selectableDevices.filter((device) => device.kind === MEDIA_DEVICE_KIND.AUDIO_INPUT).length < 1}
              value={selectedMic ? selectedMic.value : ""}
              onChange={(event) => handleChangeDevice(MEDIA_DEVICE_KIND.AUDIO_INPUT, event.target.value as string)}
              select
              InputLabelProps={{ shrink: true }}
            >
              {selectableDevices
                .filter((device) => device.kind === MEDIA_DEVICE_KIND.AUDIO_INPUT)
                .map((device) => {
                  return (
                    <MenuItem key={device.value} value={device.value}>
                      {device.label}
                    </MenuItem>
                  );
                })}
            </TextField>
            <TextField
              style={{ width: "100%", marginTop: "1em" }}
              variant="outlined"
              label={t("deviceSettingsDialog.speaker")}
              disabled={selectableDevices.filter((device) => device.kind === MEDIA_DEVICE_KIND.AUDIO_OUTPUT).length < 1}
              value={selectedSpeaker ? selectedSpeaker.value : ""}
              onChange={(event) => handleChangeDevice("audiooutput", event.target.value as string)}
              select
              InputLabelProps={{ shrink: true }}
            >
              {selectableDevices
                .filter((device) => device.kind === MEDIA_DEVICE_KIND.AUDIO_OUTPUT)
                .map((device) => {
                  return (
                    <MenuItem key={device.value} value={device.value}>
                      {device.label}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Box>
        </Box>
        {versionToDisplay && (
          <Box component="div" sx={{ mt: "1em" }}>
            <Divider />
            <Box sx={{ mt: "1em" }}>{t("deviceSettingsDialog.versionInfo")}</Box>

            <Box component="div" sx={{ mt: "0.7em", ml: "1em" }}>
              {t("deviceSettingsDialog.version")}
              {versionToDisplay}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleApply();
          }}
        >
          {t("deviceSettingsDialog.apply")}
        </Button>
        <Button
          onClick={() => {
            handleApply();
            handleClose();
          }}
        >
          {t("deviceSettingsDialog.ok")}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            setTimeout(() => {
              handleCameraSettingsCancel();
            }, 500);
          }}
        >
          {t("deviceSettingsDialog.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceSettingDialog;
