import { Client, ConnectionID } from "ricoh-ls-sdk";
import { MainState } from "../redux/slice";
import { DUMMY_DEVICE_ID, MIME_TYPE } from "./constants";
import { CanvasElement } from "./types";

export const stopStream = (stream: MediaStream | null): void => {
  if (!stream) return;
  stream.getTracks().forEach((track) => {
    track.stop();
    stream.removeTrack(track);
  });
};

// DEBUG環境かつ username が theta-test の場合は theta の映像としてあつかうための判定メソッド
export const isDebugThetaUser = (username: string): boolean => {
  return process.env.DEBUG !== undefined && username === "theta-test";
};

// DEBUG環境かつ username が pod-test の場合は VRPod の映像としてあつかうための判定メソッド
export const isDebugPodUser = (username: string): boolean => {
  return process.env.DEBUG !== undefined && username === "pod-test";
};

export const getVideoTracks = async (videoDeviceId: string | undefined): Promise<MediaStreamTrack[]> => {
  let videoTracks: MediaStreamTrack[] = [];
  if (!videoDeviceId) {
    return [];
  }
  if (videoDeviceId === DUMMY_DEVICE_ID) {
    // video ダミーデバイス
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.fillRect(0, 0, canvas.width, canvas.height);
    }
    const videoStream = (canvas as CanvasElement).captureStream();
    videoTracks = videoStream.getTracks();
  } else {
    try {
      let constraints: MediaStreamConstraints;
      if (videoDeviceId === "default") {
        constraints = {
          video: {
            aspectRatio: 16 / 9,
          },
        };
      } else {
        constraints = {
          video: {
            deviceId: { exact: videoDeviceId },
            aspectRatio: 16 / 9,
          },
        };
      }
      const videoStream = await navigator.mediaDevices.getUserMedia(constraints);
      videoTracks = videoStream.getTracks();
    } catch {
      return [];
    }
  }
  return videoTracks;
};

export const getAudioTracks = async (audioDeviceId: string | undefined): Promise<MediaStreamTrack[]> => {
  let audioTracks: MediaStreamTrack[] = [];
  if (!audioDeviceId) {
    return [];
  }
  if (audioDeviceId === DUMMY_DEVICE_ID) {
    // audio ダミーデバイス
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
    const audioStream = audioContext.createMediaStreamDestination().stream;
    audioTracks = audioStream.getTracks();
  } else {
    try {
      const constraints = {
        audio: audioDeviceId === "default" ? true : { deviceId: { exact: audioDeviceId } },
      };
      const audioStream = await navigator.mediaDevices.getUserMedia(constraints);
      audioTracks = audioStream.getTracks();
    } catch {
      return [];
    }
  }
  return audioTracks;
};

export const getDummyMedia = async (): Promise<MediaStream> => {
  const videoTracks = await getVideoTracks(DUMMY_DEVICE_ID);
  const audioTracks = await getAudioTracks(DUMMY_DEVICE_ID);
  const tracks = videoTracks.concat(audioTracks);
  return new MediaStream(tracks);
};

export const getCaptureImage = ({
  state,
  targetConnectionId,
  mimeType,
  quality,
}: {
  state: MainState;
  targetConnectionId: ConnectionID;
  mimeType?: MIME_TYPE;
  quality?: number;
}): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const { videoStreams, screenShareStreams, otherMembers } = state.immutable;
    const targetVideo = videoStreams.get(targetConnectionId);
    const targetScreenShare = screenShareStreams.get(targetConnectionId);
    const targetMember = otherMembers.get(targetConnectionId);
    const targetStream = targetVideo ? targetVideo : targetScreenShare;

    if (!targetStream) {
      // console.log(`Not found SubView: ${targetConnectionId}`);
      console.error("Not found SubView");
      reject();
      return;
    }

    // 画面共有は1Memberとして扱っていないため、対象のSubViewが画面共有の場合は現実装に合わせて true で決め打ちとする
    let isVideoEnabled = true;
    if (state.self.connectionId === targetConnectionId) {
      isVideoEnabled = state.self.enableVideo;
    } else if (targetMember) {
      isVideoEnabled = targetMember.enableVideo;
    }

    if (!isVideoEnabled) {
      // console.log(`Target SubView is muted: ${targetConnectionId}`);
      console.error("Target SubView is muted");
      reject();
      return;
    }

    const videoElement = document.createElement("video");
    videoElement.playsInline = true;
    videoElement.controls = false;
    videoElement.muted = true;
    videoElement.srcObject = targetStream;

    // VideoElementに表示されてからCanvasに描画
    videoElement.onplaying = () => {
      const canvasElement = document.createElement("canvas");
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;
      const context = canvasElement.getContext("2d");
      if (context) {
        context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
      }

      // Blobに変換して親windowに通知
      canvasElement.toBlob(
        (blob) => {
          resolve(blob);
        },
        mimeType,
        quality
      );

      // 破棄
      canvasElement.remove();
      videoElement.srcObject = null;
      videoElement.remove();
    };

    videoElement.play();
  });
};

export const getImageSize = (image: string): Promise<number[]> => {
  return new Promise<number[]>((resolve, reject) => {
    const img = new Image();
    img.onerror = (e) => reject(e);
    img.onload = () => {
      resolve([img.naturalWidth, img.naturalHeight]);
      // console.log("img.naturalWidth = " + img.naturalWidth);
    };
    img.src = image;
  });
};

export const getLSReport = (
  targetClient: Client,
  reportType: "VideoAudioLog" | "ScreenShareLog" | "VideoAudioStats" | "ScreenShareStats",
  filterOption?: "head" | "tail"
): string => {
  if (reportType === "VideoAudioLog" || reportType === "ScreenShareLog") {
    switch (filterOption) {
      case "head":
        return targetClient.getHeadReport();
      case "tail":
        return targetClient.getTailReport();
      default:
        return targetClient.getHeadReport() + targetClient.getTailReport();
    }
  } else {
    return targetClient.getStatsReport();
  }
};
