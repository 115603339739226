import * as CONST from "./constants";
import { CognitoTokens, CognitoUser, ConnectionParams } from "./types";
import { isValidBitrateReservationMbps, isValidRoomType } from "./validators";

const request = async (
  method: "GET" | "POST",
  path: string,
  params?: Record<string, string | number | boolean>,
  signal?: AbortSignal
) => {
  try {
    const headers: { [header: string]: string } = { ...CONST.API_HEADERS };
    if (path === "/auth/ls") {
      const idToken = sessionStorage.getItem("id_token");
      if (idToken) {
        headers.Authorization = `Bearer ${idToken}`;
      } else {
        throw new Error("予期せぬエラーが発生しました。");
      }
    }
    const options: RequestInit = {
      method,
      headers,
      signal,
    };

    const queryParams = [];
    if (method === "GET" && params) {
      Object.keys(params).forEach((paramKey) => {
        queryParams.push(`${paramKey}=${params[paramKey]}`);
      });
    }
    if (method === "POST" && params) {
      options.body = JSON.stringify(params);
    }

    const pathWithQuery = path + (queryParams.length > 0 ? `?${queryParams.join("&")}` : "");
    const response = await fetch(
      `https://${BUILD_CONFIG.BACKEND_API_BASE}:${BUILD_CONFIG.BACKEND_API_PORT}${pathWithQuery}`,
      options
    );

    if (!response.ok) {
      // console.log(`response.ok--> https://${BUILD_CONFIG.BACKEND_API_BASE}:${BUILD_CONFIG.BACKEND_API_PORT}${pathWithQuery}`);
      // console.warn(`${method} error response => ${response.statusText}`);
      console.error("Error response");
      return Promise.reject(new Error(response.statusText));
    }

    return response.json();
  } catch (error) {
    if (error?.name === "AbortError") {
      // console.log(`${method} ${path} is aborted.`);
      console.error("Abort Error");
    }
    if (error?.message === "予期せぬエラーが発生しました。") {
      console.error("Authorization Error");
    }
    return Promise.reject(error);
  }
};

// Access Token, Connection ID の取得 API
export async function fetchConnectionParams(
  roomId: string,
  signal?: AbortSignal,
  bitrateReservation?: string,
  roomType?: string
): Promise<ConnectionParams> {
  const param: {
    room_id: string;
    bitrate_reservation_mbps?: string;
    room_type?: string;
  } = {
    room_id: roomId,
  };

  if (isValidBitrateReservationMbps(bitrateReservation)) {
    param.bitrate_reservation_mbps = bitrateReservation;
  }
  if (isValidRoomType(roomType)) {
    param.room_type = roomType;
  }

  return request("POST", "/auth/ls", param, signal);
}

// Cognito IDToken, RefreshToken の取得 API
export async function fetchCognitoTokens(username: string, password: string): Promise<CognitoTokens> {
  const param: {
    username: string;
    password: string;
  } = {
    username,
    password,
  };

  return request("POST", "/auth/user", param);
}

// Cognitoに登録されているユーザ一覧を取得するAPI
export const fetchCognitoUsers = (signal?: AbortSignal): Promise<{ users: CognitoUser[] }> => {
  return request("GET", "/users", {}, signal);
};
