import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import jaResource from "./ja";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ja: {
        translation: jaResource,
      },
    },
    fallbackLng: "ja",
    react: {
      // wait: true,
      useSuspense: true,
    },
    detection: {
      order: ["navigator"],
    },
  });

export default i18n;
