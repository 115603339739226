// #region --- Import ---
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";

import { useSelector } from "react-redux";
import { actions } from "../redux/slice";
import { RootState, useAppDispatch } from "../redux/store";

import { Alert, Box, Button, Dialog, DialogContent, Typography, styled } from "@mui/material";

import GeneralDialog from "@/scripts/components/generalDialog";
import i18n from "../../locales/i18n";
import * as canvasAction from "../utils/canvasActions";
import * as CONST from "../utils/constants";
import { MESSAGE_TYPE } from "../utils/constants";
import { AlertSeverity, ImageCursor, LocalConnectOption } from "../utils/types";
import { WebSocketContext } from "../utils/webSocket";

import ShowSnackbar from "../atoms/showSnackbar";

import Toolbar from "../organism/Toolbar";

import DeviceSettingDialog from "../components/deviceSettingDialog";
import HandWritingDialog from "../components/handWritingDialog";
import VideoViewerH from "../components/videoViewerH"; // Height優先viewer

// NOTE: 過去実装したファイル
// import { createContext } from "react";
// import { Container, Typography } from "@material-ui/core";
// import DialogActions from "@material-ui/core/DialogActions";

// import { GlassesTransform } from "../utils/types";
// import { AnnotationTools } from "../atoms/annotationTools";
// import VideoViewer from "./videoViewer";

// import Menu from "@material-ui/core/Menu";                     画像選択で使用していたコンポーネント
// import MenuItem from "@material-ui/core/MenuItem";             画像選択で使用していたコンポーネント
// import { CursorList } from "../../images/cursors/cursors";     画像選択で使用していたコンポーネント
// import { CursorList2 } from "../../images/cursors/med/cursors";画像選択で使用していたコンポーネント

// #endregion

// #region --- Variable ---
// 動画のアスペクト比用変数。
let videoAspectRatioH = 1; // 横。
let videoAspectRatioV = 1; // 縦。

let myRoomId = "";
let myFrontendId = "";

// Calib
let calibTargetDestination = null; // left|right
let isCalibRunning = false;

// NOTE:下記は canvasActions.ts へ移動済
// let drawType = "";
// let beforeDrawType = "";

// const defaultDrawType = "liveCircle"; //  [liveCircle,circle,stroke,arrow]
// let drawStart = false;
// let inputText = "";
// let currentCursor = null;
// let currentButtonColor = CONST.CANVAS_PEN_COLOR.DEFAULT;
// let currentColorRGBA = CONST.CANVAS_PEN_COLOR.DEFAULT;
// let glassesTransform0: GlassesTransform | null; // @drawStart
// let lastTimeStamp: number = 0;

// NOTE: 過去実装した機能 不要になったためコメント
// let currentCursor2 = null;                             // 画像選択２の機能
// let mouseX: number | null = null;                      // モーダルではない手描き機能
// let mouseY: number | null = null;                      // モーダルではない手描き機能
// let points = [];                                       // モーダルではない手描き機能
// let current_glassesTransform: GlassesTransform | null; // どの機能で使用されていたのか不明. getGlassesTransform()で値を入れているようだが、すでにコメント行だったので不要と判断

// #endregion

// #region --- Type definition ---
interface Props {
  connectionId: string | null;
  connectOption: LocalConnectOption | null;
  image: string | null;
  imageWidth: number | null;
  roomId: string | null;
  frontendId: string | null;
  onClose: () => void;
}

// #endregion

// #region --- Function ---
const changeButtonColor = () => {
  // NOTE:初期化処理.全て黒にしておく
  // document.getElementById('strokeButton').style.backgroundColor = '#000000';
  // document.getElementById('circleButton').style.backgroundColor = '#000000';
  if (canvasAction.handWritingImages.length > 0) {
    document.getElementById("liveHandWritingImageButton").style.backgroundColor = "#404D60";
    document.getElementById("liveHandWritingImageButton").style.pointerEvents = "auto";
  } else {
    document.getElementById("liveHandWritingImageButton").style.backgroundColor = "#404D6066";
    document.getElementById("liveHandWritingImageButton").style.pointerEvents = "none";
  }
  document.getElementById("liveCircleButton").style.backgroundColor = "#404D60";
  document.getElementById("imageButton").style.backgroundColor = "#404D60";
  document.getElementById("arrowButton").style.backgroundColor = "#404D60";
  // document.getElementById("liveArrowButton").style.backgroundColor = "#000000";
  // document.getElementById("image2Button").style.backgroundColor = "#000000";
  // NOTE:テキストフォームの動的CSS変更はStyleOutlinedInputコンポーネントで制御
  // → withStylesでのオーバーライドしかフォーム外枠のCSSを適用できないため
  if (
    canvasAction.currentDrawType === "textMessage" ||
    canvasAction.currentDrawType === "handWriting" ||
    canvasAction.currentDrawType === "calibCursor"
  )
    return;
  // 押されたボタンのみ背景をcolorに
  else if (canvasAction.currentDrawType === "clickToStart") {
    document.getElementById("liveCircleButton").style.backgroundColor = "#F7C925";
  } else {
    document.getElementById(`${canvasAction.currentDrawType}Button`).style.backgroundColor = "#F7C925";
  }
};

const drawBackground = (sendMessage: (Message) => void, image: string) => {
  const imageElement = new Image();
  imageElement.onload = () => {
    const { naturalWidth, naturalHeight } = imageElement;
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    canvas.width = naturalWidth;
    canvas.height = naturalHeight;
    const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
    bufferCanvas.width = naturalWidth;
    bufferCanvas.height = naturalHeight;
    //drawBoundary_L(sendMessage,null,true, circleSize, penColor);
    //drawBoundary_R(sendMessage,null,true, circleSize, penColor);
  };
  imageElement.src = image;
};

// TODO: drawCanvasコンポーネントを別途作成し、DrawStart(),OnMove(),DrawEnd()を移動する。
const DrawStart = (
  sendMessage: (Message) => void,
  //e: React.MouseEvent<HTMLCanvasElement>,
  e: React.PointerEvent<HTMLCanvasElement>,
  width: number,
  color: string,
  currentImageCursor: ImageCursor
) => {
  // drawStart = true;
  // canvasAction.setDrawStart(true);
  // android側へ送信するメッセージでtransformが更新されていなかったのでここでセット
  // NOTE: glassesTransform  = getGlassesTransform();はもともとコメントで、current_glassesTransform はNULL で定義されてから値を更新していないので、不要と判断
  // NOTE: glassesTransform0 = current_glassesTransform;の、current_glassesTransform は NULL で定義されてから値を更新していない.それ以外で、glassesTransform0は更新されていないので、glassesTransform0 を canvasActions に移動だけして値は更新しない。
  // glassesTransform0 = {
  //   transform: {
  //     position: { x: 0.0, y: 0.0, z: 0.0 },
  //     rotation: CONST.GLASS_TRANSFORM_SHARE.rotation,
  //   },
  // };
  // glassesTransform0 = current_glassesTransform;
  // canvasAction.setGlassesTransform0(glassesTransform0);
  //glassesTransform = getGlassesTransform();
  const { x, y } = calcMouseLocOnCanvas(e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.nativeEvent.clientY);

  // if (canvasAction.currentDrawType == "stroke") {
  //   clearCanvas(sendMessage, e);
  //   DrawStroke(x, y, e, width, color);
  //   points = [{ x: x / canvas.width, y: y / canvas.height }];
  // } else if (canvasAction.currentDrawType == "arrow") {
  if (canvasAction.currentDrawType === "arrow") {
    canvasAction.DrawArrow(sendMessage, x, y, e, width, color, myRoomId, myFrontendId);
  } else if (canvasAction.currentDrawType === "liveArrow") {
    // TODO:3DoFトラッキングのwidth,colorにおいて、他アノテーションのwidth,colorと共用できるなら処理を統一する → コンポーネント化した後が良さそう
    canvasAction.setCurrentTransformShareWidth(width);
    canvasAction.setCurrentTransformShareColor(color);
    canvasAction.SetCurrentPose(sendMessage, x, y, e, myRoomId, myFrontendId);

    canvasAction.setCurrentTransformShareDrawStart(true);
    canvasAction.DrawLiveArrow(sendMessage, myRoomId, myFrontendId);
    // } else if (canvasAction.currentDrawType == "circle") {
    //   clearCanvas(sendMessage, e);
    //   DrawCircle(sendMessage, x, y, e, width, color);
  } else if (canvasAction.currentDrawType === "liveCircle") {
    canvasAction.DrawLiveCircle(sendMessage, x, y, width, color, myRoomId, myFrontendId);
  } else if (canvasAction.currentDrawType === "image") {
    canvasAction.DrawImage(sendMessage, x, y, e, width, color, myRoomId, myFrontendId, currentImageCursor);
  } else if (canvasAction.currentDrawType === "liveHandWritingImage") {
    if (!canvasAction.isClickForPlacementHandWriting) {
      canvasAction.DrawLiveHandWriting(sendMessage, x, y, width, color, myRoomId, myFrontendId);
    }
  } else if (canvasAction.currentDrawType === "calibCursor") {
    canvasAction.DrawCalibCursor(sendMessage, x, y, e, width, color, myRoomId, myFrontendId);
  }
};

const OnMove = (
  sendMessage: (Message) => void,
  // e: React.MouseEvent<HTMLCanvasElement>,
  e: React.PointerEvent<HTMLCanvasElement>,
  width: number,
  color: string,
  currentImageCursor: ImageCursor
) => {
  // if (canvasAction.currentDrawType === "stroke" && e.buttons !== 1) {
  //   return;
  // }
  // if (e.buttons !== 1) { return; } // L-button押している間
  if (canvasAction.currentDrawType === "arrow") {
    return;
  }
  //if (canvasAction.currentDrawType !== "stroke") { return; }
  const { x, y } = calcMouseLocOnCanvas(e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.nativeEvent.clientY);
  // if (canvasAction.currentDrawType == "stroke") {
  //   DrawStroke(x, y, e, width, color);
  //   points.push({ x: x / canvas.width, y: y / canvas.height });
  // }
  if (canvasAction.currentDrawType === "clickToStart") {
    canvasAction.drawTextCursor(sendMessage, x, y, e, color);
  }
  if (canvasAction.currentDrawType === "liveHandWritingImage") {
    if (!canvasAction.isClickForPlacementHandWriting) {
      canvasAction.DrawLiveHandWriting(sendMessage, x, y, width, color, myRoomId, myFrontendId);
    }
  }
  if (canvasAction.currentDrawType === "liveCircle") {
    canvasAction.DrawLiveCircle(sendMessage, x, y, width, color, myRoomId, myFrontendId);
  }
  if (canvasAction.currentDrawType === "calibCursor") {
    canvasAction.DrawCalibCursor(sendMessage, x, y, e, width, color, myRoomId, myFrontendId);
  }
  if (canvasAction.currentDrawType === "image") {
    canvasAction.DrawImage(sendMessage, x, y, e, width, color, myRoomId, myFrontendId, currentImageCursor);
  }
  // if (canvasAction.currentDrawType == "image2") {
  //   DrawImage2(sendMessage, x, y, e, width);
  // }
};

const DrawEnd = (
  // called on pointer up
  sendMessage: (Message) => void,
  e: React.PointerEvent<HTMLCanvasElement>
) => {
  let calOffsetX = 0;
  let calOffsetY = 0;
  // console.log("mouse button => " + e.nativeEvent.button); // L:0|M:1|R:2
  if (e.nativeEvent.button === 0) {
    // M button
    // update calib_offset_{x,y}
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const isDrawEnd = true;
    const { x, y } = calcMouseLocOnCanvas(e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.nativeEvent.clientY, isDrawEnd);
    if (calibTargetDestination === "left") {
      //console.log('DCL=',CONST.GLASS_CALIBRATION_RESULT.D_center_L_X);
      calOffsetX = canvasAction.glassCalibrationResult.D_center_L_X - x / canvas.width;
      calOffsetY = canvasAction.glassCalibrationResult.D_center_L_Y - y / canvas.height;

      setCalibrationOffset2d(sendMessage, calibTargetDestination, calOffsetX, calOffsetY);
      isCalibRunning = true;
    }
    if (calibTargetDestination === "right") {
      //console.log('DCR=',CONST.GLASS_CALIBRATION_RESULT.D_center_L_X);
      calOffsetX = canvasAction.glassCalibrationResult.D_center_R_X - x / canvas.width;
      calOffsetY = canvasAction.glassCalibrationResult.D_center_R_Y - y / canvas.height;

      setCalibrationOffset2d(sendMessage, calibTargetDestination, calOffsetX, calOffsetY);
      isCalibRunning = true;
    }
    //console.log(calib_offset_x,calib_offset_y);
    setCalibrationTargetIcon(sendMessage, calibTargetDestination, "off");
    calibTargetDestination = null;
  }
  // if (canvasAction.currentDrawType !== "stroke") {
  //   return;
  // }
  // mouseX = null;
  // mouseY = null;
  // sendMessage({
  //   messageType: MESSAGE_TYPE.STROKE,
  //   roomId: myRoomId,
  //   points: points,
  //   width: width,
  //   color: color,
  //   timestamp: new Date().getTime(),
  //   transform: glassesTransform0?.transform,
  //   objectId: new Date().getTime(),
  //   frontendId: myFrontendId,
  // });
};

// 動画領域の左端の座標を返す。
// ratioW: 動画のアスペクト比の幅方向の値。
// ratioH: 動画のアスペクト比の高さ方向の値。
const calcVideoLeft = (ratioW: number, ratioH: number): number => {
  const canvasElem = document.getElementById("canvas");
  if (!canvasElem) return 0;
  const viewerHeight = Number(sessionStorage.getItem("videoViewerHeight"));
  const videoUnitHeight = viewerHeight / ratioH;
  const videoWidth = videoUnitHeight * ratioW;
  const canvasWidth = canvasElem.clientWidth;
  return (canvasWidth - videoWidth) / 2;
};

// マウスの位置に対するcanvas上の位置を返す。
// offsetX: e.nativeEvent.offsetX。
// offsetY: e.nativeEvent.offsetY。
// clientY: e.nativeEvent.clientY。
const calcMouseLocOnCanvas = (
  offsetX: number,
  offsetY: number,
  clientY: number,
  isDrawEnd = false
): {
  x: number;
  y: number;
} => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const videoWidth = canvas.clientWidth - calcVideoLeft(videoAspectRatioH, videoAspectRatioV) * 2;
  const sideSpaceWidth = (canvas.clientWidth - videoWidth) / 2;

  let x = 0;
  let y = 0;

  if (sideSpaceWidth >= 0) {
    // 表示領域が横長の場合の処理。
    const uncroppedX = (offsetX - sideSpaceWidth) * (canvas.width / (canvas.clientWidth - sideSpaceWidth * 2));
    x = Math.min(Math.max(uncroppedX, 0), canvas.width);
    y = offsetY * (canvas.height / canvas.clientHeight);
  } else {
    // 表示領域が縦長の場合の処理。
    const innerWidth = window.innerWidth; // ウインドウの表示領域内の幅
    const innerHeight = window.innerHeight; // ウインドウの表示領域内の高さ。
    const normalizedX = offsetX / innerWidth; // カーソルの横位置の値の範囲を0（左端）～1（右端）にしたx座標。
    const paddingTop = 20; // 上部のパディング（20pxの固定値）。
    const toolMenuHeight = document.getElementById("toolMenu").clientHeight; // 下部のツール領域の高さ。
    const videoAreaTop = paddingTop; // 動画領域上端。
    const videoAreaHeight = innerHeight - paddingTop - toolMenuHeight; // 動画領域の高さ（ウインドウの表示領域の高さから上部のパディングと下部のツール領域の高さを除いた高さ）。
    const videoHeight = (innerWidth / videoAspectRatioH) * videoAspectRatioV; // 動画の高さ。
    const offsetYFromVideoAreaTop = clientY - videoAreaTop; // カーソル位置の縦オフセット（動画領域上端からの距離）。
    const videoTopY = (videoAreaHeight - videoHeight) / 2; // 動画上端のy座標。
    x = innerWidth * normalizedX * (canvas.width / canvas.clientWidth);
    y = (offsetYFromVideoAreaTop - videoTopY) * (canvas.width / canvas.clientWidth);
  }

  // NOTE:下記処理を共通化。また、DrawEndのcalcMouseLocOnCanvas()だけ、下記処理がなかった。
  // DrawEndの後続処処理はキャリブレーションの処理しかないので、キャリブレーションの処理に影響がでる？
  if (!isDrawEnd) {
    if (y < 0) {
      y = 0;
    } else if (y > canvas.height) {
      y = canvas.height;
    }
  }

  return { x: x, y: y };
};

// #endregion

// #region --- Style and Styling Overrides ---
const CustomizedDialog = styled(Dialog)(() => ({
  "& .custom-paper": {
    display: "flex",
    objectFit: "contain",
    margin: 0,
    padding: 0,
    "&+.MuiPaper-root": {
      padding: 0,
      margin: 0,
    },
  },
}));

const CustomizedDialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  margin: 0,
  padding: 0,
  height: "100%",
  width: "100%",
  justifyContent: "center",
  background: "#202124",
  "&+.MuiDialogContent-root": {
    //paddingTop: '0px !important',
    padding: 0,
    margin: 0,
  },
}));

const CustomizedVideoContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  objectFit: "contain",
  background: "#202124",
  alignItems: "center",
  /*
  alignItems: "center",
  "-webkit-overflow-scrolling": "touch", // mobile向け
  overflowY: "scroll", // mobile向け
  "&::-webkit-scrollbar": {
    // mobile向け
    display: "none",
  },
  */
}));

const CustomizedToolMenu = styled("div")(() => ({
  display: "flex",
  height: "255px",
  padding: "1.9em 1.5em 2.7em 1.5em",
  justifyContent: "center",
  background: "white",
  // overflow: "hidden",
  overflowX: "scroll",
  fontSize: "16px",

  ["@media screen and (max-width: 1700px)"]: {
    justifyContent: "flex-start",
  },
}));

const CustomizedCanvasWrapForBoundaryAndCircle = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  width: "auto",
  height: "100%",
}));

const CustomizedCanvasWrap = styled("div")(() => ({
  position: "relative",
  padding: 0,
  boxSizing: "content-box",
  overflow: "hidden",
  background: "#202124",
  //objectFit: "contain",
  //width: "100%",
  //height: "100%",
}));

const CustomizedCanvas = styled("canvas")(() => ({
  position: "absolute",
  top: 0,
  // left: 0,
  display: "flex",
  width: "100%",
  height: "100%",
  objectFit: "contain",
  touchAction: "none",
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  //maxHeight: "100%",
  //maxWidth: "100%",
}));

const CustomizedTypography = styled(Typography)(() => ({
  position: "fixed",
  top: "calc(100% - 37px)",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

// #endregion

// #region --- CanvasDialog components ---
const CanvasDialog: React.FC<Props> = (props) => {
  //const viewerWidth = Number(localStorage.getItem("videoViewerWidth"));
  //console.log(`wxh: viewer=${viewerWidth},${viewerHeight}`)
  const dispatch = useAppDispatch();
  const [circleSize, setCircleSize] = useState(CONST.CANVAS_CIRCLE_SIZE.DEFAULT); // constants.ts
  const [penColor, setPenColor] = useState(CONST.CANVAS_PEN_COLOR.DEFAULT);
  const [openGeneralDialog, setOpenGeneralDialog] = useState(false);
  const [viewerHeight, setViewerHeight] = useState(Number(sessionStorage.getItem("videoViewerHeight")));
  // const { sendMessage, getGlassesTransform } = useContext(WebSocketContext);
  const { sendMessage, glassType } = useContext(WebSocketContext);
  const [showDeviceSetting, setShowDeviceSetting] = useState(false);
  const [showHandWritingArea, setShowHandWritingArea] = useState(false);

  const { canvas, immutable } = useSelector((state: RootState) => state.main);
  const { beforeDrawType, currentImageCursor, inputText } = canvas;
  const { otherMembers, videoStreams } = immutable;
  const member = otherMembers.get(props.connectionId);
  const stream = videoStreams.get(props.connectionId);
  const participants = otherMembers.size + 1;
  const alphaValues = ["FF", "4D"];
  const baseColors = ["#B80000", "#DB3E00", "#FCCB00", "#008B02", "#006B76", "#1273DE", "#004DCF", "#5300EB"];
  const colorPalette = alphaValues.reduce((acc, alphaValue) => {
    return acc.concat(baseColors.map((baseColor) => `${baseColor}${alphaValue}`));
  }, []);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertSeverity>();

  const [showCalibAlert, setShowCalibAlert] = useState(false);
  //current_glassesTransform = getGlassesTransform();

  myRoomId = props.roomId;
  myFrontendId = props.frontendId;

  const videoSizeReceived = (width: number, height: number) => {
    videoAspectRatioH = width;
    videoAspectRatioV = height;
  };

  // const handleExit: () => void = () => {
  //   dispatch(actions.disconnect());
  // };

  const handleWindowResize = useCallback(() => {
    setViewerHeight(Number(sessionStorage.getItem("videoViewerHeight")));
  }, []);

  useEffect(() => {
    if (glassType === CONST.GLASS_TYPE.GREEN) {
      // NOTE: Daisy、Elsa共に単色ディスプレイなので、GREENの場合はデフォルト選択色を緑色に変更
      setPenColor("#008b02ff");
    }

    if (!member && !stream) {
      canvasAction.newClearCanvas();
      canvasAction.canvasHistories.splice(0, canvasAction.canvasHistories.length);
    } else if (member && stream) {
      if (canvasAction.currentDrawType === "liveCircle") setActiveAnnotation(sendMessage, "circle");
      else if (canvasAction.currentDrawType === "liveHandWritingImage") setActiveAnnotation(sendMessage, "trackingOverlayImage");
      else if (canvasAction.currentDrawType === "arrow" || canvasAction.currentDrawType === "image")
        setActiveAnnotation(sendMessage, canvasAction.currentDrawType);
    }
  }, [glassType, member, stream]);

  useLayoutEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  });

  const handlePointerOut = (event: React.PointerEvent<HTMLCanvasElement>) => {
    const isMouse = event.pointerType === "mouse";
    if (!isMouse) return;

    setOutOfCanvas(sendMessage, true);
    canvasAction.clearDrawStroke(sendMessage, myRoomId, myFrontendId);
    // DrawEnd(sendMessage, event);
  };

  const handleShowSnackbar = useCallback((severity: AlertSeverity, Message: string) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(Message);
    setShowSnackbar(true);
  }, []);
  const handleCloseSnackbar = useCallback(() => {
    setShowSnackbar(false);
  }, []);

  const handleClickDeviceSettingIcon = useCallback(() => {
    setShowDeviceSetting(true);
  }, []);

  const handleCloseCalib = useCallback((direction: string) => {
    setShowDeviceSetting(false);
    setShowCalibAlert(true);
    canvasAction.setCurrentDrawType("calibCursor");
    if (canvasAction.isClickForPlacementHandWriting) canvasAction.setIsClickForPlacementHandWriting(false);
    changeButtonColor();

    calibTargetDestination = direction;
    startCalib2d(sendMessage, direction);
  }, []);

  const startCalib2d = (sendMessage: (Message) => void, destination: string) => {
    const shouldDeleteAllAnnotations = true;
    canvasAction.newClearCanvas();
    canvasAction.clearScreenAll(sendMessage, myRoomId, myFrontendId, shouldDeleteAllAnnotations);
    setOutOfCanvas(sendMessage, true);

    setCalibrationTargetIcon(sendMessage, destination, "on");
  };

  const completeCalib = () => {
    handleShowSnackbar("success", "キャリブレーションを実行しました。");
    setShowCalibAlert(false);
    canvasAction.newClearCanvas();
    canvasAction.clearScreenAll(sendMessage, myRoomId, myFrontendId);
    canvasAction.setCurrentDrawType("liveCircle");
    changeButtonColor();
    isCalibRunning = false;
  };

  const cancelCalib = () => {
    handleShowSnackbar("warning", "キャリブレーションを中止しました。");
    setShowCalibAlert(false);
    canvasAction.newClearCanvas();
    canvasAction.clearScreenAll(sendMessage, myRoomId, myFrontendId);
    canvasAction.setCurrentDrawType("liveCircle");
    changeButtonColor();

    setCalibrationTargetIcon(sendMessage, calibTargetDestination, "off");
    // calibTargetDestination
    calibTargetDestination = null;
  };

  const handleApplyCameraControl = useCallback(
    (isBacklightCompensation: boolean, brightness: number, brightnessCalculationMode: number) => {
      setCameraControl(sendMessage, isBacklightCompensation, brightness, brightnessCalculationMode);
    },
    [sendMessage]
  );

  // #region --- Handle Draw Function ---
  const handleOpenHandWriting = useCallback(() => {
    dispatch(actions.setBeforeDrawType(canvasAction.currentDrawType));
    canvasAction.setCurrentDrawType("handWriting");
    setShowHandWritingArea(true);
  }, []);

  const handleCloseHandWriting = useCallback(() => {
    canvasAction.setCurrentDrawType(beforeDrawType);
    setShowHandWritingArea(false);
  }, [beforeDrawType]);

  const handleSendAndCloseDialog = useCallback(() => {
    if (beforeDrawType === "clickToStart" || beforeDrawType === "liveHandWritingImage") {
      canvasAction.setCurrentDrawType("liveCircle");
      setActiveAnnotation(sendMessage, "circle");
    } else canvasAction.setCurrentDrawType(beforeDrawType);

    changeButtonColor();
    setShowHandWritingArea(false);
  }, [sendMessage, beforeDrawType]);

  const handleClickClearHandWriting = useCallback(() => {
    canvasAction.clearHandWriting(sendMessage, myRoomId, myFrontendId);
    canvasAction.handWritingImages.length = 0;
    if (canvasAction.currentDrawType === "liveHandWritingImage") {
      canvasAction.setCurrentDrawType("liveCircle");
      setActiveAnnotation(sendMessage, "circle");
    }
    if (canvasAction.isClickForPlacementHandWriting) canvasAction.setIsClickForPlacementHandWriting(false);
    changeButtonColor();
  }, []);

  const handleClickHandWritingImage = useCallback(() => {
    canvasAction.setCurrentDrawType("liveHandWritingImage");
    canvasAction.createLiveHandWritingImage(circleSize, penColor);
    changeButtonColor();

    setActiveAnnotation(sendMessage, "trackingOverlayImage");
  }, [circleSize, penColor]);

  const handleClickStroke = useCallback(() => {
    canvasAction.setCurrentDrawType("liveCircle");
    if (canvasAction.isClickForPlacementHandWriting) canvasAction.setIsClickForPlacementHandWriting(false);
    changeButtonColor();

    setActiveAnnotation(sendMessage, "circle");
  }, []);

  const handleClickImageStroke = useCallback(
    (cursor: ImageCursor) => {
      if (currentImageCursor !== cursor) {
        dispatch(actions.setCurrentCursor(cursor));
      }
      if (canvasAction.isClickForPlacementHandWriting) canvasAction.setIsClickForPlacementHandWriting(false);

      canvasAction.setCurrentDrawType("image");
      changeButtonColor();

      setActiveAnnotation(sendMessage, "image");
    },
    [currentImageCursor, sendMessage]
  );

  const handleClickArrow = useCallback(() => {
    canvasAction.setCurrentDrawType("arrow");
    if (canvasAction.isClickForPlacementHandWriting) canvasAction.setIsClickForPlacementHandWriting(false);
    changeButtonColor();

    setActiveAnnotation(sendMessage, "arrow");
  }, []);

  // const handleClickLiveArrow = useCallback(() => {
  //   canvasAction.setCurrentDrawType("liveArrow");
  //   canvasAction.setCurrentTransformShareDrawStart(false);
  //   changeButtonColor();
  // }, [canvasAction.setCurrentDrawType, canvasAction.setCurrentTransformShareDrawStart, changeButtonColor]);

  const handleClickClear = useCallback(() => {
    canvasAction.clearDrawClick(sendMessage, myRoomId, myFrontendId);
  }, [canvasAction.clearDrawClick, sendMessage, myRoomId, myFrontendId]);

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      canvasAction.DrawInputText(sendMessage, e, circleSize, penColor, inputText, myRoomId, myFrontendId);
    },
    [canvasAction.DrawInputText, sendMessage, circleSize, penColor, myRoomId, myFrontendId]
  );

  const handleChangeTextFocus = useCallback(() => {
    canvasAction.setCurrentDrawType("textMessage");
    changeButtonColor();
    if (canvasAction.isClickForPlacementHandWriting) canvasAction.setIsClickForPlacementHandWriting(false);
  }, []);

  const handleClickCrossIconInText = useCallback(() => {
    canvasAction.clearInputText(sendMessage, myRoomId, myFrontendId);
  }, [sendMessage, myRoomId, myFrontendId]);

  const handlePenColorChange = useCallback(
    (value: string) => {
      //TODO: Reduxに移行後、Toolbarファイルに移動
      setPenColor(value);

      //TODO: Reduxに移行後、Toolbarファイルに移動
      //NOTE: グラス側、手描き画像追従機能実現のため、手描き画像を再送信
      if (canvasAction.handWritingImages.length > 0) {
        const img = canvasAction.createLiveHandWritingImage(circleSize, value);
        setClearOverlayImage(sendMessage);
        setOverlayImage(sendMessage, img);
      }
    },
    [circleSize, sendMessage]
  );

  const handleChangeCircleSize = useCallback(
    (value: number) => {
      //TODO: Reduxに移行後、Toolbarファイルに移動
      setCircleSize(value);

      //TODO: Reduxに移行後、Toolbarファイルに移動
      //NOTE: グラス側、手描き画像追従機能実現のため、手描き画像を再送信
      if (canvasAction.handWritingImages.length > 0) {
        const img = canvasAction.createLiveHandWritingImage(circleSize, penColor);
        setClearOverlayImage(sendMessage);
        setOverlayImage(sendMessage, img);
      }
    },
    [circleSize, penColor, sendMessage]
  );

  // #endregion

  return (
    <div>
      <CustomizedDialog
        fullScreen
        open={props.image !== null && props.imageWidth !== null}
        TransitionProps={{
          onEntered: () => {
            const shouldDeleteAllAnnotations = true;
            canvasAction.newClearCanvas();
            canvasAction.clearScreenAll(sendMessage, myRoomId, myFrontendId, shouldDeleteAllAnnotations);
            // clearCanvas(sendMessage, null, shouldDeleteAllAnnotations);
            // initTracking(sendMessage);
            drawBackground(sendMessage, props.image);
            changeButtonColor();
            setCircleSize(circleSize);
          },
          onExited: () => canvasAction.newClearCanvas(),
        }}
        PaperProps={{
          className: "custom-paper",
        }}
      >
        <CustomizedDialogContent id="d_content">
          <CustomizedVideoContainer id="videoContainer">
            <CustomizedCanvasWrap>
              {member && stream && (
                <div
                  id="videoViewer"
                  //className={classes.fullscreenMainVideo}
                >
                  <VideoViewerH
                    connectionId={props.connectionId}
                    connectOption={props.connectOption}
                    videoSizeReceived={videoSizeReceived}
                    // width={props.imageWidth}
                    //width={viewerWidth}
                    height={viewerHeight}
                    member={member}
                    participants={participants}
                    isSelf={false}
                    stream={stream}
                  />
                </div>
              )}
              {/* canvas: boundary+circle */}
              <CustomizedCanvasWrapForBoundaryAndCircle>
                <CustomizedCanvas
                  id="canvas"
                  //width={viewerWidth}
                  //height={viewerHeight}
                  onPointerDown={(event) => DrawStart(sendMessage, event, circleSize, penColor, currentImageCursor)}
                  onPointerMove={(event) => OnMove(sendMessage, event, circleSize, penColor, currentImageCursor)}
                  //onPointerUp={() => DrawEnd(sendMessage, circleSize, penColor)}
                  //onPointerOut={() => DrawEnd(sendMessage, circleSize, penColor)}
                  onPointerUp={(event) => DrawEnd(sendMessage, event)}
                  onPointerOut={handlePointerOut}
                  onPointerEnter={() => {
                    setOutOfCanvas(sendMessage, false);
                  }}
                  onClick={(e) => {
                    const { x, y } = calcMouseLocOnCanvas(e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.nativeEvent.clientY);
                    if (canvasAction.currentDrawType === "clickToStart") {
                      canvasAction.setCurrentDrawType("liveCircle");
                      setActiveAnnotation(sendMessage, "circle");
                      canvasAction.DrawLiveCircle(sendMessage, x, y, circleSize, penColor, myRoomId, myFrontendId);
                    }
                    if (canvasAction.currentDrawType === "liveHandWritingImage") {
                      canvasAction.handlePlacementHandWriting(sendMessage, x, y, circleSize, penColor, myRoomId, myFrontendId);
                    }
                    if (isCalibRunning) {
                      completeCalib();
                    }
                  }}
                />
                {/* NOTE: アノテーション同時表示用。バッファーキャンバスを元に、キャンバスに描写した特定の描画ターゲットの状態を復元し、メインのキャンバスに描写 */}
                {/* NOTE: 別のキャンバス（buffer_canvas）で復元しないと、同じ位置に描写したアノテーションを復元できない */}
                <canvas id="buffer_canvas" style={{ display: "none" }} />
                {/* NOTE: 『グラス側の手描き画像追従機能』実現のため、手描き画像を再作成するキャンバス */}
                {/* NOTE: IMGデータを作成するために、HTMLに一度描写しないと、正常に作成されないかもしれないので追加 */}
                <canvas
                  id="buffer_live_handWriting_image"
                  style={{ display: "none" }}
                  width={CONST.HANDWRITING_CANVAS.WIDTH}
                  height={CONST.HANDWRITING_CANVAS.HEIGHT}
                />
              </CustomizedCanvasWrapForBoundaryAndCircle>
            </CustomizedCanvasWrap>
          </CustomizedVideoContainer>
        </CustomizedDialogContent>
        <CustomizedToolMenu id="toolMenu">
          {/* OPTIMIZE:Android側とのWebsocket通信時(初回)、sendMessage()のmessage.timestampが更新される。*/}
          {/* useCallbackの第二引数にsendMessage()を指定しているので、Toolbarの再レンダリング が発生している。*/}
          <Toolbar
            onClickStroke={handleClickStroke}
            onClickImageStroke={handleClickImageStroke}
            currentImageCursor={currentImageCursor}
            onClickArrow={handleClickArrow}
            // onClickLiveArrow={handleClickLiveArrow}
            onClear={handleClickClear}
            onClickHandWriting={handleOpenHandWriting}
            onClickHandWritingImage={handleClickHandWritingImage}
            onClearHandWriting={handleClickClearHandWriting}
            onTextChange={handleChangeText}
            onTextFocus={handleChangeTextFocus}
            onClickCrossIconInText={handleClickCrossIconInText}
            handlePenColorChange={handlePenColorChange}
            circleSize={circleSize}
            onChangeCircleSize={handleChangeCircleSize}
            onClickDeviceSettingIcon={handleClickDeviceSettingIcon}
            glassType={glassType}
            penColor={penColor}
          />
          <HandWritingDialog
            open={showHandWritingArea}
            onClose={handleCloseHandWriting}
            onSendClose={handleSendAndCloseDialog}
            onShowSnackbar={handleShowSnackbar}
            colorPalette={colorPalette}
            myRoomId={myRoomId}
            penColor={penColor}
          />
          <ShowSnackbar
            open={showSnackbar}
            handleClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            message={snackbarMessage}
          />
          {showCalibAlert && (
            <Box
              width="100%"
              height="300px"
              component="div"
              position="fixed"
              top="calc(100% - 225px)"
              left={0}
              bottom={0}
              right={0}
              style={{ backgroundColor: "#202124" }}
            >
              <Alert
                style={{
                  position: "fixed",
                  top: "calc(100% - 105px)",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "350px",
                  backgroundColor: "white",
                  color: "black",
                }}
                icon={false}
                severity="info"
                action={
                  <Button style={{ color: "#6192E5" }} color="inherit" size="small" onClick={() => cancelCalib()}>
                    {i18n.t("deviceSettingsDialog.abortCalibration")}
                  </Button>
                }
              >
                <Typography>{i18n.t("deviceSettingsDialog.calibrationProgress")}</Typography>
              </Alert>
            </Box>
          )}
        </CustomizedToolMenu>
        {/* // HACK:スクロールバーの上に要素が配置できなかったので、「position:fixed」でスクロールバーの上に配置 */}
        <CustomizedTypography variant="h6">{i18n.t("title.app")}</CustomizedTypography>
        <DeviceSettingDialog
          open={showDeviceSetting}
          onClose={() => setShowDeviceSetting(false)}
          onCloseCalib={handleCloseCalib}
          handleApplyCameraControl={handleApplyCameraControl}
        />
      </CustomizedDialog>

      <GeneralDialog
        open={openGeneralDialog}
        message={i18n.t("message.guide.sendcompleted")}
        onClose={() => setOpenGeneralDialog(false)}
      />
    </div>
  );
};
export default CanvasDialog;
// #endregion

// #region --- SendMessage ---
// NOTE: 後ほど別ファイルへ移動する
const setCameraControl = (
  sendMessage: (Message) => void,
  isBacklightCompensation: boolean,
  brightness: number,
  brightnessCalculationMode: number
) => {
  // NOTE: brightness の値が小さいときは、少しの値の変化でも明るさへの影響が大きく分かりやすいが、
  // 1000(1/100秒)あたりになると10程度の差では明るさの変化が分からなくなってくる。
  // 上記理由でスライダーの右の方ではうまく明るさをコントロールできないため、露光時間の計算処理を追加。
  const exposureTime = Math.trunc((Math.pow(2, brightness / 3) * 333) / 256);
  // 明るさ確認用
  console.log("brightness:", exposureTime);
  sendMessage({
    messageType: MESSAGE_TYPE.CAMERA_CONTROL,
    roomId: myRoomId,
    control: {
      BACKLIGHT_COMPENSATION: isBacklightCompensation,
      EXPOSURE_ABSOLUTE: exposureTime,
      EXPOSURE_AUTO: brightnessCalculationMode,
    },
    timestamp: new Date().getTime(),
    frontendId: myFrontendId,
  });
};

const setCalibrationOffset2d = (sendMessage: (Message) => void, destination: string, calOffsetX: number, calOffsetY: number) => {
  sendMessage({
    messageType: MESSAGE_TYPE.CALIBRATION_OFFSET_2D,
    roomId: myRoomId,
    destination,
    offset: { x: calOffsetX, y: calOffsetY },
    timestamp: new Date().getTime(),
    frontendId: myFrontendId,
  });
};

const setCalibrationTargetIcon = (sendMessage: (Message) => void, destination: string, display: string) => {
  sendMessage({
    messageType: MESSAGE_TYPE.CALIBRATION_TARGET_ICON,
    roomId: myRoomId,
    destination,
    display,
    timestamp: new Date().getTime(),
    frontendId: myFrontendId,
  });
};

const setOutOfCanvas = (sendMessage: (Message) => void, isOutOfCanvas: boolean) => {
  sendMessage({
    messageType: MESSAGE_TYPE.OUT_OF_CANVAS,
    isOutOfCanvas,
    roomId: myRoomId,
  });
};

const setOverlayImage = (sendMessage: (Message) => void, img: HTMLImageElement) => {
  sendMessage({
    messageType: CONST.MESSAGE_TYPE.OVERLAYIMAGE,
    image: img.src,
    timestamp: new Date().getTime(),
    roomId: myRoomId,
  });
};

const setClearOverlayImage = (sendMessage: (Message) => void) => {
  sendMessage({
    messageType: CONST.MESSAGE_TYPE.CLEAROVERLAYIMAGE,
    timestamp: new Date().getTime(),
    roomId: myRoomId,
  });
};

const setActiveAnnotation = (sendMessage: (Message) => void, activeAnnotation: string) => {
  // WARNING: activeAnnotationにはアノテーションのメッセージタイプ（"messageType": "**）の名前を渡す
  // WARNING: フロント画面のキャンバスを制御しているアノテーション名とアノテーションのメッセージタイプ名が異なっているので注意
  sendMessage({
    messageType: CONST.MESSAGE_TYPE.ACTIVE_ANNOTATION,
    activeAnnotationType: activeAnnotation,
    timestamp: new Date().getTime(),
    roomId: myRoomId,
  });
};
// #endregion

// #region --- Code no longer needed after refactoring ---
// 念のため残しておきます。

// NOTE: 不要になった型定義 annotationTools.tsx でのみ使用されていたが、annotationTools.tsxはどこにもインポートされてないので不要と判断
// interface pcContextInterface {
//   penColor: string;
//   setPenColor: (col: string) => void;
// }
// interface drawTypeContextInterface {
//   drawType: string;
//   setDrawType: (dType: string) => void;
// }

// export const PenColorContext = React.createContext({} as pcContextInterface);
// export const DrawTypeContext = React.createContext({} as drawTypeContextInterface);

// NOTE: 移動したため、不要になった style
// strokeButton: {
//   margin: "0.4em 0 0 1em",
//   height: "2.7em",
//   "&:hover": {
//     opacity: "0.6",
//   },
// },
// clickButton: {
//   margin: "0.4em 0 0 1.5em",
//   height: "2.7em",
//   "&:hover": {
//     opacity: "0.6",
//   },
// },
// clearButton: {
//   height: "2.7em",
//   background: "#fe6464",
//   "&:hover": {
//     background: "#fe6464",
//     opacity: "0.6",
//   },
// },
// sendButton: {
//   height: "2.7em",
//   "&:hover": {
//     opacity: "0.6",
//   },
// },
// drawMenu: {
//   display: "flex",
//   justifyContent: "flex-end",
//   borderRadius: "5px",
//   background: "#f8f8f8",
// },
// disconnectButton: {
//   margin: "0 1em 0 1em",
//   height: "2.7em",
//   background: "#fc0909",
//   "&:hover": {
//     background: "#fc0909",
//     opacity: "0.6",
//   },
// },

// const StyleOutlinedInput = withStyles((theme) => ({
//   root: {
//     height: "2.4em",
//     margin: "0.4em 1em 0 1.7em",
//     paddingTop: "0.5em",
//     paddingBottom: "0.5em",
//     "&$focused": {
//       "& $notchedOutline": {
//         borderColor: "#fccb00",
//       },
//     },
//     "&$disabled": {
//       color: "#747473",
//       "& $notchedOutline": {
//         borderColor: "#747473",
//         backgroundColor: "#000000",
//       },
//     },
//   },
//   notchedOutline: {
//     borderColor: "#747473",
//   },
//   focused: {},
//   disabled: {},
// }))(OutlinedInput);

// NOTE: 不要になった機能のHTMLタグのBK
/* <Box sx={{ mr: "1em" }} className={classes.drawMenu}>
<ToolbarItem
  onClick={() => {
    handleOpenHandWriting();
  }}
  buttonId="edit"
  iconName="edit"
/>
<Divider orientation="vertical" flexItem style={{ margin: "8px 0" }} />
<ToolbarItem
  onClick={() => {
    canvasAction.clearHandWriting(sendMessage, myRoomId, myFrontendId);
  }}
  buttonId="edit_off"
  iconName="edit_off"
/>
</Box> */

/* <Box sx={{ p: "0.5em" }} className={classes.drawMenu}> */
/* <Button
              id="liveCircleButton"
              className={classes.strokeButton}
              variant="contained"
              color="primary"
              onClick={() => {
                canvasAction.setCurrentDrawType("liveCircle");
                changeButtonColor();
              }}
            >
              {i18n.t("button.liveCircle")}
            </Button> */
/* <Button
              id="imageButton"
              className={classes.strokeButton}
              variant="contained"
              color="primary"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {canvasAction.currentCursor == null ? <p>画像選択</p> : <img src={canvasAction.currentCursor.src} height="30" />}
            </Button>
            <Menu id="image-select-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseM}>
              {CursorList.map((cursor, index) => {
                return (
                  <MenuItem onClick={() => onClickMenuItem(cursor)} style={{ backgroundColor: "black" }} key={index}>
                    <img src={cursor.src} height="40" />
                    {index}
                  </MenuItem>
                );
              })}
            </Menu> */
/* <Button
              id="arrowButton"
              className={classes.clickButton}
              variant="contained"
              color="primary"
              onClick={() => {
                canvasAction.setCurrentDrawType("arrow");
                changeButtonColor();
              }}
            >
              {i18n.t("button.arrow")}
            </Button> */
/* <Button
              id="liveArrowButton"
              className={classes.strokeButton}
              variant="contained"
              color="primary"
              onClick={() => {
                canvasAction.setCurrentDrawType("liveArrow");
                // TODO:↓矢印２をすでに描写している状態で、再度矢印２をクリックしたらfalseになるので、トラッキングされないかもしれない。
                // TODO:リファクタリング中なので、別でテストし修正する
                canvasAction.setCurrentTransformShareDrawStart(false);
                changeButtonColor();
              }}
            >
              {i18n.t("button.liveArrow")}
            </Button> */
/* <Box sx={{ m: "0.4em 0 0 1.5em" }}>
              <Button
                className={classes.clearButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  canvasAction.clearDrawClick(sendMessage, myRoomId, myFrontendId);
                }}
              >
                {i18n.t("button.clearCanvas")}
              </Button>
            </Box> */
/* <StyleOutlinedInput
              id="text_message"
              color="primary"
              multiline={true}
              maxRows={2}
              margin="none"
              onChange={(e) => {
                canvasAction.DrawInputText(sendMessage, e, circleSize, penColor, canvasAction.inputText, myRoomId, myFrontendId);
              }}
              onFocus={() => {
                canvasAction.setCurrentDrawType("textMessage");
                changeButtonColor();
              }}
              placeholder={i18n.t("button.inputTextPlaceholder")}
              endAdornment={
                <IconButton
                  size="small"
                  color="inherit"
                  className="material-icons clear"
                  onClick={() => {
                    canvasAction.clearInputText(sendMessage, myRoomId, myFrontendId);
                  }}
                >
                  clear
                </IconButton>
              }
            /> */
/* </Box> */

/* <Box sx={{ ml: "1.5em", display: "flex", alignItems: "center" }}>
{showColorPalette && (
  <GithubPicker
    width="212px"
    triangle="hide"
    colors={colorPalette}
    onChange={(value) => {
      colRGBA = combineColorAlpha(value.hex, value.rgb.a);
      setPenColor(colRGBA);
      handleColorClick(colRGBA);

      //NOTE: グラス側、手描き画像追従機能実現のため、手描き画像を再送信
      if (canvasAction.handWritingImages.length > 0) {
        const img = canvasAction.createLiveHandWritingImage(circleSize, colRGBA);
        setClearOverlayImage(sendMessage);
        setOverlayImage(sendMessage, img);
      }
    }}
  />
)}
<Box sx={{ width: 250, ml: "1.5em" }}>
  <Box sx={{ fontWeight: "bold" }}>{i18n.t("label.circleSize")}</Box>
  <Grid container spacing={2} alignItems="center">
    <Grid item xs>
      <Slider
        valueLabelDisplay="auto"
        step={CONST.CANVAS_CIRCLE_SIZE.STEP}
        min={CONST.CANVAS_CIRCLE_SIZE.MIN}
        max={CONST.CANVAS_CIRCLE_SIZE.MAX}
        value={circleSize}
        marks
        onChange={(_event: React.ChangeEvent<HTMLInputElement>, value: number) => {
          setCircleSize(value);

          //NOTE: グラス側、手描き画像追従機能実現のため、手描き画像を再送信
          if (canvasAction.handWritingImages.length > 0) {
            const img = canvasAction.createLiveHandWritingImage(circleSize, colRGBA);
            setClearOverlayImage(sendMessage);
            setOverlayImage(sendMessage, img);
          }
        }}
      />
    </Grid>
  </Grid>
</Box>
<Box sx={{ ml: "1em" }}>
  <ToolbarItem
    onClick={() => {
      setShowDeviceSetting(true);
    }}
    buttonId="device-setting"
    iconName="settings"
  />
</Box>
<Button className={classes.disconnectButton} variant="contained" color="primary" onClick={handleExit}>
  {i18n.t("button.disconnect")}
</Button>
</Box> */

/* <Button id="circleButton" className={classes.strokeButton}
    variant="contained" color="primary"
    onClick={() => {
      drawType = "circle";
      changeButtonColor();
    }} >
    {i18n.t("button.circle")}
  </Button> */

/* <Button id="strokeButton"
    className={classes.strokeButton}
    variant="contained"
    color="primary"
    onClick={() => {
      drawType = "stroke";
      changeButtonColor();
    }} >
    {i18n.t("button.stroke")}
  </Button> */

/* <Button
    id="image2Button"
    className={classes.strokeButton}
    variant="contained"
    color="primary"
    aria-controls={open ? "basic-menu" : undefined}
    aria-haspopup="true"
    aria-expanded={open ? "true" : undefined}
    onClick={handleClick2}
  >
    {currentCursor2 == null ? <p>画像選択2</p> : <img src={currentCursor2.src} height="30" />}
  </Button>
  <Menu id="image-select-menu2" anchorEl={anchorEl2} open={Boolean(anchorEl2)} onClose={handleCloseM2}>
    {CursorList2.map((cursor, index) => {
      return (
        <MenuItem onClick={() => onClickMenuItem2(cursor)} style={{ backgroundColor: "black" }} key={index}>
          <img src={cursor.src} height="40" />
          {index}
        </MenuItem>
      );
    })}
  </Menu> */

/* <Button className={classes.strokeButton} variant="contained" color="primary"
    onClick={props.onClose} >
    {i18n.t("button.return")} */
/* {i18n.t("button.close")}  */
/* </Button> */

// NOTE:『canvasDialogForVideo.tsx』から『canvasActions.ts』へ移動した関数のBK
// const DrawStroke = (
//   x: number,
//   y: number,
//   // e: React.MouseEvent<HTMLCanvasElement>,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   width: number,
//   color: string
// ) => {
//   const canvas = e.target as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");
//   ctx.beginPath();
//   //ctx.globalAlpha = 0.7;
//   if (mouseX === null || mouseY === null) {
//     ctx.moveTo(x, y);
//   } else {
//     ctx.moveTo(mouseX, mouseY);
//   }
//   ctx.lineTo(x, y);
//   ctx.lineCap = "round";
//   ctx.lineWidth = width;
//   ctx.strokeStyle = color; //"#fccb00cc";
//   ctx.stroke();
//   mouseX = x;
//   mouseY = y;
// };

// const DrawArrow = (
//   sendMessage: (Message) => void,
//   x: number,
//   y: number,
//   // e: React.MouseEvent<HTMLCanvasElement>,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   width: number,
//   color: string
// ) => {
//   if (e.button !== 0) {
//     return;
//   }
//   //console.log('drawType=' + drawType);
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");
//   const scale = width - 3;
//   //const x = e.nativeEvent.offsetX * (canvas.width / canvas.clientWidth);
//   //const y = e.nativeEvent.offsetY * (canvas.height / canvas.clientHeight);
//   //ctx.globalAlpha = 0.7;
//   ctx.fillStyle = color; //"#fccb00cc";
//   ctx.beginPath();
//   ctx.translate(x, y);
//   ctx.scale(scale, scale);
//   let w = 1;
//   let w2 = 2;
//   let l = 4;
//   ctx.moveTo(0, 0);
//   ctx.lineTo(w2, -w2);
//   ctx.lineTo(w, -w2);
//   ctx.lineTo(w, -l);
//   ctx.lineTo(-w, -l);
//   ctx.lineTo(-w, -w2);
//   ctx.lineTo(-w2, -w2);
//   ctx.lineTo(0, 0);
//   ctx.fill();
//   ctx.setTransform(1, 0, 0, 1, 0, 0); // reset transform
//   const bounds = ctx.getImageData(x - w2, y - l, w2 * 2, l);
//   const arrowWidth = bounds.width;

//   sendMessage({
//     messageType: MESSAGE_TYPE.ARROW,
//     roomId: myRoomId,
//     point: { x: x / canvas.width, y: y / canvas.height },
//     width: arrowWidth,
//     color: color,
//     scale: scale,
//     timestamp: new Date().getTime(),
//     transform: glassesTransform0?.transform,
//     objectId: new Date().getTime(),
//     frontendId: myFrontendId,
//   });
// };

// const DrawCircle = (
//   sendMessage: (Message) => void,
//   x: number,
//   y: number,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   width: number,
//   color: string
// ) => {
//   if (e != null && e.button !== 0) {
//     return;
//   }
//   //console.log('drawType=' + drawType);
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");

//   const radius = width;
//   const lineWidth = radius / 5;
//   ctx.strokeStyle = color;
//   ctx.lineWidth = lineWidth;
//   ctx.beginPath();
//   ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
//   ctx.stroke();
//   //console.log("DrawCircle width = "+width);
//   //console.log("DrawCircle radius = "+radius);
//   //console.log("DrawCircle lineWidth = "+lineWidth);
//   sendMessage({
//     messageType: MESSAGE_TYPE.CIRCLE,
//     roomId: myRoomId,
//     point: { x: x / canvas.width, y: y / canvas.height },
//     // radius: radius / canvas.height,
//     radius: radius,
//     width: lineWidth,
//     color: color,
//     timestamp: new Date().getTime(),
//     transform: glassesTransform0?.transform,
//     objectId: new Date().getTime(),
//     frontendId: myFrontendId,
//   });
// };

// const DrawLiveCircle = (
//   sendMessage: (Message) => void,
//   x: number,
//   y: number,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   width: number,
//   color: string
// ) => {
//   //console.log(`drawType=${drawType}:  ${x},${y}`);
//   //if (e != null && e.button !== 0) { return; }
//   let ts: number = new Date().getTime();
//   if (ts < lastTimeStamp + 40) {
//     // 間引き
//     return;
//   } else {
//     lastTimeStamp = ts;
//   }
//   clearCanvas(sendMessage);
//   if (!canvasAction.DrawStart) {
//     // マウスクリックで開始させる
//     drawTextCursor(sendMessage, x, y, e, "#ff0");
//     return;
//   }
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");

//   canvasAction.restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");
//   const radius = width;
//   const lineWidth = radius / 5;
//   ctx.strokeStyle = color;
//   ctx.lineWidth = lineWidth;
//   ctx.beginPath();
//   ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
//   ctx.stroke();
//   sendMessage({
//     messageType: MESSAGE_TYPE.CIRCLE,
//     roomId: myRoomId,
//     point: { x: x / canvas.width, y: y / canvas.height },
//     // 刹那の微調整
//     //point: { x: x / canvas.width + 0.02 ,
//     //        y: y / canvas.height  + 0.026}, //cindy 0.023 },
//     // calib proc:
//     // centerPos@Disp-L/R -> d2c_L/R -> pos_L/R@cam - curs_L/R => offset_L/R
//     radius: radius / canvas.height,
//     width: lineWidth,
//     color: color,
//     timestamp: new Date().getTime(),
//     transform: glassesTransform0?.transform,
//     objectId: ts,
//     frontendId: myFrontendId,
//   });
// };

// const DrawImage = (
//   sendMessage: (Message) => void,
//   x: number,
//   y: number,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   width: number
// ) => {
//   let ts: number = new Date().getTime();
//   if (ts < lastTimeStamp + 40) {
//     // 間引き
//     return;
//   } else {
//     lastTimeStamp = ts;
//   }
//   clearCanvas(sendMessage);
//   if (!canvasAction.DrawStart) {
//     // マウスクリックで開始させる
//     drawTextCursor(sendMessage, x, y, e, "#ff0");
//     return;
//   }
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");

//   canvasAction.restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");
//   // load image
//   const img = new Image();
//   const cur = currentCursor;
//   img.src = cur.src;
//   const scale = width / 20.0; // from Slider
//   const w = Math.trunc(img.width * scale); // to Int
//   const h = Math.trunc(img.height * scale);
//   const x0 = Math.trunc(x - cur.origin[0] * scale);
//   const y0 = Math.trunc(y - cur.origin[1] * scale);
//   // originがカーソル位置になるようにシフト+スケールしてimgを描画
//   //ctx.globalCompositeOperation = 'source-over';
//   ctx.globalAlpha = 0.8;
//   ctx.drawImage(img, x0, y0, w, h);
//   //console.log("scale = "+scale);
//   sendMessage({
//     messageType: MESSAGE_TYPE.IMAGE,
//     imageType: cur.imgType,
//     roomId: myRoomId,
//     point: { x: x / canvas.width, y: y / canvas.height },
//     showPosition: { x: cur.origin[0], y: cur.origin[1] },
//     scale: scale,
//     //color: color,
//     timestamp: new Date().getTime(),
//     transform: glassesTransform0?.transform,
//     objectId: ts,
//     frontendId: myFrontendId,
//   });
// };

// const DrawImage2 = (
//   sendMessage: (Message) => void,
//   x: number,
//   y: number,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   width: number
// ) => {
//   let ts: number = new Date().getTime();
//   if (ts < lastTimeStamp + 40) {
//     // 間引き
//     return;
//   } else {
//     lastTimeStamp = ts;
//   }
//   clearCanvas(sendMessage);
//   if (!canvasAction.DrawStart) {
//     // マウスクリックで開始させる
//     drawTextCursor(sendMessage, x, y, e, "#ff0");
//     return;
//   }
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");

//   canvasAction.restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");
//   // load image
//   const img = new Image();
//   const cur = currentCursor2;
//   img.src = cur.src;
//   const scale = width / 20.0; // from Slider
//   const w = Math.trunc(img.width * scale); // to Int
//   const h = Math.trunc(img.height * scale);
//   const x0 = Math.trunc(x - cur.origin[0] * scale);
//   const y0 = Math.trunc(y - cur.origin[1] * scale);
//   // originがカーソル位置になるようにシフト+スケールしてimgを描画
//   //ctx.globalCompositeOperation = 'source-over';
//   ctx.globalAlpha = 0.8;
//   ctx.drawImage(img, x0, y0, w, h);
//   //console.log("scale = "+scale);
//   sendMessage({
//     messageType: MESSAGE_TYPE.IMAGE,
//     imageType: cur.imgType,
//     roomId: myRoomId,
//     point: { x: x / canvas.width, y: y / canvas.height },
//     showPosition: { x: cur.origin[0], y: cur.origin[1] },
//     scale: scale,
//     //color: color,
//     timestamp: new Date().getTime(),
//     transform: glassesTransform0?.transform,
//     objectId: ts,
//     frontendId: myFrontendId,
//   });
// };

// const DrawInputText = (
//   sendMessage: (Message) => void,
//   event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
//   width: number,
//   color: string,
//   inputText: string
// ) => {
//   clearCanvas(() => {});
//   inputText = event.target.value;

//   const timestamp = new Date().getTime();
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");
//   const widthAdjustment = width + 10;
//   const bottomLeft_X =
//     Math.min(CONST.GLASS_CALIBRATION_RESULT.bottomLeft_L_X, CONST.GLASS_CALIBRATION_RESULT.bottomLeft_R_X) * canvas.width +
//     widthAdjustment;
//   const bottomRight_X =
//     Math.max(CONST.GLASS_CALIBRATION_RESULT.bottomRight_L_X, CONST.GLASS_CALIBRATION_RESULT.bottomRight_R_X) * canvas.width -
//     widthAdjustment;
//   const maxWidth = bottomRight_X - bottomLeft_X;
//   const bottomLeft_Y =
//     Math.max(CONST.GLASS_CALIBRATION_RESULT.bottomLeft_L_Y, CONST.GLASS_CALIBRATION_RESULT.bottomLeft_R_Y) * canvas.height -
//     widthAdjustment;
//   const drawnText = drawTextWithLineBreaks(ctx, inputText, color, widthAdjustment, maxWidth, bottomLeft_X, bottomLeft_Y);
//   canvasAction.restoreBufferCanvasFromHistory("DrawHandWriting", "DrawArrow");

//   //buffer
//   const buffer_canvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
//   const buffer_ctx = buffer_canvas.getContext("2d");
//   buffer_ctx.clearRect(0, 0, buffer_canvas.width, buffer_canvas.height);
//   drawTextWithLineBreaks(buffer_ctx, inputText, color, widthAdjustment, maxWidth, bottomLeft_X, bottomLeft_Y);
//   canvasAction.saveCanvasHistory("DrawInputText", timestamp);

//   sendMessage({
//     messageType: MESSAGE_TYPE.TEXT_MESSAGE,
//     roomId: myRoomId,
//     textMessage: drawnText,
//     // scale: widthAdjustment / 10,
//     width: widthAdjustment,
//     timestamp: timestamp,
//     objectId: timestamp,
//     frontendId: myFrontendId,
//   });
// };

// const drawTextWithLineBreaks = (
//   ctx: CanvasRenderingContext2D,
//   text: string,
//   color: string,
//   fontSize: number,
//   maxWidth: number,
//   x: number,
//   y: number
// ): string => {
//   ctx.font = `${fontSize}px Arial`;
//   ctx.textAlign = "left";
//   ctx.textBaseline = "top";
//   ctx.fillStyle = color;
//   ctx.strokeStyle = color;

//   let currentLine = "";
//   const lines: string[] = [];
//   for (const character of text) {
//     if (character === "\n") {
//       lines.push(currentLine.trim());
//       currentLine = "";
//       continue;
//     }

//     const lineLength = ctx.measureText(convertFullWidthToHalfWidth(currentLine + character)).width;
//     if (lineLength > maxWidth) {
//       lines.push(currentLine.trim());
//       currentLine = "";
//     }

//     currentLine += convertFullWidthToHalfWidth(character);
//   }
//   if (currentLine) lines.push(currentLine.trim());

//   const totalHeight = y - lines.length * fontSize;
//   for (let i = 0; i < lines.length; i++) {
//     let line = lines[i];
//     ctx.fillText(line, x, totalHeight + i * fontSize);
//   }

//   let drawnText = "";
//   drawnText = lines.join("\n");
//   return drawnText;
// };

// const drawTextCursor = (
//   sendMessage: (Message) => void,
//   x: number,
//   y: number,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   color: string
// ) => {
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");
//   ctx.font = "15pt Arial";
//   //ctx.lineWidth = 2;
//   ctx.fillText("Click to start", x, y);
//   ctx.fillStyle = color;
//   //ctx.strokeText('Click to start', x,y); // , canvas.width);
//   //ctx.strokeStyle = color;
//   ctx.textAlign = "center";
//   ctx.textBaseline = "bottom";
// };

// const DrawCalibCursor = (
//   sendMessage: (Message) => void,
//   x: number,
//   y: number,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   width: number,
//   color: string
// ) => {
//   let ts: number = new Date().getTime();
//   if (ts < lastTimeStamp + 40) {
//     return;
//   } else {
//     lastTimeStamp = ts;
//   }
//   clearCanvas(sendMessage);

//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");

//   ctx.strokeStyle = "red";
//   ctx.beginPath();
//   ctx.translate(x, y);
//   ctx.scale(2, 2);
//   ctx.moveTo(0, 0);
//   ctx.lineTo(5, 0);
//   ctx.lineTo(-5, 0);
//   ctx.moveTo(0, 0);
//   ctx.lineTo(0, 5);
//   ctx.lineTo(0, -5);
//   ctx.setTransform(1, 0, 0, 1, 0, 0);

//   ctx.stroke();
// };

// 表示枠(左右をマージ，L+R->矩形化)
// const drawBoundary_LR = (
//   sendMessage: (Message) => void,
//   e: React.PointerEvent<HTMLCanvasElement>,
//   needInitCircle: Boolean,
//   circleSize: number = 5,
//   penColor: string = ""
// ) => {
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");
//   const topLeft_X =
//     Math.min(CONST.GLASS_CALIBRATION_RESULT.topLeft_L_X, CONST.GLASS_CALIBRATION_RESULT.topLeft_R_X) * canvas.width;
//   const topLeft_Y =
//     Math.min(CONST.GLASS_CALIBRATION_RESULT.topLeft_L_Y, CONST.GLASS_CALIBRATION_RESULT.topLeft_R_Y) * canvas.height;
//   const topRight_X =
//     Math.max(CONST.GLASS_CALIBRATION_RESULT.topRight_L_X, CONST.GLASS_CALIBRATION_RESULT.topRight_R_X) * canvas.width;
//   const topRight_Y =
//     Math.min(CONST.GLASS_CALIBRATION_RESULT.topRight_L_Y, CONST.GLASS_CALIBRATION_RESULT.topRight_R_Y) * canvas.height;
//   const bottomRight_X =
//     Math.max(CONST.GLASS_CALIBRATION_RESULT.bottomRight_L_X, CONST.GLASS_CALIBRATION_RESULT.bottomRight_R_X) * canvas.width;
//   const bottomRight_Y =
//     Math.max(CONST.GLASS_CALIBRATION_RESULT.bottomRight_L_Y, CONST.GLASS_CALIBRATION_RESULT.bottomRight_R_Y) * canvas.height;
//   const bottomLeft_X =
//     Math.min(CONST.GLASS_CALIBRATION_RESULT.bottomLeft_L_X, CONST.GLASS_CALIBRATION_RESULT.bottomLeft_R_X) * canvas.width;
//   const bottomLeft_Y =
//     Math.max(CONST.GLASS_CALIBRATION_RESULT.bottomLeft_L_Y, CONST.GLASS_CALIBRATION_RESULT.bottomLeft_R_Y) * canvas.height;
//   //ctx.strokeStyle = "red";
//   ctx.strokeStyle = "#ff000077"; // RGBA
//   ctx.lineWidth = 2;
//   ctx.beginPath();
//   ctx.moveTo(topLeft_X, topLeft_Y);
//   ctx.lineTo(topRight_X, topRight_Y);
//   ctx.lineTo(bottomRight_X, bottomRight_Y);
//   ctx.lineTo(bottomLeft_X, bottomLeft_Y);
//   ctx.lineTo(topLeft_X, topLeft_Y);
//   ctx.stroke();
// };

/*
// 表示枠（右目）
const drawBoundary_L = (sendMessage: (Message) => void,
  e: React.PointerEvent<HTMLCanvasElement>,
  needInitCircle: Boolean, circleSize: number = 5, penColor: string = "") => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  const topLeft_L_X = CONST.GLASS_CALIBRATION_RESULT.topLeft_L_X * canvas.width;
  const topLeft_L_Y = CONST.GLASS_CALIBRATION_RESULT.topLeft_L_Y * canvas.height;
  const topRight_L_X = CONST.GLASS_CALIBRATION_RESULT.topRight_L_X * canvas.width;
  const topRight_L_Y = CONST.GLASS_CALIBRATION_RESULT.topRight_L_Y * canvas.height;
  const bottomRight_L_X = CONST.GLASS_CALIBRATION_RESULT.bottomRight_L_X * canvas.width;
  const bottomRight_L_Y = CONST.GLASS_CALIBRATION_RESULT.bottomRight_L_Y * canvas.height;
  const bottomLeft_L_X = CONST.GLASS_CALIBRATION_RESULT.bottomLeft_L_X * canvas.width;
  const bottomLeft_L_Y = CONST.GLASS_CALIBRATION_RESULT.bottomLeft_L_Y * canvas.height;
  ctx.strokeStyle = "#ff000077"; // RGBA
  ctx.lineWidth = 2;
  ctx.moveTo(topLeft_L_X, topLeft_L_Y);
  ctx.lineTo(topRight_L_X, topRight_L_Y);
  ctx.lineTo(bottomRight_L_X, bottomRight_L_Y);
  ctx.lineTo(bottomLeft_L_X, bottomLeft_L_Y);
  ctx.lineTo(topLeft_L_X, topLeft_L_Y);
  ctx.stroke()
  //ctx.strokeRect(topLeft_L_X, topLeft_L_Y, boundaryWidth, boundaryHeight);
  //if (needInitCircle) {
  //  const boundaryWidth  = bottomRight_L_X - topLeft_L_X;
  //  const boundaryHeight = bottomRight_L_Y - topLeft_L_Y;
  //  const circleX = topLeft_L_X + boundaryWidth / 2
  //  const circleY = topLeft_L_Y + boundaryHeight / 2
  //  DrawCircle(sendMessage, circleX, circleY, e, circleSize, penColor);
  //}
}
*/

/*
// 表示枠（右目）
const drawBoundary_R = (sendMessage: (Message) => void,
  e: React.PointerEvent<HTMLCanvasElement>,
  needInitCircle: Boolean, circleSize: number = 5, penColor: string = "") => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  const topLeft_R_X = CONST.GLASS_CALIBRATION_RESULT.topLeft_R_X * canvas.width;
  const topLeft_R_Y = CONST.GLASS_CALIBRATION_RESULT.topLeft_R_Y * canvas.height;
  const topRight_R_X = CONST.GLASS_CALIBRATION_RESULT.topRight_R_X * canvas.width;
  const topRight_R_Y = CONST.GLASS_CALIBRATION_RESULT.topRight_R_Y * canvas.height;
  const bottomRight_R_X = CONST.GLASS_CALIBRATION_RESULT.bottomRight_R_X * canvas.width;
  const bottomRight_R_Y = CONST.GLASS_CALIBRATION_RESULT.bottomRight_R_Y * canvas.height;
  const bottomLeft_R_X = CONST.GLASS_CALIBRATION_RESULT.bottomLeft_R_X * canvas.width;
  const bottomLeft_R_Y = CONST.GLASS_CALIBRATION_RESULT.bottomLeft_R_Y * canvas.height;
  ctx.strokeStyle = "#0000ff77"; // RGBA
  ctx.lineWidth = 2;
  ctx.moveTo(topLeft_R_X, topLeft_R_Y);
  ctx.lineTo(topRight_R_X, topRight_R_Y);
  ctx.lineTo(bottomRight_R_X, bottomRight_R_Y);
  ctx.lineTo(bottomLeft_R_X, bottomLeft_R_Y);
  ctx.lineTo(topLeft_R_X, topLeft_R_Y);
  ctx.stroke()
}
*/

/*
const initTracking = (sendMessage: (Message) => void) => {
  sendMessage({
    messageType: MESSAGE_TYPE.WT_INIT_TRACKING,
    roomId: myRoomId,
    frontendId: myFrontendId,
  });
};
*/

// /**
//  * キャンバスに描写しているアノテーションとグラス側のアノテーションをクリアします。
//  * @param sendMessage WebSocketを介してサーバーにメッセージを送信するための関数
//  * @param e ポインターイベント
//  * @param deleteAll 存在する場合はすべてのアノテーションを削除することを示すオプションの文字列引数
//  * WARNING:	deleteAllがFalseの場合は、グラス側の "手描き", "テキストメッセージ" を削除しません。
//  * NOTE:当初はアノテーションを同時に表示しない仕様だったので、アノテーションをすべて削除するclearCanvas()を至るところで使用する実装をしていた。
//  * そこから、アノテーションを同時に表示する仕様変更が発生。
//  * その仕様変更について、仮実装で検証する段階であるため、実装後、再度仕様が変更する可能性がある。
//  * さらに実装期限も設けられているので、既存の処理に影響が少ないかつ工数が少ない方法として、clearCanvasを使用した処理で実装。
//  * TODO:検証後、仕様が固まったらアノテーション個別削除機能を改めて実装する。
//  */
// const clearCanvas = (sendMessage: (message) => void, event: React.PointerEvent<HTMLCanvasElement> = null, deleteAll = false) => {
//   const canvas = document.getElementById("canvas") as HTMLCanvasElement;
//   const ctx = canvas.getContext("2d");
//   ctx.clearRect(0, 0, canvas.width, canvas.height);
//   sendMessage({
//     messageType: MESSAGE_TYPE.DELETE_ALL,
//     roomId: myRoomId,
//     frontendId: myFrontendId,
//     deleteAll,
//   });
//   // points = [];
//   canvasAction.drawBoundary_LR();
//   // drawBoundary_L(sendMessage,e,false);
//   // drawBoundary_R(sendMessage,e,false);
// };

// const clearHandWriting = (sendMessage: (Message) => void, e: React.PointerEvent<HTMLCanvasElement> = null) => {
//   clearCanvas(() => {});

//   sendMessage({
//     messageType: CONST.MESSAGE_TYPE.CLEAROVERLAYIMAGE,
//     roomId: myRoomId,
//     timestamp: new Date().getTime(),
//   });

//   canvasAction.deleteCanvasHistoryBy("DrawHandWriting");
//   canvasAction.restoreBufferCanvasFromHistory("DrawArrow", "DrawInputText");
// };

// const ClearDrawStroke = (sendMessage: (Message) => void) => {
//   if (drawType === "arrow" || drawType === "liveArrow" || drawType === "textMessage" || drawType === "handwriting") return;
//   clearCanvas(() => {});
//   // clearCanvas(sendMessage);

//   canvasAction.deleteCanvasHistoryBy("DrawArrow");
//   canvasAction.restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");
// };

// const clearInputText = (sendMessage: (Message) => void, e: React.PointerEvent<HTMLCanvasElement> = null) => {
//   var textareaForm = (document.getElementById("text_message") as HTMLInputElement) || null;
//   textareaForm.value = "";
//   clearCanvas(() => {});

//   sendMessage({
//     messageType: CONST.MESSAGE_TYPE.DELETE_TEXT_MESSAGE,
//     roomId: myRoomId,
//     timestamp: new Date().getTime(),
//   });

//   canvasAction.deleteCanvasHistoryBy("DrawInputText");
//   canvasAction.restoreBufferCanvasFromHistory("DrawArrow", "DrawHandWriting");
// };

// for Key Input Event on Canvas
/*
  const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const key = event.code;
    //    if (key == 'Space'){
    console.log(key)
  }
*/

// for Menu 画像選択1,2ボタンの表示内容リストに移動したので不要
// const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
// const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//   setAnchorEl(event.currentTarget);
// };
// const handleCloseM = () => {
//   setAnchorEl(null);
// };
// const onClickMenuItem = (cursor) => {
//   //const imgType = event.target.innerText;
//   // currentCursor = cursor;
//   canvasAction.setCurrentCursor(cursor);
//   canvasAction.setCurrentDrawType("image");
//   changeButtonColor();
//   setAnchorEl(null); // to close menu
// };

// for Menu2
// const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
// const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
//   setAnchorEl2(event.currentTarget);
// };
// const handleCloseM2 = () => {
//   setAnchorEl2(null);
// };
// const onClickMenuItem2 = (cursor) => {
//   //const imgType = event.target.innerText;
//   currentCursor2 = cursor;
//   drawType = "image2";
//   changeButtonColor();
//   setAnchorEl2(null); // to close menu
// };

// #endregion
