import { PayloadAction } from "@reduxjs/toolkit";
import { MainState } from "../slice";

export const enlargeCursor = (state: MainState, action: PayloadAction<boolean>): void => {
  state.largeCursor = action.payload;

  if (action.payload) {
    document.body.classList.add("large-cursor");
  } else {
    document.body.classList.remove("large-cursor");
  }
};
