import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client, ConnectionID, LSTrack, MuteType } from "ricoh-ls-sdk";
import { DEFAULT_DEVICE_ID, LAYOUT, MUTE_TYPE, POV_STATE } from "../utils/constants";

import {
  DrawType,
  GlassesTransform,
  ImageCursor,
  ImageMember,
  MEDIA_TYPES,
  MediaRecorders,
  MediaStreams,
  RecordingSubViews,
  RoomMember,
  RoomMembers,
} from "../utils/types";

import {
  clearAutoDisconnectTimer,
  disconnect,
  failConnect,
  setAutoDisconnectTimer,
  startConnect,
  stopConnect,
  successConnect,
  updateIsReconnecting,
} from "./reducers/connection";

import { addRemoteConnection, addRemoteTrack, leave } from "./reducers/notification";
import {
  addRecordingMember,
  removeRecordingMember,
  setMediaRecorder,
  setRecordingStartNotification,
  setVisibleRecordingSetting,
} from "./reducers/recording";

import { setBeforeDrawType, setCurrentCursor, setInputText, setIsClickForPlacementHandWriting } from "./reducers/canvas";
import { enlargeCursor } from "./reducers/cursor";
import { setImageMember } from "./reducers/Image";
import {
  failGetDisplayMedia,
  failScreenShare,
  startGetDisplayMedia,
  startScreenShare,
  stopScreenShare,
  successGetDisplayMedia,
  successScreenShare,
} from "./reducers/screenShare";
import {
  addMainVideo,
  changeCameraEnabled,
  changeCurrentLayout,
  changeMicEnabled,
  changeMuteState,
  changeSpeakerDevice,
  changeToFullscreenLayout,
  changeToGalleryLayout,
  finishFullscreenLayout,
  removeMainVideo,
  updateLocalAudio,
  updateLocalVideo,
} from "./reducers/settingChange";
import { setHighlight, setIsSubViewHorizontal } from "./reducers/subViewOperation";
import { changeMainStatePoV, setSharingPoV } from "./reducers/thetaPoV";

export interface MainState {
  sampleName: string;
  isJoined: boolean;
  isReconnecting: boolean;
  clientId: string | null;
  // プレゼンテーションレイアウトでメインに表示される connectionId
  mainConnectionIds: Array<ConnectionID>;
  hasReceivedRemoteTrack: boolean;
  self: RoomMember;
  // immutable state はこの中で管理する
  immutable: {
    videoAudioClient: Client | null;
    screenShareClient: Client | null;
    otherMembers: RoomMembers;
    audioStreams: MediaStreams;
    videoStreams: MediaStreams;
    screenShareStreams: MediaStreams;
    localVideoAudioStream: MediaStream | null;
    localScreenShareStream: MediaStream | null;
    localTracks: LSTrack[];
    mediaRecorders: MediaRecorders;
    recordingSubViews: RecordingSubViews;
  };
  // デバイス設定で設定を変更したスピーカーの deviceId
  // 現在選択中のスピーカーを判断するAPIが無いため state で管理する
  selectedSpeakerId: string | null;
  previousLayout: LAYOUT | null;
  currentLayout: LAYOUT | null;
  muteType: MuteType;
  videoDeviceId: string | undefined;
  audioDeviceId: string | undefined;
  useDummyDevice: boolean;
  visibleRecordingSetting: boolean;
  recordingSettingConnectionId: string | null;
  enableScreenShareAudio: boolean;
  highlightConnectionIds: Array<ConnectionID>;
  isSubViewHorizontal: boolean;
  sharingPoV: ConnectionID;
  imageMember: ImageMember;
  glassesTransform: GlassesTransform;

  largeCursor: boolean;
  autoDisconnectTimer: number | null;

  canvas: {
    beforeDrawType: DrawType | null;
    currentImageCursor: ImageCursor | null;
    inputText: string;
    isClickForPlacementHandWriting: boolean;
  };
}

export const initialRoomMember: RoomMember = {
  connectionId: null,
  screenShareConnectionId: null,
  username: null,
  enableAudio: false,
  enableVideo: false,
  isTheta: false,
  poV: null,
  poVState: POV_STATE.NONE,
  mediaType: MEDIA_TYPES.VIDEO_AUDIO,
  isPod: false,
  thetaVideoFormat: null,
};

export const initialMainState: MainState = {
  sampleName: "",
  isJoined: false,
  isReconnecting: false,
  hasReceivedRemoteTrack: false,
  clientId: null,
  immutable: {
    videoAudioClient: null,
    screenShareClient: null,
    audioStreams: new Map(),
    videoStreams: new Map(),
    screenShareStreams: new Map(),
    otherMembers: new Map(),
    localVideoAudioStream: null,
    localScreenShareStream: null,
    localTracks: [],
    mediaRecorders: new Map(),
    recordingSubViews: new Map(),
  },
  mainConnectionIds: [],
  self: initialRoomMember,
  selectedSpeakerId: null,
  currentLayout: LAYOUT.GALLERY,
  previousLayout: null,
  muteType: MUTE_TYPE.HARDMUTE,
  videoDeviceId: DEFAULT_DEVICE_ID,
  audioDeviceId: DEFAULT_DEVICE_ID,
  useDummyDevice: false,
  visibleRecordingSetting: false,
  recordingSettingConnectionId: null,
  enableScreenShareAudio: false,
  highlightConnectionIds: [],
  isSubViewHorizontal: false,
  sharingPoV: null,
  imageMember: null,
  largeCursor: false,
  autoDisconnectTimer: null,
  glassesTransform: null,

  canvas: {
    beforeDrawType: null,
    currentImageCursor: null,
    inputText: "",
    isClickForPlacementHandWriting: false,
  },
};

export const mainSlice = createSlice({
  name: "main",
  initialState: initialMainState,
  reducers: {
    startGetDisplayMedia,
    failGetDisplayMedia,
    successGetDisplayMedia,
    startScreenShare,
    failScreenShare,
    successScreenShare,
    stopScreenShare,
    startConnect,
    successConnect,
    failConnect,
    stopConnect,
    updateLocalAudio,
    updateLocalVideo,
    changeMuteState,
    changeSpeakerDevice,
    changeToFullscreenLayout,
    changeToGalleryLayout,
    changeCurrentLayout,
    finishFullscreenLayout,
    addMainVideo,
    removeMainVideo,
    changeCameraEnabled,
    changeMicEnabled,
    addRemoteTrack,
    addRemoteConnection,
    leave,
    setIsSubViewHorizontal,
    setHighlight,
    setVisibleRecordingSetting,
    setMediaRecorder,
    setRecordingStartNotification,
    addRecordingMember,
    removeRecordingMember,
    disconnect,
    changeMainStatePoV,
    setSharingPoV,
    setImageMember,
    enlargeCursor,
    setAutoDisconnectTimer,
    clearAutoDisconnectTimer,
    updateIsReconnecting,
    setBeforeDrawType,
    setCurrentCursor,
    setInputText,
    setIsClickForPlacementHandWriting,
  },
});

export type SliceFunction<Payload, Result = void> = (state: MainState, action: PayloadAction<Payload>) => Result;
export const { actions } = mainSlice;
