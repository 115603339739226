import qs from "query-string";
import { isBoolean, isValidBitrate, isValidBitrateReservationMbps, isValidLayout, isValidRoomType } from "./validators";

const validateFunctions = {
  video_bitrate: isValidBitrate,
  share_bitrate: isValidBitrate,
  default_layout: isValidLayout,
  use_dummy_device: isBoolean,
  bitrate_reservation_mbps: isValidBitrateReservationMbps,
  room_type: isValidRoomType,
};

const validateQueryParam = (paramKey: string, value: string | string[], validateCondition: boolean) => {
  if (validateCondition) {
    return `&${paramKey}=${value}`;
  } else {
    return "";
  }
};

export const openMeetingWindow = ({
  roomId,
  username,
  queryParams,
}: {
  roomId: string;
  username?: string;
  queryParams?: qs.ParsedQuery<string>;
}): void => {
  const encodedUsername = encodeURIComponent(username || "default_user");
  let uriPath = `/room/${roomId}/?username=${encodedUsername}`;

  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      const value = queryParams[key];
      const validateCondition = validateFunctions[key](value);
      uriPath += validateQueryParam(key, value, validateCondition);
    });
  }

  window.open(uriPath);
};
