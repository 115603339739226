import React from "react";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/material";

import i18n from "../../locales/i18n";

const CustomizedPageLayout = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100 * var(--vh))",
  flexDirection: "column",
}));

const CustomizedLink = styled(Link)(() => ({
  marginTop: "1em",
}));

const Page404: React.FC<Record<string, never>> = () => {
  return (
    <CustomizedPageLayout component="div">
      <h1>{i18n.t("page404.title")}</h1>
      <div>{i18n.t("page404.content")}</div>
      <CustomizedLink to="/">トップページへ</CustomizedLink>
    </CustomizedPageLayout>
  );
};

export default Page404;
