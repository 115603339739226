import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { actions } from "../redux/slice";
import { MESSAGE_TYPE, GLASS_TYPE } from "./constants";
import { GlassesTransform, Message, Messages, WebSocketContextProps } from "./types";
import * as canvasAction from "./canvasActions";

// 浅いコピーではなく深いコピーをする
// JavaScriptではこの方法がスタンダード
const deepCopy = (value: Record<string, unknown> | Array<unknown>) => {
  return JSON.parse(JSON.stringify(value));
};

// コールバック関数で定義時の値がキャプチャされるのを防ぎたいときにrefを使う
// https://kgtkr.net/blog/2019/03/20/react-hooks-effect
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useValueRef = (value: any) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
};

export const useWebSocket = ({
  initialMessages,
  onCallSuccess,
  onError,
}: {
  initialMessages: Messages;
  onCallSuccess: (roomId: string) => void;
  onError: (message, closeConnect: () => void) => void;
}): WebSocketContextProps => {
  const dispatch = useDispatch();

  const [messages, setMessage] = useState<Messages>(initialMessages);
  const [isCalling, setIsCalling] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [glassCalibrationReceived, setGlassCalibrationReceived] = useState(false);
  const [glassType, setGlassType] = useState<GLASS_TYPE>(GLASS_TYPE.UNKNOWN);
  const messagesRef = useValueRef(messages);
  const isCallingRef = useValueRef(isCalling);
  const socketRef: React.MutableRefObject<undefined | WebSocket> = useRef();
  const glassesTransformRef = useRef<GlassesTransform>({
    transform: {
      rotation: { w: 1, x: 0, y: 0, z: 0 },
    },
    //timestamp: new Date().getTime(),
  });
  const getGlassesTransform = useCallback<() => GlassesTransform>(() => glassesTransformRef.current, []);
  let myRoomId = "";
  let myFrontendId = "";

  const connectWebSocket = (idToken: string) => {
    // console.log("Connecting...");
    socketRef.current = new WebSocket(`wss://${BUILD_CONFIG.BACKEND_API_BASE}:${BUILD_CONFIG.BACKEND_WEB_SOCKET_PORT}/call`, [
      idToken,
    ]);

    // 子→親のstate更新で発生する警告対策のためuseEffect()している
    useEffect(() => setIsConnected(true), [setIsConnected]);

    socketRef.current.onopen = () => {
      // console.log("Opened");
      myRoomId = sessionStorage.getItem("myRoomId");
      myFrontendId = sessionStorage.getItem("frontendId");
      // console.log("myRoomId:" + myRoomId);
      // console.log("WebSocket Opened");
      // console.log(`response.ok--> wss://${BUILD_CONFIG.BACKEND_API_BASE}:${BUILD_CONFIG.BACKEND_WEB_SOCKET_PORT}/call`);
      setIsOpened(true);
    };

    socketRef.current.onerror = (event) => {
      // console.log(`Error: ${event}`);
      // console.log(`WebSocket Error=%O`, event);
      console.error(`Error: ${event}`);
      console.error(`WebSocket Error=%O`, event);
    };

    socketRef.current.onclose = (event) => {
      // console.log(`Closed: ${event.code} ${event.reason}`);
      setIsConnected(false);
    };

    socketRef.current.onmessage = (event) => {
      // console.log(`WebSocket Received: ${event.data}`);
      try {
        const message = JSON.parse(event.data);
        if (message.roomId === myRoomId) {
          // remoteRoomIdがmyRoomIdと一致, or nullなら
          // console.log(">>Type: "+message.messageType);
          if (isCallingRef.current === true && message.messageType === MESSAGE_TYPE.CONNECT_REQUEST) {
            onCallSuccess(message.roomId);
            setIsCalling(false);
          } else if (message.messageType === MESSAGE_TYPE.GLASSES_TRANSFORM) {
            //console.log("ws>>" + message.transform.rotation);
            // console.log("--->"+message.timestamp);  // ok
            // dispatch(actions.setGlassesInfo(message.transform)); // ok
            canvasAction.GlassesTransformShare0.rotation = message.transform.rotation;
            if (canvasAction.currentTransformShareDrawStart === true) {
              // console.log("ws>>", message.transform.rotation);
              canvasAction.DrawLiveArrow(() => {}, myRoomId, myFrontendId);
            }
            glassesTransformRef.current = message;
          } else if (message.messageType === MESSAGE_TYPE.BINARY_IMAGE) {
            try {
              const dataUri = message.image;
              dispatch(actions.setImageMember(dataUri));
            } catch (error) {
              // console.log("DataURI is not included in a message.");
              console.error("DataURI is not included in a message.");
            }
          } else if (message.messageType === MESSAGE_TYPE.ORIG_VIDEO_IMG) {
            try {
              // console.log("ORIG_VIDEO_IMG--->"+message.timestamp);
              const dataUri = message.image;
              dispatch(actions.setImageMember(dataUri));
            } catch (error) {
              // console.log("DataURI is not included in a message.");
              console.error("DataURI is not included in a message.");
            }
          } else if (message.messageType === MESSAGE_TYPE.WT_PC_FAILURE) {
            // Wikitude Point Cloud 座標変換失敗通知
            // console.log("WT:PC_FAILURE> " + message.objectId); // .objectId: 失敗したオブジェクト
            // console.log("WT> " + message.objectId);
            console.error("WT> FAILURE");
          } else if (message.messageType === MESSAGE_TYPE.WT_NO_TRACKING) {
            // Wikitude: Tracking開始されていない通知
            // console.log("WT:NO_TRACKING> " + message.case); //.case: 原因
            console.error("WT: FAILURE> "); //.case: 原因
          } else if (message.messageType === MESSAGE_TYPE.GLASS_CALIBRATION) {
            // console.log("GLASS_CALIBRATION--->" + message.timestamp);
            // console.log("GLASS_CALIBRATION--->%O", message);
            canvasAction.glassCalibrationResult.glassType = message.glassType;
            canvasAction.glassCalibrationResult.displayType = message.displayType;
            // 新しいキャリブレーション結果に対応
            // Left
            canvasAction.glassCalibrationResult.topLeft_L_X = message.TopLeft_L.x;
            canvasAction.glassCalibrationResult.topLeft_L_Y = message.TopLeft_L.y;
            canvasAction.glassCalibrationResult.topRight_L_X = message.TopRight_L.x;
            canvasAction.glassCalibrationResult.topRight_L_Y = message.TopRight_L.y;
            canvasAction.glassCalibrationResult.bottomRight_L_X = message.BottomRight_L.x;
            canvasAction.glassCalibrationResult.bottomRight_L_Y = message.BottomRight_L.y;
            canvasAction.glassCalibrationResult.bottomLeft_L_X = message.BottomLeft_L.x;
            canvasAction.glassCalibrationResult.bottomLeft_L_Y = message.BottomLeft_L.y;
            // Right
            canvasAction.glassCalibrationResult.topLeft_R_X = message.TopLeft_R.x;
            canvasAction.glassCalibrationResult.topLeft_R_Y = message.TopLeft_R.y;
            canvasAction.glassCalibrationResult.topRight_R_X = message.TopRight_R.x;
            canvasAction.glassCalibrationResult.topRight_R_Y = message.TopRight_R.y;
            canvasAction.glassCalibrationResult.bottomRight_R_X = message.BottomRight_R.x;
            canvasAction.glassCalibrationResult.bottomRight_R_Y = message.BottomRight_R.y;
            canvasAction.glassCalibrationResult.bottomLeft_R_X = message.BottomLeft_R.x;
            canvasAction.glassCalibrationResult.bottomLeft_R_Y = message.BottomLeft_R.y;

            canvasAction.glassCalibrationResult.D_center_L_X = message.D_center_L.x;
            canvasAction.glassCalibrationResult.D_center_L_Y = message.D_center_L.y;
            canvasAction.glassCalibrationResult.D_center_R_X = message.D_center_R.x;
            canvasAction.glassCalibrationResult.D_center_R_Y = message.D_center_R.y;

            setGlassType(canvasAction.glassCalibrationResult.glassType);
            setGlassCalibrationReceived(true);
          } else if (message.messageType === MESSAGE_TYPE.ERROR) {
            onError(message, closeConnect);
          } else {
            setMessage({ ...messagesRef.current, [message.messageType]: message });
          }
        }
      } catch (error) {
        // console.log("Failed to parse message.");
        console.error("Failed to parse message.");
      }
    };
  };

  const resetMessage = (type: MESSAGE_TYPE) => {
    const updatedMessages = deepCopy(messages);
    delete updatedMessages[type];
    setMessage(updatedMessages);
  };

  const sendMessage = (message: Message) => {
    // console.log("WebSocket sent message >> " + JSON.stringify(message));
    socketRef.current.send(JSON.stringify(message));
    if (message.messageType === MESSAGE_TYPE.CALL) {
      setIsCalling(true);
    }
  };

  const closeConnect = () => {
    socketRef.current.close();
    socketRef.current = null;
  };

  const createUuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (a) => {
      let r = (new Date().getTime() + Math.random() * 16) % 16 | 0,
        v = a == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  return {
    getGlassesTransform,
    messages,
    isConnected,
    isOpened,
    glassCalibrationReceived,
    glassType,
    connectWebSocket,
    resetMessage,
    sendMessage,
    closeConnect,
    createUuid,
  };
  // return { messages, isConnected, connectWebSocket, resetMessage, sendMessage, closeConnect };
};

export const WebSocketContext = createContext({} as WebSocketContextProps);
