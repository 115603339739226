import { PayloadAction } from "@reduxjs/toolkit";
import { Client } from "@/lib/ricoh-ls-sdk";
import { LAYOUT } from "../../utils/constants";
import { MainState } from "../slice";
import { stopStream } from "../../utils/liveStreamingHelpers";

export const startGetDisplayMedia = (state: MainState): void => {
  // console.log("startGetDisplayMedia start");
  stopStream(state.immutable.localScreenShareStream);
  // TODO: localScreenStreamをnullにしたほうが良いのでは？
  // console.log("startGetDisplayMedia end");
};

export const successGetDisplayMedia = (
  state: MainState,
  action: PayloadAction<{ stream: MediaStream; enableAudio: boolean }>
): void => {
  // console.log("successGetDisplayMedia start");
  const { stream, enableAudio } = action.payload;
  state.immutable.localScreenShareStream = stream;
  state.enableScreenShareAudio = enableAudio;
  // console.log("successGetDisplayMedia end");
};

export const failGetDisplayMedia = (state: MainState, action: PayloadAction<string>): void => {
  // const errorMessage = action.payload;
  // console.log("failed to get screen media, error =>", errorMessage);
  console.error("Failed to get screen media");

  stopStream(state.immutable.localScreenShareStream);
  state.immutable.localScreenShareStream = null;
};

export const startScreenShare = (state: MainState): void => {
  // console.log("startScreenShare start");
  state.immutable.screenShareClient && state.immutable.screenShareClient.disconnect();
  state.immutable.screenShareClient = null;
  // console.log("startScreenShare end");
};

export const successScreenShare = (
  state: MainState,
  action: PayloadAction<{ stream: MediaStream; connectionId: string; client: Client }>
): void => {
  // console.log("successScreenShare start");
  const { stream, connectionId, client } = action.payload;

  // mediaStreams に 生成した stream を追加する
  const currentStreams = new Map(state.immutable.screenShareStreams);
  currentStreams.set(connectionId, stream);
  state.immutable.screenShareStreams = currentStreams;

  const newSelf: MainState["self"] = { ...state.self, screenShareConnectionId: connectionId };
  state.self = newSelf;

  // main connection id がなければ自分を main connection id に設定する
  // TODO: なぜmainConnectionIdsにセットする必要があるのか要確認
  if (state.mainConnectionIds.length === 0) {
    state.mainConnectionIds = [connectionId];
  }

  state.immutable.screenShareClient = client;
  // console.log("successScreenShare end");
};

export const failScreenShare = (state: MainState, action: PayloadAction<string>): void => {
  // const errorMessage = action.payload;
  // console.log("failed to connect screenshare, error =>", errorMessage);
  console.error("Failed to connect screenshare");

  stopStream(state.immutable.localScreenShareStream);
  state.immutable.localScreenShareStream = null;
  state.immutable.screenShareClient && state.immutable.screenShareClient.disconnect();
  state.immutable.screenShareClient = null;
};

export const stopScreenShare = (state: MainState): void => {
  // console.log("stopScreenShare start");
  if (!state.immutable.localScreenShareStream) return;

  stopStream(state.immutable.localScreenShareStream);

  const newSelf: MainState["self"] = { ...state.self, screenShareConnectionId: null };

  // main videoが失われた場合はGallery表示に戻す
  const isOnlyMainConnectionId =
    state.self.screenShareConnectionId &&
    state.mainConnectionIds.includes(state.self.screenShareConnectionId) &&
    state.mainConnectionIds.length === 1;
  if (isOnlyMainConnectionId) {
    state.currentLayout = LAYOUT.GALLERY;
    state.mainConnectionIds = [];
  }

  const screenShareStreams = new Map(state.immutable.screenShareStreams);
  state.self.screenShareConnectionId && screenShareStreams.delete(state.self.screenShareConnectionId);
  state.immutable.screenShareStreams = screenShareStreams;
  state.immutable.screenShareClient && state.immutable.screenShareClient.disconnect();
  state.immutable.screenShareClient = null;
  state.immutable.screenShareClient = new Client(); // TODO: これ必要？
  state.immutable.localScreenShareStream = null;

  state.self = newSelf;
  // console.log("stopScreenShare end");
};
