import { DrawType, ImageCursor } from "../../utils/types";
import { SliceFunction } from "../slice";

export const setBeforeDrawType: SliceFunction<DrawType> = ({ canvas }, { payload }) => {
  canvas.beforeDrawType = payload;
};

export const setCurrentCursor: SliceFunction<ImageCursor> = ({ canvas }, { payload }) => {
  canvas.currentImageCursor = payload;
};

export const setInputText: SliceFunction<string> = ({ canvas }, { payload }) => {
  canvas.inputText = payload;
};

export const setIsClickForPlacementHandWriting: SliceFunction<boolean> = ({ canvas }, { payload }) => {
  canvas.isClickForPlacementHandWriting = payload;
};
