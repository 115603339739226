// 共通の型定義置き場
import { ConnectionID, VideoCodecType } from "ricoh-ls-sdk";
import { DISPLAY_TYPE, GLASS_TYPE, MESSAGE_TYPE, POV_STATE } from "./constants";

interface ToolbarConfig {
  isHidden: boolean;
  isHiddenCameraButton: boolean;
  isHiddenMicButton: boolean;
  isHiddenScreenShareButton: boolean;
  isHiddenParticipantsButton: boolean;
  isHiddenDeviceSettingButton: boolean;
  isHiddenExitButton: boolean;
}

interface LSConfSampleConfig {
  BACKEND_API_BASE: string;
  BACKEND_API_PORT: number;
  BACKEND_WEB_SOCKET_PORT: number;
  LS_CLIENT_ID: string;
  LS_CONF_URL?: string;
  LS_SIGNALING_URL: string;
  THETA_ZOOM_MAX_RANGE: number;
  IS_HIDDEN_VIDEO_MENU_BUTTON: boolean;
  IS_HIDDEN_RECORDING_BUTTON: boolean;
  IS_HIDDEN_SHARE_POV_BUTTON: boolean;
  DEFAULT_LAYOUT: "gallery" | "presentation" | "fullscreen";
  TOOLBAR_CONFIG: ToolbarConfig;
  POD_COORDINATES?: {
    upperLeft: number[];
    lowerRight: number[];
  };
  THEME_CONFIG: {
    primary: string;
    background: string;
    surface: string;
    onPrimary: string;
    onSurface: string;
    textSecondaryOnBackground: string;
    components: {
      participantsVideoContainer: {
        background: string;
      };
      toolbar: {
        background: string;
        iconColor: string;
      };
      video: {
        background: string;
        textColor: string;
        textBackgroundColor: string;
        iconColor: string;
        menuBackgroundColor: string;
        menuTextColor: string;
      };
    };
  };
}

// 外部モジュールでないとglobal空間の拡張でビルドエラーになってしまうため
export {};
declare global {
  // ビルド時に指定した定数をBUILD_CONFIG.XXX  で各値を呼び出せるよう global 空間を拡張
  const BUILD_CONFIG: LSConfSampleConfig;
  // MediaDevicesのメソッドがデフォルトで定義されていないので独自定義する
  interface MediaDevices {
    getDisplayMedia(constrains?: MediaStreamConstraints): Promise<MediaStream>;
  }
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

export type ConnectionParams = {
  access_token: string;
  connection_id: string;
};

export type CognitoTokens = {
  id_token: string;
  refresh_token: string;
};

export type Layout = "gallery" | "presentation" | "fullscreen";

export type RoomType = "sfu" | "p2p" | "p2p_turn";

export type LSConfParameters = {
  roomId: string;
  username: string;
  video_bitrate?: number;
  share_bitrate?: number;
  default_layout?: Layout;
  use_dummy_device?: boolean;
  bitrate_reservation_mbps?: number;
  room_type?: RoomType;
};

export type Message = {
  messageType: string;
  roomId?: string;
  image?: string;
  poV?: PoV;
  connectionId?: ConnectionID;
  caller?: string;
  callee?: string;
  [key: string]: any;
};

export type Messages = {
  [index in MESSAGE_TYPE]?: Message;
};

export type MediaStreams = Map<ConnectionID, MediaStream>;

// eq: Equirectangular形式
// dl: Dual-Fisheye形式
export type ThetaVideoFormat = "eq" | "dl";

export enum MEDIA_TYPES {
  VIDEO_AUDIO = "VIDEO_AUDIO",
  SCREEN_SHARE = "SCREEN_SHARE",
}

export type RoomMember = {
  connectionId: string | null;
  screenShareConnectionId: string | null;
  username: string | null;
  enableVideo: boolean;
  enableAudio: boolean;
  isTheta: boolean;
  thetaVideoFormat: ThetaVideoFormat | null;
  poV: PoV | null;
  poVState: POV_STATE;
  mediaType: MEDIA_TYPES;
  // VRPod 拠点かどうかのフラグ
  isPod: boolean;
};

export type ImageMember = {
  connectionId: string | null;
  username: string | null;
  src: string | null;
};

export type PoV = {
  pan: number;
  tilt: number;
  fov: number;
};

export type Position = {
  x: number;
  y: number;
  z: number;
};

// three.jsのQuarternionと衝突回避
export type Quaternion_t = {
  w: number;
  x: number;
  y: number;
  z: number;
};

export type Transform = {
  position?: Position;
  rotation: Quaternion_t;
};

export type GlassesTransform = {
  transform: Transform;
  //timestamp: number;
};

export type GlassesTransformShare = {
  rotation: Quaternion_t;
};

type RecordingSubView = {
  connectionIds: ConnectionID[];
  startNotification: boolean;
};

export type RoomMembers = Map<ConnectionID, RoomMember>;

export type MediaRecorders = Map<ConnectionID, MediaRecorder>;

export type RecordingSubViews = Map<ConnectionID, RecordingSubView>;

export type ConnectionMetadata = {
  username: string;
  mediaType: MEDIA_TYPES;
  parentConnectionId?: string;
};

export type TrackMetadata = {
  mediaType: MEDIA_TYPES;
  isTheta: boolean;
  thetaVideoFormat: ThetaVideoFormat | null;
  isPod: boolean;
};

export type LocalConnectOption = {
  username: string;
  enableAudio: boolean;
  enableVideo: boolean;
  maxVideoBitrate: number;
  maxShareBitrate: number;
  // デバイスがない場合にも接続できる
  useDummyDevice: boolean;
  signalingURL: string;
  videoCodec: VideoCodecType;
};

export type WebSocketContextProps = {
  getGlassesTransform: () => GlassesTransform;
  messages: Messages;
  isConnected: boolean;
  isOpened: boolean;
  glassCalibrationReceived: boolean;
  glassType: GLASS_TYPE;
  connectWebSocket: (idToken: string) => void;
  resetMessage: (type: MESSAGE_TYPE) => void;
  sendMessage: (message: Message) => void;
  closeConnect: () => void;
  createUuid: () => string;
};

export interface CanvasElement extends HTMLCanvasElement {
  captureStream(frameRate?: number): MediaStream;
}

export type CognitoUser = {
  name: string;
};

export type DrawType =
  | "clickToStart"
  | "arrow"
  | "liveArrow"
  | "liveCircle"
  | "image"
  | "handWriting"
  | "liveHandWritingImage"
  | "textMessage"
  | "calibCursor";

export type DrawTarget =
  | "DrawArrow"
  | "DrawLiveCircle"
  | "DrawImage"
  | "DrawHandWriting"
  | "DrawLiveHandWriting"
  | "DrawInputText";

export interface ImageDataWithMessageType extends ImageData {
  drawTarget: string;
  objectId: number;
}

export interface ImageDataWithColor extends ImageData {
  color: string;
}

export interface ImageCursor {
  src: string;
  origin: [number, number];
  imgType: string;
}

export type GlassCalibrationResult = {
  topLeft_L_X: number;
  topLeft_L_Y: number;
  topRight_L_X: number;
  topRight_L_Y: number;
  bottomRight_L_X: number;
  bottomRight_L_Y: number;
  bottomLeft_L_X: number;
  bottomLeft_L_Y: number;
  // Right
  topLeft_R_X: number;
  topLeft_R_Y: number;
  topRight_R_X: number;
  topRight_R_Y: number;
  bottomRight_R_X: number;
  bottomRight_R_Y: number;
  bottomLeft_R_X: number;
  bottomLeft_R_Y: number;
  /* 旧仕様
  topLeftX: 0.166015625,
  topLeftY: 0.30125,
  bottomRightX: 0.600390625,
  bottomRightY: 0.654375,
  */
  glassType: GLASS_TYPE;
  displayType: DISPLAY_TYPE;
  // normalized center pos @camera of display L/R
  D_center_L_X: number;
  D_center_L_Y: number;
  D_center_R_X: number;
  D_center_R_Y: number;
};

export type GlassCalibrationBoundary = {
  topLeftX: number;
  topLeftY: number;
  topRightX: number;
  topRightY: number;
  bottomRightX: number;
  bottomRightY: number;
  bottomLeftX: number;
  bottomLeftY: number;
};

export type HandWritingLine = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  width: number;
};

export type RouteProps = {
  element: React.ReactNode;
};

export interface AuthenticationState {
  isTokenInvalid: boolean;
}

export interface LocationState {
  from: Location;
}

export type AlertSeverity = "success" | "info" | "warning" | "error";
