import { PayloadAction } from "@reduxjs/toolkit";
import { ConnectionID } from "ricoh-ls-sdk";
import { MainState } from "../slice";

export const setIsSubViewHorizontal = (state: MainState, action: PayloadAction<boolean>): void => {
  state.isSubViewHorizontal = action.payload;
};

export const setHighlight = (
  state: MainState,
  action: PayloadAction<{ targetConnectionId: ConnectionID; highlight: boolean }>
): void => {
  const { targetConnectionId, highlight } = action.payload;
  let found = false;

  state.immutable.otherMembers.forEach((member) => {
    if (member.connectionId === targetConnectionId || member.screenShareConnectionId === targetConnectionId) {
      found = true;
    }
  });
  if (state.self.connectionId === targetConnectionId || state.self.screenShareConnectionId === targetConnectionId) {
    found = true;
  }
  if (state.imageMember?.connectionId === targetConnectionId) {
    found = true;
  }

  if (found) {
    if (highlight) {
      const connectionIds = state.highlightConnectionIds;
      if (!connectionIds.includes(targetConnectionId)) {
        connectionIds.push(targetConnectionId);
      }
      state.highlightConnectionIds = [...connectionIds];
    } else {
      state.highlightConnectionIds = state.highlightConnectionIds.filter((id) => id !== targetConnectionId);
    }
  } else {
    // console.log(`Not found SubView: ${targetConnectionId}`);
    console.warn("Not found SubView");
  }
};
