// TODO:カスタムフックを使用した処理も都度実装
// #region --- Import ---
import { Euler, Quaternion, Vector3 } from "three";

import {
  CANVAS_CIRCLE_SIZE,
  CANVAS_PEN_COLOR,
  DISPLAY_TYPE,
  GLASS_TYPE,
  HANDWRITING_CANVAS,
  MESSAGE_TYPE,
} from "../utils/constants";
import { convertFullWidthToHalfWidth } from "../utils/convertors";
import {
  DrawTarget,
  DrawType,
  GlassCalibrationBoundary,
  GlassCalibrationResult,
  GlassesTransform,
  GlassesTransformShare,
  HandWritingLine,
  ImageCursor,
  ImageDataWithColor,
  ImageDataWithMessageType,
} from "../utils/types";

// #endregion

// #region --- Draw Management ---
// HACK: 全体的にファイルスコープの let を削減する方向で修正する。(const はOK)。

export const canvasHistories: ImageDataWithMessageType[] = [];
export const defaultDrawType: DrawType = "clickToStart";

// export let DrawStart = false;
// export const setDrawStart = (value: boolean): void => {
//   DrawStart = value;
// };

export let currentDrawType: DrawType = defaultDrawType;
export const setCurrentDrawType = (value: DrawType): void => {
  currentDrawType = value;
};
export let lastTimeStamp: number = null;
export const setlastTimeStamp = (value: number): void => {
  lastTimeStamp = value;
};

export const throttleTime = 40;
export const drawWithThrottle = (ts: number): boolean => {
  // 間引き
  if (ts < lastTimeStamp + throttleTime) {
    return false;
  } else {
    lastTimeStamp = ts;
    return true;
  }
};

export let isClickForPlacementHandWriting = false;
export const setIsClickForPlacementHandWriting = (value: boolean): void => {
  isClickForPlacementHandWriting = value;
};

export const drawingHistories: HandWritingLine[] = [];
export const addDrawingHistories = (value: HandWritingLine): void => {
  drawingHistories.push(value);
};

export const handWritingImages: ImageDataWithColor[] = [];
export const addHandWritingImage = (value: ImageDataWithColor): void => {
  handWritingImages.push(value);
};

export const generateLiveHandwritingImage = (color: string): void => {
  const bufferCanvas = document.createElement("canvas");
  const ctx = bufferCanvas.getContext("2d");
  bufferCanvas.width = HANDWRITING_CANVAS.WIDTH;
  bufferCanvas.height = HANDWRITING_CANVAS.HEIGHT;

  ctx.strokeStyle = color;
  for (const line of drawingHistories) {
    ctx.beginPath();
    ctx.moveTo(line.x1, line.y1);
    ctx.lineTo(line.x2, line.y2);
    ctx.lineCap = "round";
    ctx.lineWidth = line.width;
    ctx.stroke();
  }
  const imageData = ctx.getImageData(0, 0, bufferCanvas.width, bufferCanvas.height) as ImageDataWithColor;
  imageData.color = color;
  addHandWritingImage(imageData);
};

export const createLiveHandWritingImage = (width: number, color: string): HTMLImageElement => {
  let handWritingImage: ImageDataWithColor = null;
  for (const image of handWritingImages) {
    if (color.toUpperCase() === image.color) {
      handWritingImage = image;
    }
  }
  if (handWritingImage === null) {
    return;
  }

  const bufferCanvas = document.createElement("canvas");
  const bufferCtx = bufferCanvas.getContext("2d");
  bufferCanvas.width = handWritingImage.width;
  bufferCanvas.height = handWritingImage.height;
  bufferCtx.fillStyle = color;
  bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);
  bufferCtx.putImageData(handWritingImage, 0, 0);

  // NOTE:手描きの筆サイズとは別のサイズ設定値『画像のサイズ』を縮小するために、別キャンバスに描写
  const resizeRate = width / HANDWRITING_CANVAS.LiveHandWritingImage_SCALE;
  const w = Math.trunc(handWritingImage.width * resizeRate);
  const h = Math.trunc(handWritingImage.height * resizeRate);

  const liveHandWritingCanvas = document.getElementById("buffer_live_handWriting_image") as HTMLCanvasElement;
  const liveHandWritingCtx = liveHandWritingCanvas.getContext("2d");
  liveHandWritingCtx.clearRect(0, 0, liveHandWritingCanvas.width, liveHandWritingCanvas.height);
  // liveHandWritingCtx.drawImage(bufferCanvas, 0, 0, w, h);
  liveHandWritingCtx.drawImage(bufferCanvas, 0, 0, liveHandWritingCanvas.width, liveHandWritingCanvas.height, 0, 0, w, h);

  const img = new Image();
  const dataURL = liveHandWritingCanvas.toDataURL();
  img.src = dataURL;

  return img;
};

// HACK: 後ほど、他の変数と一緒にReduxストアに移動する予定。
export const glassCalibrationResult: GlassCalibrationResult = {
  // 新仕様 デフォルト値
  // Left
  topLeft_L_X: 0.25,
  topLeft_L_Y: 0.25,
  topRight_L_X: 0.75,
  topRight_L_Y: 0.25,
  bottomRight_L_X: 0.75,
  bottomRight_L_Y: 0.75,
  bottomLeft_L_X: 0.25,
  bottomLeft_L_Y: 0.75,
  // Right
  topLeft_R_X: 0.25,
  topLeft_R_Y: 0.25,
  topRight_R_X: 0.75,
  topRight_R_Y: 0.25,
  bottomRight_R_X: 0.75,
  bottomRight_R_Y: 0.75,
  bottomLeft_R_X: 0.25,
  bottomLeft_R_Y: 0.75,
  /* 旧仕様
  topLeftX: 0.166015625,
  topLeftY: 0.30125,
  bottomRightX: 0.600390625,
  bottomRightY: 0.654375,
  */
  glassType: GLASS_TYPE.UNKNOWN,
  displayType: DISPLAY_TYPE.UNKNOWN,
  // normalized center pos @camera of display L/R
  D_center_L_X: 0.5,
  D_center_L_Y: 0.5,
  D_center_R_X: 0.5,
  D_center_R_Y: 0.5,
};

export const isInDrawBoundaryRange = (x: number, y: number): boolean => {
  // NOTE:必要であれば、下記も判定に追加した方が良いかもしれない。
  // let topRightY: number, bottomRightX: number, bottomRightY: number, bottomLeftX: number;
  const { topLeftX, topLeftY, topRightX, bottomLeftY } = canvasCalibrationBoundary();

  // NOTE:範囲外の判定を手描きキャンバスの大きさも考慮して判定する場合は下記を追加
  // const scale = width / HANDWRITING_CANVAS.LiveHandWritingImage_SCALE;
  // const w = Math.trunc(HANDWRITING_CANVAS.WIDTH * scale);
  // const h = Math.trunc(HANDWRITING_CANVAS.HEIGHT * scale);
  // const x0 = Math.trunc(x - w / 4);
  // const x1 = Math.trunc(x + w / 4);
  // const y0 = Math.trunc(y - h / 4);
  // const y1 = Math.trunc(y + h / 4);
  // if (x0 >= topLeftX && x1 <= topRightX && y0 >= topLeftY && y1 <= bottomLeftY) {

  if (x >= topLeftX && x <= topRightX && y >= topLeftY && y <= bottomLeftY) {
    return true;
  } else {
    return false;
  }
};

export const canvasCalibrationBoundary = (): GlassCalibrationBoundary => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const { width, height } = canvas;
  const { displayType } = glassCalibrationResult;

  let topLeftX: number,
    topLeftY: number,
    topRightX: number,
    topRightY: number,
    bottomRightX: number,
    bottomRightY: number,
    bottomLeftX: number,
    bottomLeftY: number;

  if (displayType === DISPLAY_TYPE.LEFTONLY) {
    topLeftX = glassCalibrationResult.topLeft_L_X * width;
    topLeftY = glassCalibrationResult.topLeft_L_Y * height;
    topRightX = glassCalibrationResult.topRight_L_X * width;
    topRightY = glassCalibrationResult.topRight_L_Y * height;
    bottomRightX = glassCalibrationResult.bottomRight_L_X * width;
    bottomRightY = glassCalibrationResult.bottomRight_L_Y * height;
    bottomLeftX = glassCalibrationResult.bottomLeft_L_X * width;
    bottomLeftY = glassCalibrationResult.bottomLeft_L_Y * height;
  } else if (displayType === DISPLAY_TYPE.RIGHTONLY) {
    topLeftX = glassCalibrationResult.topLeft_R_X * width;
    topLeftY = glassCalibrationResult.topLeft_R_Y * height;
    topRightX = glassCalibrationResult.topRight_R_X * width;
    topRightY = glassCalibrationResult.topRight_R_Y * height;
    bottomRightX = glassCalibrationResult.bottomRight_R_X * width;
    bottomRightY = glassCalibrationResult.bottomRight_R_Y * height;
    bottomLeftX = glassCalibrationResult.bottomLeft_R_X * width;
    bottomLeftY = glassCalibrationResult.bottomLeft_R_Y * height;
  } else {
    // NOTE:両目用.ないとは思うが、"LeftOnly","RightOnly","BOTH",以外の値が来た時用にelseで拾っておいた方が良い？
    topLeftX = Math.min(glassCalibrationResult.topLeft_L_X, glassCalibrationResult.topLeft_R_X) * width;
    topLeftY = Math.min(glassCalibrationResult.topLeft_L_Y, glassCalibrationResult.topLeft_R_Y) * height;
    topRightX = Math.max(glassCalibrationResult.topRight_L_X, glassCalibrationResult.topRight_R_X) * width;
    topRightY = Math.min(glassCalibrationResult.topRight_L_Y, glassCalibrationResult.topRight_R_Y) * height;
    bottomRightX = Math.max(glassCalibrationResult.bottomRight_L_X, glassCalibrationResult.bottomRight_R_X) * width;
    bottomRightY = Math.max(glassCalibrationResult.bottomRight_L_Y, glassCalibrationResult.bottomRight_R_Y) * height;
    bottomLeftX = Math.min(glassCalibrationResult.bottomLeft_L_X, glassCalibrationResult.bottomLeft_R_X) * width;
    bottomLeftY = Math.max(glassCalibrationResult.bottomLeft_L_Y, glassCalibrationResult.bottomLeft_R_Y) * height;
  }

  return {
    topLeftX,
    topLeftY,
    topRightX,
    topRightY,
    bottomRightX,
    bottomRightY,
    bottomLeftX,
    bottomLeftY,
  };
};

export const handlePlacementHandWriting = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  width: number,
  color: string,
  myRoomId: string,
  myFrontendId: string
): void => {
  if (isInDrawBoundaryRange(x, y)) {
    setIsClickForPlacementHandWriting(!isClickForPlacementHandWriting);

    if (isClickForPlacementHandWriting) {
      DrawPlacementHandWriting(sendMessage, x, y, width, color, myRoomId);
    }
  } else {
    setIsClickForPlacementHandWriting(false);
  }

  if (!isClickForPlacementHandWriting) {
    DrawLiveHandWriting(sendMessage, x, y, width, color, myRoomId, myFrontendId);
  }
};

// #endregion

// #region --- 3DoF Tracking Functions ---
// NOTE: 3DoFトラッキング関連は、処理実装後、canvasActions.ts外に書き直した方が良さそう。（何時対応するかは悩み中..）
// interface GlassesTransform {
//   transform: {
//     position: { x: number; y: number; z: number };
//     rotation: { w: number; x: number; y: number; z: number };
//   };
// }

export let currentTransformShareWidth: number = CANVAS_CIRCLE_SIZE.DEFAULT;
export let currentTransformShareColor: string = CANVAS_PEN_COLOR.DEFAULT;
export let currentTransformShareDrawStart = false;

// NOTE: 移動元の glassesTransform0 は値が更新されていなかったので、一旦定義だけ
export const glassesTransform0: GlassesTransform = {
  transform: {
    position: { x: 0.0, y: 0.0, z: 0.0 },
    rotation: {
      w: 1.0,
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
  },
};

export const GlassesTransformShare0: GlassesTransformShare = {
  rotation: {
    w: 1.0,
    x: 0.0,
    y: 0.0,
    z: 0.0,
  },
};

export const setCurrentTransformShareWidth = (value: number): void => {
  currentTransformShareWidth = value;
};

export const setCurrentTransformShareColor = (value: string): void => {
  currentTransformShareColor = value;
};

export const setCurrentTransformShareDrawStart = (value: boolean): void => {
  currentTransformShareDrawStart = value;
};

const PI = Math.PI;
const quat_imu_to_cam = new Quaternion(); // IMUからCAMERAへの変換（回転）
quat_imu_to_cam.setFromEuler(new Euler(-PI / 2, -PI / 2, PI, "ZXY"));
//quat_imu_to_cam.setFromEuler(new Euler(-PI/2, -PI/2, PI, 'XYZ'));
let quat_click = new Quaternion(0, 0, 0, 1); // クリック時の姿勢
let pt_click = [0, 0]; // クリック時のスクリーン座標

// クリック時の位置・姿勢を保存＋android側に送信
export const SetCurrentPose = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  e: React.PointerEvent<HTMLCanvasElement>,
  myRoomId: string,
  myFrontendId: string
): void => {
  const rot = GlassesTransformShare0.rotation;
  quat_click = new Quaternion(rot.x, rot.y, rot.z, rot.w);
  quat_click.multiply(quat_imu_to_cam);
  pt_click = [x, y];

  DrawArrow(sendMessage, x, y, e, currentTransformShareWidth, currentTransformShareColor, myRoomId, myFrontendId);
};
// #endregion

// #region --- 3DoF Draw Functions ---
let lastTimeStampArrow = 0;

export const DrawLiveArrow = (sendMessage: (Message) => void, myRoomId: string, myFrontendId: string): void => {
  const ts: number = new Date().getTime();
  const loopWait = 30; //40;
  if (ts < lastTimeStampArrow + loopWait) {
    // 間引き
    return;
  } else {
    lastTimeStampArrow = ts;
  }
  // if (!DrawStart) {
  // マウスクリックで開始させる
  //drawTextCursor(sendMessage, x, y, e, "#ff0");
  // return;
  // }
  const timestamp = new Date().getTime();
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  //diff_q = (q_imu_to_cam * imu.quat) * q_click.inverse()
  // update x,y by imu rotation
  const DIST = 500.0; // Z（奥行）方向の設定距離（適当）
  const rot = GlassesTransformShare0.rotation;
  const q1 = new Quaternion(rot.x, rot.y, rot.z, rot.w);
  const q2 = new Quaternion(0, 0, 0, 1);
  q2.copy(quat_click);
  const q3 = new Quaternion(0, 0, 0, 1);
  q3.copy(quat_imu_to_cam);
  q2.invert();
  q1.multiply(q3);
  q1.multiply(q2);
  const diff_q = new Quaternion(0, 0, 0, 1);
  diff_q.copy(q1);

  const pv = new Vector3(pt_click[0] - canvas.width / 2, pt_click[1] - canvas.height / 2, DIST);
  //pv = quaternion.rotate_vectors(diff_q, pv0, axis=-1)
  const q4 = new Quaternion(0, 0, 0, 1);
  q4.set(-diff_q.x, diff_q.y, -diff_q.z, diff_q.w); // L<->R sys.
  //q4 = diff_q;
  pv.applyQuaternion(q4); // ベクトルpvをq4で回転させる
  //console.log("z>>",pv);
  if (pv.z > 0.001) {
    newClearCanvas();

    const fct = DIST / pv.z;
    const x = pv.x * fct + canvas.width / 2; // z=DIST平面との交点のx,y座標
    const y = pv.y * fct + canvas.height / 2;

    // console.log(`xy>> ${x},${y}`); // @screen coords.

    const ctx = canvas.getContext("2d");
    const scale = currentTransformShareWidth;
    ctx.fillStyle = currentTransformShareColor; //"#fccb00cc";
    ctx.beginPath();
    ctx.translate(x, y);
    ctx.scale(scale - 10, scale - 10);
    const w = 1;
    const w2 = 2;
    const l = 4;
    ctx.moveTo(0, 0);
    ctx.lineTo(w2, -w2);
    ctx.lineTo(w, -w2);
    ctx.lineTo(w, -l);
    ctx.lineTo(-w, -l);
    ctx.lineTo(-w, -w2);
    ctx.lineTo(-w2, -w2);
    ctx.lineTo(0, 0);
    ctx.fill();
    ctx.setTransform(1, 0, 0, 1, 0, 0); // reset transform
    restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");

    //buffer
    const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
    const bufferCtx = bufferCanvas.getContext("2d");
    bufferCtx.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);
    drawArrowIcon(bufferCtx, currentTransformShareColor, scale, x, y);
    // HACK:矢印と矢印２は共存しないので、矢印、矢印２を同じ"DrawArrow"として一旦バッファーに保存。（排他的に管理。）
    saveCanvasHistory("DrawArrow", timestamp);

    // sendMessage({
    //   messageType: MESSAGE_TYPE.DELETE_ALL,
    //   roomId: myRoomId,
    //   frontendId: myFrontendId,
    // });
    // sendMessage({
    //   messageType: MESSAGE_TYPE.ARROW,
    //   roomId: myRoomId,
    //   point: { x: x / canvas.width, y: y / canvas.height },
    //   width: scale,
    //   color: currentTransformShareColor,
    //   timestamp: timestamp,
    //   transform: glassesTransform0?.transform,
    //   objectId: timestamp,
    //   frontendId: myFrontendId,
    // });
  }
};

export const DrawLiveCircle = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  // e: React.PointerEvent<HTMLCanvasElement>,
  width: number,
  color: string,
  myRoomId: string,
  myFrontendId: string
): void => {
  //if (e != null && e.button !== 0) { return; }
  const currentTimestamp: number = new Date().getTime();
  if (!drawWithThrottle(currentTimestamp)) return;

  // clearCanvas(sendMessage, myRoomId, myFrontendId);
  newClearCanvas();
  clearScreenByDrawTarget(sendMessage, myRoomId, "DrawLiveCircle", "DrawArrow");

  // if (!DrawStart) {
  // マウスクリックで開始させる
  // drawTextCursor(sendMessage, x, y, e, color);
  // return;
  // }

  restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  const radius = width - 5;
  const lineWidth = radius / 5;
  drawCircleIcon(ctx, color, radius, lineWidth, x, y);

  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
  ctx.stroke();

  //buffer
  const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
  const bufferCtx = bufferCanvas.getContext("2d");
  bufferCtx.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);
  drawCircleIcon(bufferCtx, color, radius, lineWidth, x, y);

  saveCanvasHistory("DrawLiveCircle", currentTimestamp);

  sendMessage({
    messageType: MESSAGE_TYPE.CIRCLE,
    roomId: myRoomId,
    point: { x: x / canvas.width, y: y / canvas.height },
    // 刹那の微調整
    //point: { x: x / canvas.width + 0.02 ,
    //        y: y / canvas.height  + 0.026}, //cindy 0.023 },
    // calib proc:
    // centerPos@Disp-L/R -> d2c_L/R -> pos_L/R@cam - curs_L/R => offset_L/R
    radius: radius / canvas.height,
    width: lineWidth,
    color: color,
    timestamp: currentTimestamp,
    transform: glassesTransform0?.transform,
    objectId: currentTimestamp,
    frontendId: myFrontendId,
  });
};

const drawCircleIcon = (
  ctx: CanvasRenderingContext2D,
  color: string,
  radius: number,
  lineWidth: number,
  x: number,
  y: number
) => {
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
  ctx.stroke();
};

export const DrawLiveHandWriting = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  width: number,
  color: string,
  myRoomId: string,
  myFrontendId: string
): void => {
  deleteCanvasHistoryByDrawTarget("DrawHandWriting");

  const currentTimestamp: number = new Date().getTime();
  if (!drawWithThrottle(currentTimestamp)) return;

  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  // clearCanvas(sendMessage, myRoomId, myFrontendId);
  newClearCanvas();
  clearScreenByDrawTarget(sendMessage, myRoomId, "DrawLiveHandWriting");

  restoreBufferCanvasFromHistory("DrawInputText", "DrawArrow");

  drawHandWritingImage(x, y, width);

  saveCanvasHistory("DrawLiveHandWriting", currentTimestamp);

  sendMessage({
    messageType: MESSAGE_TYPE.TRACKINGOVERLAYIMAGE,
    roomId: myRoomId,
    point: { x: x / canvas.width, y: y / canvas.height },
    timestamp: currentTimestamp,
    objectId: currentTimestamp,
    frontendId: myFrontendId,
  });
};

// #endregion

// #region --- Draw Functions ---
export const DrawArrow = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  e: React.PointerEvent<HTMLCanvasElement>,
  width: number,
  color: string,
  myRoomId: string,
  myFrontendId: string
): void => {
  newClearCanvas();
  clearScreenByDrawTarget(sendMessage, myRoomId, "DrawArrow");
  setCurrentTransformShareDrawStart(false); //矢印2の描写OFF

  if (e.button !== 0) {
    return;
  }
  const timestamp = new Date().getTime();
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  const scale = width / 2;
  const arrowWidth = drawArrowIcon(ctx, color, scale, x, y);
  restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");

  //buffer
  const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
  const bufferCtx = bufferCanvas.getContext("2d");
  bufferCtx.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);
  drawArrowIcon(bufferCtx, color, scale, x, y);
  saveCanvasHistory("DrawArrow", timestamp);

  sendMessage({
    messageType: MESSAGE_TYPE.ARROW,
    roomId: myRoomId,
    point: { x: x / canvas.width, y: y / canvas.height },
    width: arrowWidth,
    color: color,
    scale: scale,
    timestamp: timestamp,
    transform: glassesTransform0?.transform,
    objectId: timestamp,
    frontendId: myFrontendId,
  });
};

const drawArrowIcon = (ctx: CanvasRenderingContext2D, color: string, scale: number, x: number, y: number) => {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.translate(x, y);
  ctx.scale(scale, scale);
  const w = 1;
  const w2 = 2;
  const l = 4;
  ctx.moveTo(0, 0);
  ctx.lineTo(w2, -w2);
  ctx.lineTo(w, -w2);
  ctx.lineTo(w, -l);
  ctx.lineTo(-w, -l);
  ctx.lineTo(-w, -w2);
  ctx.lineTo(-w2, -w2);
  ctx.lineTo(0, 0);
  ctx.fill();
  ctx.setTransform(1, 0, 0, 1, 0, 0); // reset transform

  const arrowWidth = w2 * 2; // 矢印の幅
  return arrowWidth;
};

export const DrawImage = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  e: React.PointerEvent<HTMLCanvasElement>,
  width: number,
  color: string,
  myRoomId: string,
  myFrontendId: string,
  currentImageCursor: ImageCursor
): void => {
  const currentTimestamp: number = new Date().getTime();
  if (!drawWithThrottle(currentTimestamp)) return;

  // clearCanvas(sendMessage, myRoomId, myFrontendId);
  newClearCanvas();
  clearScreenByDrawTarget(sendMessage, myRoomId, "DrawImage", "DrawArrow");
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");

  // if (!DrawStart) {
  // マウスクリックで開始させる
  // drawTextCursor(sendMessage, x, y, e, color);
  // return;
  // }

  restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");

  // load image
  const img = new Image();
  const cur = currentImageCursor;
  img.src = cur.src;
  const scale = width / 30.0; // from Slider
  const w = Math.trunc(img.width * scale); // to Int
  const h = Math.trunc(img.height * scale);
  const x0 = Math.trunc(x - cur.origin[0] * scale);
  const y0 = Math.trunc(y - cur.origin[1] * scale);
  // originがカーソル位置になるようにシフト+スケールしてimgを描画
  //ctx.globalCompositeOperation = 'source-over';
  ctx.globalAlpha = 0.8;
  ctx.drawImage(img, x0, y0, w, h);
  //console.log("scale = "+scale);

  //buffer
  const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
  const bufferCtx = bufferCanvas.getContext("2d");
  bufferCtx.globalAlpha = 0.8;
  bufferCtx.drawImage(img, x0, y0, w, h);

  saveCanvasHistory("DrawImage", currentTimestamp);

  sendMessage({
    messageType: MESSAGE_TYPE.IMAGE,
    imageType: cur.imgType,
    roomId: myRoomId,
    point: { x: x / canvas.width, y: y / canvas.height },
    showPosition: { x: cur.origin[0], y: cur.origin[1] },
    scale: scale,
    //color: color,
    timestamp: new Date().getTime(),
    transform: glassesTransform0?.transform,
    objectId: currentTimestamp,
    frontendId: myFrontendId,
  });
};

export const drawTextCursor = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  e: React.PointerEvent<HTMLCanvasElement>,
  color: string
): void => {
  newClearCanvas();
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  ctx.font = "15pt Arial";
  //ctx.lineWidth = 2;
  ctx.fillText("Click to start", x, y);
  ctx.fillStyle = color;
  //ctx.strokeText('Click to start', x,y); // , canvas.width);
  //ctx.strokeStyle = color;
  ctx.textAlign = "center";
  ctx.textBaseline = "bottom";
};

export const DrawInputText = (
  sendMessage: (Message) => void,
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  width: number,
  color: string,
  inputText: string,
  myRoomId: string,
  myFrontendId: string
): void => {
  newClearCanvas();
  deleteCanvasHistoryByDrawTarget("DrawInputText");
  inputText = e.target.value;

  const timestamp = new Date().getTime();
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  const sizeAdjustMent = width + 5 + width / 2;
  const bottomLeftX =
    Math.min(glassCalibrationResult.bottomLeft_L_X, glassCalibrationResult.bottomLeft_R_X) * canvas.width + sizeAdjustMent;
  const bottomRightX =
    Math.max(glassCalibrationResult.bottomRight_L_X, glassCalibrationResult.bottomRight_R_X) * canvas.width - sizeAdjustMent;
  const maxWidth = bottomRightX - bottomLeftX;
  const bottomLeftY =
    Math.max(glassCalibrationResult.bottomLeft_L_Y, glassCalibrationResult.bottomLeft_R_Y) * canvas.height - sizeAdjustMent;
  const drawnText = drawTextWithLineBreaks(ctx, inputText, color, sizeAdjustMent, maxWidth, bottomLeftX, bottomLeftY);
  restoreBufferCanvasFromHistory("DrawHandWriting", "DrawArrow");

  //buffer
  const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
  const bufferCtx = bufferCanvas.getContext("2d");
  bufferCtx.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);
  drawTextWithLineBreaks(bufferCtx, inputText, color, sizeAdjustMent, maxWidth, bottomLeftX, bottomLeftY);
  saveCanvasHistory("DrawInputText", timestamp);

  sendMessage({
    messageType: MESSAGE_TYPE.TEXT_MESSAGE,
    roomId: myRoomId,
    textMessage: drawnText,
    // scale: sizeAdjustMent / 10,
    width: sizeAdjustMent,
    timestamp: timestamp,
    objectId: timestamp,
    frontendId: myFrontendId,
  });
};

const drawTextWithLineBreaks = (
  ctx: CanvasRenderingContext2D,
  text: string,
  color: string,
  fontSize: number,
  maxWidth: number,
  x: number,
  y: number
): string => {
  ctx.font = `${fontSize}px Arial`;
  ctx.textAlign = "left";
  ctx.textBaseline = "top";
  ctx.fillStyle = color;
  ctx.strokeStyle = color;

  let currentLine = "";
  const lines: string[] = [];
  for (const character of text) {
    if (character === "\n") {
      lines.push(currentLine.trim());
      currentLine = "";
      continue;
    }

    const lineLength = ctx.measureText(convertFullWidthToHalfWidth(currentLine + character)).width;
    if (lineLength > maxWidth) {
      lines.push(currentLine.trim());
      currentLine = "";
    }

    currentLine += convertFullWidthToHalfWidth(character);
  }
  if (currentLine) lines.push(currentLine.trim());

  const totalHeight = y - lines.length * fontSize;
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    ctx.fillText(line, x, totalHeight + i * fontSize);
  }

  let drawnText = "";
  drawnText = lines.join("\n");
  return drawnText;
};

export const DrawPlacementHandWriting = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  width: number,
  color: string,
  myRoomId: string
): void => {
  deleteCanvasHistoryByDrawTarget("DrawHandWriting", "DrawLiveHandWriting");

  newClearCanvas();
  // clearScreenByDrawTarget(sendMessage, myRoomId, "DrawLiveHandWriting");

  const currentTimestamp: number = new Date().getTime();
  restoreBufferCanvasFromHistory("DrawInputText", "DrawArrow");

  drawHandWritingImage(x, y, width);

  saveCanvasHistory("DrawHandWriting", currentTimestamp);
};

const drawHandWritingImage = (x: number, y: number, width: number): void => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");

  const scale = width / HANDWRITING_CANVAS.LiveHandWritingImage_SCALE;
  const w = Math.trunc(HANDWRITING_CANVAS.WIDTH * scale);
  const h = Math.trunc(HANDWRITING_CANVAS.HEIGHT * scale);
  const x0 = Math.trunc(x - w / 4);
  const y0 = Math.trunc(y - h / 4);

  const liveHandWritingCanvas = document.getElementById("buffer_live_handWriting_image") as HTMLCanvasElement;
  ctx.drawImage(liveHandWritingCanvas, x0, y0, w, h);

  //buffer
  const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
  const bufferCtx = bufferCanvas.getContext("2d");
  bufferCtx.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);
  bufferCtx.drawImage(liveHandWritingCanvas, x0, y0, w, h);
};

export const DrawCalibCursor = (
  sendMessage: (Message) => void,
  x: number,
  y: number,
  e: React.PointerEvent<HTMLCanvasElement>,
  width: number,
  color: string,
  myRoomId: string,
  myFrontendId: string
): void => {
  canvasHistories.splice(0, canvasHistories.length);

  const currentTimestamp: number = new Date().getTime();
  if (!drawWithThrottle(currentTimestamp)) return;
  newClearCanvas();
  clearScreenAll(sendMessage, myRoomId, myFrontendId);

  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");

  ctx.strokeStyle = "red";
  ctx.beginPath();
  ctx.translate(x, y);
  ctx.scale(2, 2);
  ctx.moveTo(0, 0);
  ctx.lineTo(5, 0);
  ctx.lineTo(-5, 0);
  ctx.moveTo(0, 0);
  ctx.lineTo(0, 5);
  ctx.lineTo(0, -5);
  ctx.setTransform(1, 0, 0, 1, 0, 0);

  ctx.stroke();
};

// キャリブレーション表示枠(左右をマージ，L+R->矩形化)
export const drawBoundaryLR = (): void => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");

  const { topLeftX, topLeftY, topRightX, topRightY, bottomRightX, bottomRightY, bottomLeftX, bottomLeftY } =
    canvasCalibrationBoundary();

  //ctx.strokeStyle = "red";
  ctx.strokeStyle = "#ff000077"; // RGBA
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(topLeftX, topLeftY);
  ctx.lineTo(topRightX, topRightY);
  ctx.lineTo(bottomRightX, bottomRightY);
  ctx.lineTo(bottomLeftX, bottomLeftY);
  ctx.lineTo(topLeftX, topLeftY);
  ctx.stroke();
};

// NOTE:キャリブレーション枠を履歴に保存しないよう、bufferキャンバスで履歴管理
export const saveCanvasHistory = (drawTarget: DrawTarget, objectId: number): void => {
  const canvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height) as ImageDataWithMessageType;
  imageData.drawTarget = drawTarget;
  imageData.objectId = objectId;
  canvasHistories.push(imageData);
};

export const deleteCanvasHistoryByDrawTarget = (...drawTarget: DrawTarget[]): void => {
  if (!canvasHistories.length) return;

  for (const target of drawTarget) {
    if (!canvasHistories.some((history) => history.drawTarget === target)) continue;

    for (let i = canvasHistories.length - 1; i >= 0; i--) {
      if (canvasHistories[i].drawTarget === target) canvasHistories.splice(i, 1);
      // console.log(canvasHistories);
    }
  }
};

// NOTE:バッファーキャンバスを元に、キャンバスの特定の描画ターゲットの状態を復元する
export const restoreBufferCanvasFromHistory = (...drawTarget: DrawTarget[]): void => {
  if (!canvasHistories.length) return;

  const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
  const bufferCtx = bufferCanvas.getContext("2d");
  for (const target of drawTarget.reverse()) {
    if (!canvasHistories.some((history) => history.drawTarget === target)) continue;
    bufferCtx.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);

    canvasHistories
      .filter((canvasHistory) => target === canvasHistory.drawTarget)
      .reverse()
      .forEach((canvasHistory) => {
        const prevImageData = canvasHistory;
        bufferCtx.putImageData(prevImageData, 0, 0);
      });

    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(bufferCanvas, 0, 0);
  }
};

// NOTE:すでに実装している関数 clearCanvas() 内で deleteAll を送るのは処理として別物なので、よろしくなさそう。
// 　　 clearCanvas() を呼び出す部分で sendMessage()を別で定義するため、deleteAllを送らない newClearCanvas()を一時的に定義
// TODO:すでに実装している関数 clearCanvas() を呼び出す部分で sendMessage()を別で定義するように修正
export const newClearCanvas = () => {
  const canvas = document.getElementById("canvas") as HTMLCanvasElement;
  if (!canvas) return;

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  drawBoundaryLR();
};

export const clearDrawStroke = (sendMessage: (Message) => void, myRoomId: string, myFrontendId: string): void => {
  if (
    currentDrawType === "arrow" ||
    currentDrawType === "liveArrow" ||
    currentDrawType === "textMessage" ||
    currentDrawType === "handWriting" ||
    currentDrawType === "liveHandWritingImage"
  )
    return;
  newClearCanvas();
  clearScreenByDrawTarget(sendMessage, myRoomId, "DrawLiveCircle", "DrawImage", "DrawLiveHandWriting");

  deleteCanvasHistoryByDrawTarget("DrawArrow");
  restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");
};

export const clearDrawClick = (sendMessage: (Message) => void, myRoomId: string, myFrontendId: string): void => {
  newClearCanvas();
  clearScreenByDrawTarget(sendMessage, myRoomId, "DrawArrow");

  setCurrentTransformShareDrawStart(false);
  //NOTE: deleteArrow は複数フロントエンドの共存を想定しているため、制御用の frontendId を指定
  // sendMessage({
  //   messageType: MESSAGE_TYPE.DELETE_ARROW,
  //   roomId: myRoomId,
  //   timestamp: new Date().getTime(),
  //   frontendId: myFrontendId,
  // });

  restoreBufferCanvasFromHistory("DrawInputText", "DrawHandWriting");
};

export const clearInputText = (sendMessage: (Message) => void, myRoomId: string, myFrontendId: string): void => {
  newClearCanvas();
  deleteCanvasHistoryByDrawTarget("DrawInputText");

  sendMessage({
    messageType: MESSAGE_TYPE.DELETE_TEXT_MESSAGE,
    roomId: myRoomId,
    timestamp: new Date().getTime(),
  });

  restoreBufferCanvasFromHistory("DrawArrow", "DrawHandWriting");
};

export const clearHandWriting = (sendMessage: (Message) => void, myRoomId: string, myFrontendId: string): void => {
  newClearCanvas();
  deleteCanvasHistoryByDrawTarget("DrawHandWriting", "DrawLiveHandWriting");

  sendMessage({
    messageType: MESSAGE_TYPE.CLEAROVERLAYIMAGE,
    roomId: myRoomId,
    timestamp: new Date().getTime(),
  });

  restoreBufferCanvasFromHistory("DrawArrow", "DrawInputText");
};

/**
 * グラスに描写しているアノテーションを削除します。
 * @param sendMessage WebSocketを介してサーバーにメッセージを送信するための関数
 * @param myRoomId 削除対象のルームID
 * @param myFrontendId 削除対象のフロントエンドID
 * @param deleteAll 存在する場合はすべてのアノテーションを削除することを示すオプションの文字列引数
 * WARNING:	deleteAllがFalseの場合は、グラス側の "手描き", "テキストメッセージ" を削除しません。
 * NOTE:当初はアノテーションを同時に表示しない仕様だったので、アノテーションをすべて削除するclearCanvas()を至るところで使用する実装をしていた。
 * そこから、アノテーションを同時に表示する仕様変更が発生。
 * その仕様変更について、仮実装で検証する段階であるため、実装後、再度仕様が変更する可能性がある。
 * さらに実装期限も設けられているので、既存の処理に影響が少ないかつ工数が少ない方法として、clearCanvasを使用した処理で実装。
 */
export const clearScreenAll = (
  sendMessage: (Message) => void,
  myRoomId: string,
  myFrontendId: string,
  deleteAll = false
): void => {
  sendMessage({
    messageType: MESSAGE_TYPE.DELETE_ALL,
    roomId: myRoomId,
    frontendId: myFrontendId,
    deleteAll,
  });
};

// WARNING:Android側の仕様においては、手描き画像とテキストメッセージに objectId がない。
// WARNING:そして、手描きイメージは同時に1つだけ存在(新しい手描きイメージが送られると前の手描きイメージは捨てられる)する仕様。
// WARNING:新たに追加したアノテーション「手描き追従」は、削除、再描写を一度に行うため、clearScreenByDrawTarget() で削除する必要がない。（手描きイメージの削除ではなく移動の場合はandroid側への削除指示が不要）
export const clearScreenByDrawTarget = (sendMessage: (Message) => void, myRoomId: string, ...drawTarget: DrawTarget[]): void => {
  if (!canvasHistories.length) return;

  for (const target of drawTarget.reverse()) {
    if (!canvasHistories.some((history) => history.drawTarget === target)) continue;

    for (let i = canvasHistories.length - 1; i >= 0; i--) {
      const canvasHistory = canvasHistories[i];

      if (target === canvasHistory.drawTarget) {
        if (target === "DrawHandWriting") {
          sendMessage({
            messageType: MESSAGE_TYPE.CLEAROVERLAYIMAGE,
            roomId: myRoomId,
            timestamp: new Date().getTime(),
          });
        } else {
          const objectId = canvasHistory.objectId;
          sendMessage({
            messageType: MESSAGE_TYPE.DELETE_BY_ID,
            roomId: myRoomId,
            objectId: objectId,
          });
        }
        canvasHistories.splice(i, 1);
      }
    }
  }
};
// #endregion
