// Responsibility: 遅延アラートを表示する
import React, { memo } from "react";
import { Alert, Stack, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import { asyncReconnect } from "../redux/asyncActionCreators/connection";
import { useParams } from "react-router-dom";

import i18n from "../../locales/i18n";
import { LocalConnectOption } from "../utils/types";

import CustomButton from "../atoms/customButton";

interface AlertProps {
  enableLatencyHandling: boolean;
  showLatencyAlert: boolean;
  connectOption: LocalConnectOption;
  onClose: (isCloseButtonClicked: boolean) => void;
}

const AlertLatency: React.FC<AlertProps> = memo(({ enableLatencyHandling, showLatencyAlert, connectOption, onClose }) => {
  const dispatch = useDispatch();

  const { roomId } = useParams<{ roomId: string }>();
  sessionStorage.setItem("myRoomId", roomId);

  const handleClick = async (): Promise<void> => {
    onClose(false);

    await dispatch(
      asyncReconnect({
        connectOption,
        roomId,
      })
    );
  };
  return (
    <>
      {enableLatencyHandling && showLatencyAlert && (
        <Stack
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            padding: "0",
            zIndex: 2,
          }}
          spacing={2}
        >
          <Alert
            icon={false}
            sx={{
              color: "#e0f2f1",
              backgroundColor: "#424242",
              // NOTE: 何故か"MuiAlert-action css-ki1hdl-MuiAlert-action"にmargin-right:-8px,padding:4px 0 0 16pxが適用されている。
              // どこで適用しているのかわからないので、余白を調整するため、下記の指定でオーバーライド
              "& .MuiAlert-action": {
                marginRight: "1rem",
                padding: "0",
              },
            }}
            action={
              <Stack direction="row" spacing={3} alignItems="center">
                <CustomButton color="secondary" size="small" onClick={handleClick}>
                  {i18n.t("AlertLatency.connect")}
                </CustomButton>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    onClose(true);
                  }}
                >
                  <Clear fontSize="medium" />
                </IconButton>
              </Stack>
            }
          >
            {i18n.t("AlertLatency.content")}
          </Alert>
        </Stack>
      )}
    </>
  );
});

export default AlertLatency;
