import { ImageCursor } from "../../scripts/utils/types";

const aPath = "/images/cursors/";
const aPath2 = "/images/cursors/med/100_200/";

let CursorList: Readonly<ImageCursor[]>;
if (process.env.COMPANY_ANNOTATION_DISPLAY_MODE === "itm") {
  CursorList = [
    {
      src: aPath + "rotating-y.png",
      origin: [50, 50],
      imgType: "imgType1",
    },
    {
      src: aPath + "rotating-yL.png",
      origin: [50, 50],
      imgType: "imgType2",
    },
    {
      src: aPath + "attention.png",
      origin: [50, 50],
      imgType: "imgType3",
    },
    {
      src: aPath + "attention2.png",
      origin: [50, 50],
      imgType: "imgType4",
    },
    {
      src: aPath2 + "annotation_1_kanshi_t.png",
      origin: [50, 50],
      imgType: "kanshi",
    },
    {
      src: aPath2 + "annotation_1_hikinuku_200_t.png",
      origin: [50, 50],
      imgType: "hikinuku",
    },
    {
      src: aPath2 + "annotation_1_kaiten_200_t.png",
      origin: [50, 50],
      imgType: "kaiten",
    },
    {
      src: aPath2 + "annotation_1_keisoku_200_t.png",
      origin: [50, 50],
      imgType: "keisoku",
    },
    {
      src: aPath2 + "annotation_1_oshikomu_200_t.png",
      origin: [50, 50],
      imgType: "oshikomu",
    },
    {
      src: aPath2 + "annotation_1_sashikomu_200_t.png",
      origin: [50, 50],
      imgType: "sashikomu",
    },
    {
      src: aPath2 + "annotation_1_seisou_200_t.png",
      origin: [50, 50],
      imgType: "seisou",
    },
    {
      src: aPath2 + "annotation_1_syadan_200_t.png",
      origin: [50, 50],
      imgType: "syadan",
    },
  ];
} else {
  CursorList = [
    {
      src: aPath + "rotating-y.png",
      origin: [50, 50],
      imgType: "imgType1",
    },
    {
      src: aPath + "rotating-yL.png",
      origin: [50, 50],
      imgType: "imgType2",
    },
    {
      src: aPath + "attention.png",
      origin: [50, 50],
      imgType: "imgType3",
    },
  ];
}

export { CursorList };
