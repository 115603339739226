import { PayloadAction } from "@reduxjs/toolkit";
import { MuteType } from "ricoh-ls-sdk";
import { LAYOUT } from "../../utils/constants";
import { RoomMember, RoomMembers } from "../../utils/types";
import { MainState } from "../slice";

export const updateLocalAudio = (
  state: MainState,
  action: PayloadAction<{ audioTrack?: MediaStreamTrack; audioDeviceId?: string }>
): void => {
  const { audioTrack, audioDeviceId } = action.payload;
  if (audioDeviceId) {
    state.audioDeviceId = audioDeviceId;
  }

  if (!audioTrack) return;

  state.immutable.localTracks.forEach((track) => {
    if (track.mediaStreamTrack.kind === "audio") {
      track.mediaStreamTrack = audioTrack;
    }
  });

  const videoTracks = state.immutable.localVideoAudioStream ? state.immutable.localVideoAudioStream.getVideoTracks() : [];
  const stream = new MediaStream([...videoTracks, audioTrack]);
  state.immutable.localVideoAudioStream = stream;
  if (state.self.connectionId) {
    const mediaStreams = new Map(state.immutable.audioStreams);
    mediaStreams.set(state.self.connectionId, stream);
    state.immutable.audioStreams = mediaStreams;
  }
};

export const updateLocalVideo = (
  state: MainState,
  action: PayloadAction<{ videoTrack?: MediaStreamTrack; videoDeviceId?: string }>
): void => {
  const { videoTrack, videoDeviceId } = action.payload;
  if (videoDeviceId) {
    state.videoDeviceId = videoDeviceId;
  }

  if (!videoTrack) {
    return;
  }

  state.immutable.localTracks.forEach((track) => {
    if (track.mediaStreamTrack.kind === "video") {
      track.mediaStreamTrack = videoTrack;
    }
  });

  const audioTracks = state.immutable.localVideoAudioStream ? state.immutable.localVideoAudioStream.getAudioTracks() : [];
  const stream = new MediaStream([...audioTracks, videoTrack]);
  state.immutable.localVideoAudioStream = stream;
  if (state.self.connectionId) {
    const mediaStreams = new Map(state.immutable.videoStreams);
    mediaStreams.set(state.self.connectionId, stream);
    state.immutable.videoStreams = mediaStreams;
  }
};

export const changeMuteState = (
  state: MainState,
  action: PayloadAction<{ connectionId: string; mediaStreamTrack: MediaStreamTrack; mute: MuteType }>
): void => {
  const { connectionId, mediaStreamTrack, mute } = action.payload;

  // unmute が通知された際に対象の MediaStream を更新する
  if (mute === "unmute") {
    if (mediaStreamTrack.kind === "audio") {
      const audioStreams = new Map(state.immutable.audioStreams);
      audioStreams.set(connectionId, new MediaStream([mediaStreamTrack]));
      state.immutable.audioStreams = audioStreams;
    } else if (mediaStreamTrack.kind === "video") {
      const videoStreams = new Map(state.immutable.videoStreams);
      videoStreams.set(connectionId, new MediaStream([mediaStreamTrack]));
      state.immutable.videoStreams = videoStreams;
    }
  }

  // otherMembers の mute 更新
  const otherMembers: RoomMembers = new Map(state.immutable.otherMembers);
  const updateMember = otherMembers.get(connectionId);
  if (updateMember) {
    if (mediaStreamTrack.kind === "audio") {
      const enableAudio = mute === "unmute";
      otherMembers.set(connectionId, { ...updateMember, enableAudio });
    } else if (mediaStreamTrack.kind === "video") {
      const enableVideo = mute === "unmute";
      otherMembers.set(connectionId, { ...updateMember, enableVideo });
    }
    state.immutable.otherMembers = otherMembers;
  }
};

export const changeSpeakerDevice = (state: MainState, action: PayloadAction<string | null>): void => {
  const selectedSpeakerId = action.payload;
  state.selectedSpeakerId = selectedSpeakerId;
};

export const changeToFullscreenLayout = (state: MainState, action: PayloadAction<string>): void => {
  const connectionId = action.payload;
  // Fullscreen Layout に変更して、 mainConnectionId を更新する
  state.previousLayout = state.currentLayout;
  state.currentLayout = LAYOUT.FULLSCREEN;
  state.mainConnectionIds = [connectionId];
};

export const finishFullscreenLayout = (state: MainState): void => {
  // previous layout に戻す
  const previousLayout = state.previousLayout || LAYOUT.GALLERY;
  state.previousLayout = state.currentLayout;
  state.currentLayout = previousLayout;
  // gallery layout に戻った場合は、mainConnectionId をクリアする
  if (state.currentLayout === LAYOUT.GALLERY) {
    state.mainConnectionIds = [];
  }
};

export const changeToGalleryLayout = (state: MainState): void => {
  if (state.currentLayout !== LAYOUT.GALLERY) {
    state.previousLayout = state.currentLayout;
  }
  // Gallery Layout に変更して、 mainConnectionId をクリアする
  state.currentLayout = LAYOUT.GALLERY;
  state.mainConnectionIds = [];
};

export const changeCurrentLayout = (state: MainState, action: PayloadAction<LAYOUT>): void => {
  const layout = action.payload;
  switch (layout) {
    case LAYOUT.PRESENTATION: {
      const member: RoomMember | undefined = state.immutable.otherMembers.values().next().value;
      let connectionId: string | null = null;
      if (member === undefined || member.connectionId === null) {
        connectionId = state.self.connectionId;
      } else {
        // 自分以外の任意の1拠点を選択
        connectionId = member.connectionId;
      }
      if (connectionId === null) {
        return;
      }
      // PL にレイアウトを切り替える際は mainConnectionIds をクリアする
      state.mainConnectionIds = [];
      state.previousLayout = state.currentLayout;
      state.currentLayout = LAYOUT.PRESENTATION;
      state.mainConnectionIds = [connectionId];
      break;
    }
    case LAYOUT.FULLSCREEN: {
      const member: RoomMember | undefined = state.immutable.otherMembers.values().next().value;
      let connectionId: string | null = null;
      if (member === undefined || member.connectionId === null) {
        connectionId = state.self.connectionId;
      } else {
        // 自分以外の任意の1拠点を選択
        connectionId = member.connectionId;
      }
      if (connectionId === null) {
        return;
      }
      state.previousLayout = state.currentLayout;
      state.currentLayout = LAYOUT.FULLSCREEN;
      state.mainConnectionIds = [connectionId];
      break;
    }
    case LAYOUT.GALLERY: {
      state.previousLayout = state.currentLayout;
      state.currentLayout = LAYOUT.GALLERY;
      // gallery layout に戻った場合は、mainConnectionId をクリアする
      state.mainConnectionIds = [];
      break;
    }
  }
};

export const addMainVideo = (state: MainState, action: PayloadAction<string>): void => {
  const connectionId = action.payload;
  // Presentation Layout に変更して、 mainConnectionId を更新する
  if (state.currentLayout !== LAYOUT.PRESENTATION) {
    state.mainConnectionIds = [];
    state.previousLayout = state.currentLayout;
  }
  const connectionIds = state.mainConnectionIds;
  state.currentLayout = LAYOUT.PRESENTATION;
  connectionIds.push(connectionId);
  // connectionIds の重複を避けるため new Set() に代入する
  state.mainConnectionIds = [...new Set(connectionIds)];
};

export const removeMainVideo = (state: MainState, action: PayloadAction<string>): void => {
  const connectionId = action.payload;
  const mainConnectionIds = state.mainConnectionIds;
  const filteredMainConnectionIds = mainConnectionIds.filter((id) => id !== connectionId);

  // main connection ids が空になった場合 Gallery Layout に変更する
  if (filteredMainConnectionIds.length === 0) {
    if (state.currentLayout !== LAYOUT.GALLERY) {
      state.previousLayout = state.currentLayout;
    }
    state.currentLayout = LAYOUT.GALLERY;
  }

  state.mainConnectionIds = filteredMainConnectionIds;
};

export const changeCameraEnabled = (state: MainState, action: PayloadAction<boolean>): void => {
  const enabled = action.payload;
  const mute = enabled ? "unmute" : state.muteType;
  state.immutable.localTracks.forEach((track) => {
    if (state.immutable.videoAudioClient && track.mediaStreamTrack.kind === "video") {
      state.immutable.videoAudioClient.changeMute(track, mute);
      track.mediaStreamTrack.enabled = enabled;
    }
  });
  state.self = { ...state.self, enableVideo: enabled };
};

export const changeMicEnabled = (state: MainState, action: PayloadAction<boolean>): void => {
  const enabled = action.payload;
  const mute = enabled ? "unmute" : state.muteType;
  state.immutable.localTracks.forEach((track) => {
    if (state.immutable.videoAudioClient && track.mediaStreamTrack.kind === "audio") {
      state.immutable.videoAudioClient.changeMute(track, mute);
      track.mediaStreamTrack.enabled = enabled;
    }
  });
  state.self = { ...state.self, enableAudio: enabled };
};
