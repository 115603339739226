// Room において、 Layout に関わらずすべての参加ストリームのオーディオタグを(不可視に)並べる領域
// UI 上は表示しないが、音声を流すためにレンダリングしている
// 順番は考慮しない
import React, { MutableRefObject, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, styled } from "@mui/material";

import { RootState } from "../redux/store";
import { RoomMember } from "../utils/types";

interface InvisibleAudioViewProps {
  stream: MediaStream | null;
  isMute: boolean;
}

const CustomizedBox = styled(Box)(() => ({
  visibility: "hidden",
  position: "absolute",
  width: 0,
  height: 0,
}));

const InvisibleAudio: React.FC<InvisibleAudioViewProps> = (props) => {
  const { stream, isMute } = props;
  const audioRef: MutableRefObject<HTMLAudioElement> = useRef() as MutableRefObject<HTMLAudioElement>;
  useLayoutEffect(() => {
    if (!audioRef.current || !stream) {
      return;
    }
    audioRef.current.srcObject = stream;
    // XXX(kdxu): muted tag がきかない場合があるので、その暫定対策
    // React のバージョンを上げると治る気がする
    // cf: https://qiita.com/takujiro_0529/items/8ebee4e7daf009d9cc15
    audioRef.current.volume = isMute ? 0 : 1;
    audioRef.current.muted = isMute;

    // unmount時にaudioRef.currentが変化している可能性があるため、別変数で参照を保持する
    const currentEl = audioRef.current;

    // Chrome 92 から WebMediaPlayer数の上限(PCは75,Mobileは40)が設定され、それを超えるとWebMediaPlayerの生成がブロックされることになった
    // cf: https://chromium-review.googlesource.com/c/chromium/src/+/2816118
    // この対応として、unmount時にsrcObjectを都度解放することで再描画のたびにWebMediaPlayer数がカウントアップされるのを防ぐ
    return () => {
      if (currentEl) {
        currentEl.srcObject = null;
      }
    };
  }, [audioRef, stream, isMute]);
  return <audio ref={audioRef} controls={false} autoPlay playsInline />;
};

interface InvisibleAudioItemProps {
  member: RoomMember;
  isSelf: boolean;
}

const InvisibleAudioItem: React.FC<InvisibleAudioItemProps> = (props) => {
  const { member, isSelf } = props;
  const { immutable } = useSelector((state: RootState) => state.main);
  const { audioStreams, screenShareStreams } = immutable;
  if (!member.connectionId) {
    return null;
  }
  const audioStream = audioStreams.get(member.connectionId) || null;
  const screenShareStream = (member.screenShareConnectionId && screenShareStreams.get(member.screenShareConnectionId)) || null;
  return (
    <>
      {member.connectionId && <InvisibleAudio stream={audioStream} isMute={isSelf} />}
      {member.screenShareConnectionId && <InvisibleAudio stream={screenShareStream} isMute={isSelf} />}
    </>
  );
};

const InvisibleAudios: React.FC<Record<string, never>> = () => {
  const { self, immutable } = useSelector((state: RootState) => state.main);
  const { otherMembers } = immutable;
  return (
    <CustomizedBox component="div">
      {/* 他参加者の画面共有・ビデオ */}
      {Array.from(otherMembers.values()).map((member, index) => {
        return <InvisibleAudioItem key={index} member={member} isSelf={false} />;
      })}
      {/* 自分の画面共有・ビデオ */}
      <InvisibleAudioItem member={self} isSelf />
    </CustomizedBox>
  );
};

export default InvisibleAudios;
