import React, { memo } from "react";
import { Snackbar, Box, Alert } from "@mui/material";
import { AlertSeverity } from "../utils/types";

interface SnackbarProps {
  message: string;
  severity: AlertSeverity;
  open: boolean;
  handleClose: () => void;
}

const SnackbarComponent: React.FC<SnackbarProps> = memo(({ message, severity, open, handleClose }) => {
  return (
    <Box
      sx={{
        zIndex: 3,
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert variant="filled" severity={severity} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default SnackbarComponent;
