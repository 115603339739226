import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";

// ダブルクリックのインターバル制限 (ms)
const DOUBLE_TAP_THRESHOLD = 300;

interface Props {
  width: number;
  height: number;
  onDoubleClick?: () => void;
  onMouseMove?: () => void;
  onTouchEnd?: () => void;
}

const CustomizedBox = styled(Box)(() => ({
  position: "relative",
}));

const Frame = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, ref) => {
  const { width, height, onDoubleClick, onMouseMove, onTouchEnd, children } = props;

  // mobile Safari だと onDoubleClick が発火しないので、mobile の場合は onTouchEnd + timer でダブルタップを判定している
  // ただし onTouchEnd が THETA などの 2 本指操作やドラッグまで検知してしまうので
  // isDragging という state をもたせて onTouchMove イベントを検知して、ドラッグ中かどうかを判定している
  // TODO(kdxu): 状態が複雑なので、より良い書き方がないか検討する
  const clickTimer = useRef<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const judgeDoubleClick = useCallback(() => {
    // ダブルタップ/クリックの判定処理
    // タイマーがすでにセットされている場合はダブルクリックとみなす
    if (clickTimer.current) {
      window.clearTimeout(clickTimer.current);
      clickTimer.current = null;
      onDoubleClick && onDoubleClick();
      return;
    }
    // タイマーがセットされていない場合はセットして、ダブルクリックかどうか判定する
    clickTimer.current = window.setTimeout(() => {
      clickTimer.current = null;
    }, DOUBLE_TAP_THRESHOLD);
  }, [clickTimer, onDoubleClick]);
  // ドラッグイベントかどうかを検知しておく
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>): void => {
    e.preventDefault();
    const isDragEvent = isDragging;
    setIsDragging(false);
    // ドラッグ操作 or 指 2 本以上の操作の際はタップイベントとみなさない
    if (isDragEvent || e.touches.length > 0) {
      return;
    }
    judgeDoubleClick();
    onTouchEnd && onTouchEnd();
  };
  //const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
  const handleClick = (e: React.PointerEvent<HTMLDivElement>): void => {
    e.preventDefault();
    judgeDoubleClick();
  };
  useEffect(() => {
    return () => {
      if (clickTimer.current) {
        window.clearTimeout(clickTimer.current);
        clickTimer.current = null;
      }
    };
  }, []);
  return (
    <CustomizedBox
      component="div"
      style={{ height, width }}
      onMouseMove={onMouseMove}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </CustomizedBox>
  );
});

export default Frame;
