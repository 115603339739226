import { ImageMember } from "@/scripts/utils/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { MainState } from "../slice";
import { v4 as uuidv4 } from "uuid";
const IMAGE_USER_NAME = "image";

export const setImageMember = (state: MainState, action: PayloadAction<string>): void => {
  const newImageMember: ImageMember = {
    connectionId: state.imageMember?.connectionId || uuidv4(),
    username: IMAGE_USER_NAME,
    src: action.payload,
  };
  state.imageMember = newImageMember;
};
