import { Client, ConnectionID, LSTrack, MuteType, SendingVideoOption } from "@/lib/ricoh-ls-sdk";
import { fetchConnectionParams } from "../../utils/api";
import { AUTO_DISCONNECT_INTERVAL_MSEC, DUMMY_DEVICE_ID, ROLE, VIDEO_PRIORITY } from "../../utils/constants";
import { getDummyMedia, getVideoTracks, isDebugPodUser, isDebugThetaUser } from "../../utils/liveStreamingHelpers";
import { CanvasElement, LocalConnectOption, MEDIA_TYPES, ThetaVideoFormat } from "../../utils/types";
import { actions } from "../slice";
import { AppDispatch, RootState, RootThunk } from "../store";
import { asyncReplaceVideoWithAutoReboot } from "./videoReplace";

const isCaptureStreamSupported = "function" === typeof (HTMLCanvasElement.prototype as CanvasElement).captureStream;

export const asyncConnect =
  ({
    accessToken,
    connectionId,
    connectOption,
    role,
  }: {
    accessToken: string;
    connectionId: ConnectionID;
    connectOption: LocalConnectOption;
    role?: ROLE;
  }): RootThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { main: mainState } = getState();
    const { username, enableAudio, enableVideo, maxVideoBitrate, useDummyDevice, signalingURL, videoCodec } = connectOption;
    // console.log(`asyncConnect. main=%O`,mainState);
    // console.log(`asyncConnect. username=${username} enableVideo=${enableVideo} maxVideoBitrate=${maxVideoBitrate} username=${username}
    // useDummyDevice=${useDummyDevice} signalingURL=${signalingURL} videoCodec=${videoCodec}`);
    dispatch(actions.startConnect({ connectionId, clientId: BUILD_CONFIG.LS_CLIENT_ID }));

    let useDummyDeviceActual = useDummyDevice && isCaptureStreamSupported;
    let stream: MediaStream | null = null;

    try {
      if (!useDummyDeviceActual) {
        // video: false にしてもSFUからは VideoStream が送信される.
        // mute を hardmute にしたダミートラックでLSTrackを作ることで映像を送らない.
        const dummyVideoTrack = enableVideo ? null : await getDummyVideoTrack();
        const constraints: MediaStreamConstraints = {
          video: dummyVideoTrack == null && {
            aspectRatio: 16 / 9,
          },
          audio: true,
        };
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (dummyVideoTrack != null) {
          stream.addTrack(dummyVideoTrack);
        }
      } else {
        stream = await getDummyMedia();
      }
    } catch (error) {
      console.error("Failed to get device.");
      if (isCaptureStreamSupported) {
        stream = await getDummyMedia();
        useDummyDeviceActual = true;
      }
    }

    if (!enableVideo) {
      stream.getVideoTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }

    if (!enableAudio) {
      stream.getAudioTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }

    const videoTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : undefined;
    if (videoTrack) {
      videoTrack.addEventListener("ended", () => {
        // iOS@14.2 + Safari@14.0.1 にてカメラストリームとHTMLビデオ要素を作成/停止/再作成/アタッチ/デタッチ/再アタッチすると
        // ビデオが停止状態になる問題に対処するため、ended イベントを受信したら再割り当てを行う
        asyncReplaceVideoWithAutoReboot(dispatch, getState, mainState.videoDeviceId);
      });
    }

    const client = new Client();
    client.on("close", () => {
      // console.log("video audio client closed.");
      console.warn("video audio client closed.");
    });
    client.on("error", () => {
      // console.log("video audio client error, =>", e);
      console.error("video audio client error");
      // NOTE: LSConfのコードではfailConnectが使われていないのでとりあえずここで呼んでおく
      dispatch(actions.failConnect());
    });
    client.on(
      "addremotetrack",
      ({
        connection_id,
        mediaStreamTrack,
        stream,
        meta,
        mute,
      }: {
        connection_id: string;
        mediaStreamTrack: MediaStreamTrack;
        stream: MediaStream;
        meta?: Record<string, unknown>;
        mute: MuteType;
      }) => {
        if (meta === undefined) {
          return;
        }
        dispatch(
          actions.addRemoteTrack({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            connectionId: connection_id,
            mediaStreamTrack: mediaStreamTrack,
            stream: stream,
            meta: {
              // mediaType のプロパティ指定が無い場合 VIDEO_AUDIO とする
              mediaType: (meta.mediaType as MEDIA_TYPES) || MEDIA_TYPES.VIDEO_AUDIO,
              // isTheta のプロパティ指定が無い場合 false とする
              isTheta: (meta.isTheta as boolean) || false,
              thetaVideoFormat: meta.thetaVideoFormat as ThetaVideoFormat,
              // isPod のプロパティ指定が無い場合 false とする
              isPod: (meta.isPod as boolean) || false,
            },
            mute: mute,
          })
        );
      }
    );
    client.on("addremoteconnection", ({ connection_id, meta }: { connection_id: string; meta: Record<string, unknown> }) => {
      dispatch(
        actions.addRemoteConnection({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          connectionId: connection_id,
          meta: {
            username: meta.username as string,
            mediaType: meta.mediaType as MEDIA_TYPES,
            parentConnectionId: meta.parentConnectionId as string,
          },
        })
      );
      dispatch(actions.clearAutoDisconnectTimer());
    });
    client.on("removeremoteconnection", ({ connection_id, meta }: { connection_id: string; meta: Record<string, unknown> }) => {
      dispatch(
        actions.leave({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          connectionId: connection_id,
          meta: {
            username: meta.username as string,
            mediaType: meta.mediaType as MEDIA_TYPES,
            parentConnectionId: meta.parentConnectionId as string,
          },
        })
      );
      //dispatch(asyncSetAutoDisconnectTimer());
    });
    client.on("updatemute", (e) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dispatch(actions.changeMuteState({ connectionId: e.connection_id, mediaStreamTrack: e.mediaStreamTrack, mute: e.mute }));
    });

    const trackMetadata = {
      mediaType: MEDIA_TYPES.VIDEO_AUDIO,
      isTheta: isDebugThetaUser(username),
      thetaVideoFormat: null,
      isPod: isDebugPodUser(username),
    };
    const tracks: LSTrack[] = [];
    stream.getTracks().forEach((track) => {
      let muteState: MuteType = "unmute";
      if (track.kind === "audio") {
        muteState = enableAudio ? "unmute" : mainState.muteType;
      } else if (track.kind === "video") {
        muteState = enableVideo ? "unmute" : mainState.muteType;
      }
      tracks.push(new LSTrack(track, stream, { meta: trackMetadata, mute: muteState }));
    });

    const connectionMetadata = {
      username: username,
      mediaType: MEDIA_TYPES.VIDEO_AUDIO,
      parentConnectionId: null,
    };
    const videoSpec: SendingVideoOption = {
      codec: videoCodec,
      priority: VIDEO_PRIORITY,
      maxBitrateKbps: maxVideoBitrate,
    };
    client.connect(BUILD_CONFIG.LS_CLIENT_ID, accessToken, {
      localLSTracks: tracks,
      signalingURL: signalingURL,
      meta: connectionMetadata,
      receiving: { enabled: role !== "sendonly" },
      sending: { video: videoSpec },
    });
    dispatch(
      actions.successConnect({
        stream,
        username,
        connectionId,
        client,
        tracks,
        useDummyDevice: useDummyDeviceActual,
        enableAudio,
        enableVideo,
      })
    );
  };

async function getDummyVideoTrack(): Promise<MediaStreamTrack | null> {
  try {
    const dummyVideoTracks = await getVideoTracks(DUMMY_DEVICE_ID);
    return dummyVideoTracks[0];
  } catch (error) {
    console.error("Failed to get dummy media.", error);
    return null;
  }
}

export const asyncSetAutoDisconnectTimer = (): RootThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { otherMembers } = getState().main.immutable;
  if (otherMembers.size > 0) return;

  const timer = window.setTimeout(() => {
    dispatch(actions.disconnect());
  }, AUTO_DISCONNECT_INTERVAL_MSEC);
  dispatch(actions.setAutoDisconnectTimer(timer));

  // console.log(`Auto disconnect timer has been set. Disconnect after ${AUTO_DISCONNECT_INTERVAL_MSEC / 1000} seconds.`);
  console.warn(`Auto disconnect timer has been set.`);
};

export const asyncReconnect =
  ({ connectOption, roomId }: { connectOption: LocalConnectOption; roomId: string }): RootThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { isReconnecting } = getState().main;
    if (isReconnecting) return;

    dispatch(actions.updateIsReconnecting({ isReconnecting: true }));
    try {
      dispatch(actions.stopConnect());

      const connectionParams = await fetchConnectionParams(roomId);
      await dispatch(
        asyncConnect({
          accessToken: connectionParams.access_token,
          connectionId: connectionParams.connection_id,
          connectOption,
        })
      );
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      dispatch(actions.updateIsReconnecting({ isReconnecting: false }));
    }

    await fetchConnectionParams(roomId).then(async (connectionParams) => {
      await dispatch(
        asyncConnect({
          accessToken: connectionParams.access_token,
          connectionId: connectionParams.connection_id,
          connectOption,
        })
      );
    });
  };
