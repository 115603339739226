// iframe を表示するページ
import qs from "query-string";
import React, { useCallback, useEffect, useLayoutEffect, useState, useRef } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
import { Box, styled } from "@mui/material";

import { fetchConnectionParams } from "@/scripts/utils/api";
import GeneralDialog from "@/scripts/components/generalDialog";
import { LSConfParameters, LocalConnectOption, RoomMember, RoomMembers, ImageMember } from "../utils/types";
import {
  convert2Boolean,
  convert2NumberBitrate,
  convert2NumberBitrateReservation,
  convert2RoomType,
  convert2String,
} from "../utils/convertors";
// import ConnectRequestDialog from "../components/connectRequestDialog";
// import CanvasDialog from "../components/canvasDialog";
import CanvasDialogForVideo from "../components/canvasDialogForVideo";
import Room from "./room";
import { RootState, useAppDispatch } from "../redux/store";
import { actions } from "../redux/slice";
import { asyncConnect } from "../redux/asyncActionCreators/connection";
import { useSelector } from "react-redux";
import { DEFAULT_VIDEO_CODEC, LAYOUT, MESSAGE_TYPE } from "../utils/constants";
import { ConnectionID } from "ricoh-ls-sdk";
import { getCaptureImage, getImageSize } from "../utils/liveStreamingHelpers";
import { usePrevious } from "../utils/customHooks";
import { WebSocketContext } from "../utils/webSocket";
import i18n from "@/locales/i18n";

let hasSetRemoteAutoFullScreen = false;
const useLsSdk = ({
  roomId,
  username,
  video_bitrate,
  share_bitrate,
  use_dummy_device,
  bitrate_reservation_mbps,
  room_type,
}: LSConfParameters) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedView, setSelectedView] = useState<ConnectionID | null>(null);
  const [connectOption, setConnectOption] = useState<LocalConnectOption | null>(null);
  const dispatch = useAppDispatch();

  const resetError = () => setErrorMessage(null);

  const createAndConnectRoom = async (cancellationToken: { cancel?: () => void } = null): Promise<void> => {
    return new Promise((resolve, reject) => {
      const controller = new AbortController();
      cancellationToken.cancel = () => {
        controller.abort();
        return reject();
      };
      if (!username || !roomId || typeof username !== "string") {
        // 現在 ls-conf-sdk への対応と同様にエラーをそのまま errorMessage に入れている
        // TODO(kdxu): ls-conf-sdk のシステムエラーに対するユーザーへのメッセージが仕様として策定され次第、こちらのエラー文言も合わせて修正する
        setErrorMessage("INVALID-PARAMETERS");
        return reject();
      }
      fetchConnectionParams(roomId, controller.signal, bitrate_reservation_mbps?.toString(), room_type)
        .then((connectionParams) => {
          const connectOption: LocalConnectOption = {
            username: username,
            enableVideo: false,
            enableAudio: true,
            maxVideoBitrate: video_bitrate,
            maxShareBitrate: share_bitrate,
            useDummyDevice: use_dummy_device || false,
            signalingURL: BUILD_CONFIG.LS_SIGNALING_URL,
            videoCodec: DEFAULT_VIDEO_CODEC,
          };
          dispatch(
            asyncConnect({
              accessToken: connectionParams.access_token,
              connectionId: connectionParams.connection_id,
              connectOption,
            })
          );
          setSelectedView(connectionParams.connection_id);
          setConnectOption(connectOption);
          return resolve();
        })
        .catch((error) => {
          // 中断済み＝コンポーネントがアンマウント済みなのでstateを更新しない
          if (!controller.signal.aborted) {
            setErrorMessage(error?.message || "Unexpected Error");
          }
          return reject();
        });
    });
  };

  return {
    createAndConnectRoom,
    resetError,
    errorMessage,
    setErrorMessage,
    selectedView,
    setSelectedView,
    connectOption,
  };
};

const CustomizedPageLayout = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
}));

const CustomizedIframeContainer = styled(Box)(() => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  // "-webkit-overflow-scrolling": "touch", // mobile向け
  overflowY: "scroll", // mobile向け
  // "&::-webkit-scrollbar": {
  // mobile向け
  // display: "none",
  // },
  //background: "black",
  //margin: "0",
  //padding: "0",
}));

const AskRemoteSideToJoinFrame = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%, -50%)",
  "& p": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 36,
  },
}));

// const useStyles = makeStyles({
//   selector: {
//     display: "flex",
//     alignItems: "center",
//     minWidth: "27em",
//     justifyContent: "space-between",
//     margin: "0 1em",
//   },
//   captureMenu: {
//     display: "flex",
//     margin: "1em",
//     justifyContent: "flex-end",
//   },
//   toggleCursorButton: {
//     margin: "0 0 0 1em",
//   },
//   Button2: {
//     margin: "0 0 0 1em",
//     //backgoundColor: "fccb00",
//   },
// });

const getMemberConnectionIdNames = (self: RoomMember, otherMembers: RoomMembers, imageMember: ImageMember) => {
  const otherMembersArray = Array.from(otherMembers.values());
  const otherMemberIds = [];
  const otherMemberNames = [];
  const otherMemberScreenShareIds = [];
  otherMembersArray.forEach((member) => {
    otherMemberIds.push(member.connectionId);
    otherMemberNames.push(member.username);
    member.screenShareConnectionId && otherMemberScreenShareIds.push(member.screenShareConnectionId);
  });
  if (imageMember) {
    otherMemberIds.push(imageMember.connectionId);
    otherMemberNames.push(imageMember.username);
  }
  const selfScreenShareId = [];
  self.screenShareConnectionId && selfScreenShareId.push(self.screenShareConnectionId);
  const memberConnectionIds: ConnectionID[] = [self.connectionId]
    .concat(otherMemberIds)
    .concat(otherMemberScreenShareIds)
    .concat(selfScreenShareId);
  const memberConnectionNames: ConnectionID[] = [self.username]
    .concat(otherMemberNames)
    .concat(otherMemberScreenShareIds)
    .concat(selfScreenShareId);
  return [memberConnectionIds, memberConnectionNames];
};

const IframePage: React.FC<Record<string, never>> = () => {
  //console.log("IframePage!!!!!!!!!!!!!!!");
  // const classes = useStyles();
  const mainRef = useRef<HTMLDivElement>(null);
  const [image, setImage] = useState<string | null>(null);
  const [imageWidth, setImageWidth] = useState<number | null>(null);
  // const [isCaptureDisabled, setDisabled] = useState(false);
  const [viewReady, setViewReady] = useState(false);
  const { isOpened, isConnected, glassCalibrationReceived, sendMessage } = useContext(WebSocketContext);
  const { username, video_bitrate, share_bitrate, use_dummy_device, bitrate_reservation_mbps, room_type } = qs.parse(
    window.location.search
  );
  const { imageMember } = useSelector((state: RootState) => state.main);
  // const { imageMember, largeCursor } = useSelector((state: RootState) => state.main);
  const [showConnectMsg, setShowConnectMsg] = useState(false);

  const { roomId } = useParams<{ roomId: string }>();
  sessionStorage.setItem("myRoomId", roomId);

  const dispatch = useAppDispatch();
  const { errorMessage, resetError, createAndConnectRoom, selectedView, setSelectedView, connectOption } = useLsSdk({
    roomId,
    username: convert2String(username) || "",
    video_bitrate: convert2NumberBitrate(video_bitrate),
    share_bitrate: convert2NumberBitrate(share_bitrate),
    use_dummy_device: convert2Boolean(use_dummy_device),
    bitrate_reservation_mbps: convert2NumberBitrateReservation(bitrate_reservation_mbps),
    room_type: convert2RoomType(room_type),
  });
  const state = useSelector((state: RootState) => state.main);
  const prevIsJoind = usePrevious(state.isJoined);
  const onCloseErrorDialog = (): void => {
    resetError();
  };

  const cancellationToken = {
    // 型警告が出るためダミーのメソッドを定義しておく
    cancel: () => {
      console.log("This is dummy.");
    },
  };
  useLayoutEffect(() => {
    // ここでcatchしておかないとUncaughtの警告が出る
    // createAndConnectRoom(cancellationToken).catch(() => console.log("Promise in createAndConnectRoom has been rejected."));
    createAndConnectRoom(cancellationToken).catch(() => console.warn("Promise has been rejected."));

    return () => {
      cancellationToken.cancel();
    };
  }, []);

  useEffect(() => {
    if (!isOpened) {
      return;
    }
    sendMessage({
      messageType: MESSAGE_TYPE.GLASS_CALIBRATION_REQUEST,
      roomId: roomId,
      timestamp: new Date().getTime(),
    });
  }, [isOpened]);

  useEffect(() => {
    if (prevIsJoind && !state.isJoined) {
      window.close();
      // 直接URLを指定して会議画面を開いた場合などは
      // タブを閉じることができないため、トップページに戻す
      window.location.href = "/";
    }
  }, [state.isJoined]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isConnected && isOpened && !glassCalibrationReceived) {
        setShowConnectMsg(true);
      } else if (isConnected && isOpened && glassCalibrationReceived) {
        setShowConnectMsg(false);
      }
    }, 1000);
    // ↑setTimeoutの待ち時間の数値は、isConnected, isOpened, glassCalibrationReceived の状態を確認するまでの待ち時間の数値。
    // この時間が短いと、動画が表示されるまでに「遠隔側に参加してもらうよう依頼してください」のメッセージが出る場合があるので、
    // ある程度長めの時間にしておく。
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isConnected, isOpened, glassCalibrationReceived]);

  const memberConnectionIdNames = getMemberConnectionIdNames(state.self, state.immutable.otherMembers, state.imageMember);
  const memberConnectionIds = memberConnectionIdNames[0];
  // const memberConnectionNames = memberConnectionIdNames[1];

  useEffect(() => {
    // もし画面選択で選択していたユーザが退出したら自分の画面を選択状態にする
    if (memberConnectionIds.indexOf(selectedView) < 0) {
      setSelectedView(state.self.connectionId);
    }
    if (memberConnectionIds.length <= 1) {
      hasSetRemoteAutoFullScreen = false;
    }
    if (!hasSetRemoteAutoFullScreen && memberConnectionIds.length > 1 && state.hasReceivedRemoteTrack) {
      let foundVSKey = null;

      for (let [key, vs] of state.immutable.videoStreams) {
        if (vs.active) {
          foundVSKey = key;
        }
      }
      if (foundVSKey == null) {
        // 遠隔側が再接続したときに、RemoteTrackの追加前に実行されることがあり、再接続できなくなる.
        return;
      }

      const remoteConnectionId = foundVSKey;
      // console.debug("The active video stream of the remote connection is available.", remoteConnectionId);
      setSelectedView(remoteConnectionId);
      dispatch(actions.changeToFullscreenLayout(remoteConnectionId));
      hasSetRemoteAutoFullScreen = true;

      if (memberConnectionIds.length > 1 && state.hasReceivedRemoteTrack) {
        // console.log("sendMessage CalibrationResultRequest");
        // request glass calibration result
        sendMessage({
          messageType: MESSAGE_TYPE.GLASS_CALIBRATION_REQUEST,
          roomId: roomId,
          timestamp: new Date().getTime(),
        });
      }
    }
  }, [memberConnectionIds]);

  const closeCanvasDialog = () => {
    setImage(null);
    setImageWidth(null);
    if (state.previousLayout == LAYOUT.FULLSCREEN) {
      dispatch(actions.changeToFullscreenLayout(selectedView));
    }
  };

  //console.log(`wxh: imageW=${imageWidth}`) // 640

  const prepareView = async () => {
    if (viewReady) {
      return;
    }

    try {
      sendMessage({
        messageType: MESSAGE_TYPE.GLASS_CALIBRATION_REQUEST,
        roomId: roomId,
        timestamp: new Date().getTime(),
      });
      let dataURI = "";
      if (selectedView === imageMember?.connectionId) {
        dataURI = imageMember.src;
      } else {
        const blob = await getCaptureImage({ state, targetConnectionId: selectedView });
        dataURI = URL.createObjectURL(blob);
        const imageSizeArr = await getImageSize(dataURI);
        setImageWidth(imageSizeArr[0]);
      }
      setImage(dataURI);
      setViewReady(true);
    } catch (error) {
      // console.log("camera muted(prepareView)");
      console.error("camera muted(prepareView)");
    }
  };

  useEffect(() => {
    if (viewReady) {
      return;
    }

    if (!state.isJoined) {
      return;
    }

    if (state.currentLayout !== LAYOUT.FULLSCREEN) {
      return;
    }

    prepareView();
  }, [viewReady, state.isJoined, state.currentLayout]);

  const onChangeMainViewWidth = useCallback(() => {
    if (!mainRef.current) {
      return;
    }
    const newViewWidth = Math.floor(mainRef.current.clientWidth);
    sessionStorage.setItem("videoViewerWidth", newViewWidth.toString());
    const newViewHeight = Math.floor(mainRef.current.clientHeight);
    sessionStorage.setItem("videoViewerHeight", newViewHeight.toString());
  }, [mainRef]);

  useLayoutEffect(() => {
    onChangeMainViewWidth();
    window.addEventListener("resize", onChangeMainViewWidth);
    return () => {
      window.removeEventListener("resize", onChangeMainViewWidth);
    };
  }, [mainRef]);

  return (
    <CustomizedPageLayout component="div" ref={mainRef}>
      <CustomizedIframeContainer component="div" id="ls-container">
        <Room username={convert2String(username)} roomId={roomId} />
      </CustomizedIframeContainer>
      <GeneralDialog open={errorMessage !== null} message={errorMessage} onClose={onCloseErrorDialog} />
      {/* <ConnectRequestDialog /> */}
      {/* {selectedView && (
        <div className={classes.captureMenu}>
          <div className={classes.selector}>
            <Typography color="textSecondary">{i18n.t("label.selectSubView")}:</Typography>
            
            <Select
              labelId="select-label"
              variant="outlined"
              autoWidth
              value={selectedView}
              onChange={async (event) => {
                const selected = memberConnectionIds.filter((member) => member === event.target.value)[0];
                setSelectedView(selected);
                try {
                  dispatch(actions.setHighlight({ targetConnectionId: selected, highlight: true }));
                  setDisabled(false);
                  if(state.currentLayout == LAYOUT.FULLSCREEN){
                    dispatch(actions.finishFullscreenLayout());
                    dispatch(actions.changeToFullscreenLayout(selected));
                  }
                } catch (error) {
                  setErrorMessage(i18n.t("message.error.exitedConnectionId"));
                  setDisabled(true);
                }
              }}
            >
              {memberConnectionIds.map((connectionId,index) => {
                return (
                  <MenuItem key={connectionId} value={connectionId}>
                    {memberConnectionNames[index]}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <Button
            className={classes.Button2}
            color="primary"
            variant="contained"
            disabled={isCaptureDisabled}
            onClick={async () => {
              try {
                if(state.currentLayout == LAYOUT.FULLSCREEN){
                  dispatch(actions.finishFullscreenLayout());
                }
                sendMessage({
                  messageType: MESSAGE_TYPE.REQ_ORIG_VIDEO_IMG,
                  roomId: roomId,
                  timestamp: new Date().getTime()
                });
                let dataURI = "";
                if (selectedView === imageMember?.connectionId) {
                  dataURI = imageMember.src;
                } else {
                  const blob = await getCaptureImage({ state, targetConnectionId: selectedView });
                  dataURI = URL.createObjectURL(blob);
                }
                setImage(dataURI);
                
              } catch (error) {
                setErrorMessage(i18n.t("message.error.cameraMuted"));
              }
            }}
          >
            {i18n.t("label.capture")}
          </Button>
          <span></span>
          <div></div>

          <Button
            className={classes.Button2}
            color="primary"
            variant="contained"
            disabled={isCaptureDisabled}
            onClick={async () => {
              try {
                if(state.currentLayout == LAYOUT.FULLSCREEN){
                  dispatch(actions.finishFullscreenLayout());
                }
                sendMessage({
                  messageType: MESSAGE_TYPE.REQ_ORIG_VIDEO_IMG,
                  "roomId": roomId,
                  timestamp: new Date().getTime()
                });
                sendMessage({
                  messageType: MESSAGE_TYPE.GLASS_CALIBRATION_REQUEST,
                  "roomId": roomId,
                  timestamp: new Date().getTime()
                });
                let dataURI = "";
                if (selectedView === imageMember?.connectionId) {
                  dataURI = imageMember.src;
                } else {
                  const blob = await getCaptureImage({ state, targetConnectionId: selectedView });
                  dataURI = URL.createObjectURL(blob);
                  const imageSizeArr = await getImageSize(dataURI);
                  setImageWidth(imageSizeArr[0]);
                }
                setImage(dataURI);
                
              } catch (error) {
                setErrorMessage(i18n.t("message.error.cameraMuted"));
              }
            }}
          >
            {i18n.t("label.markOnVideo")}
          </Button>
          
        </div>
      )} */}

      {/* <CanvasDialog
        image={image}
        imageWidth={imageWidth}
        connectionId={selectedView}
        roomId={roomId}
        frontendId={sessionStorage.getItem("frontendId")}
        onClose={() => closeCanvasDialog()}
      /> */}

      <CanvasDialogForVideo
        image={image}
        imageWidth={imageWidth}
        connectionId={selectedView}
        connectOption={connectOption}
        roomId={roomId}
        frontendId={sessionStorage.getItem("frontendId")}
        onClose={() => closeCanvasDialog()}
      />

      {showConnectMsg && (
        <AskRemoteSideToJoinFrame component="div">
          <p>{i18n.t("message.guide.askRemoteSideToJoin")}</p>
        </AskRemoteSideToJoinFrame>
      )}
    </CustomizedPageLayout>
  );
};
export default IframePage;
