// NOTE: canvasDialogForVideo.tsx 肥大化を防ぐため、GUIを分離 プロジェクトの規模に応じて都度集約、分解する
import React, { memo, useRef } from "react";
import { styled, OutlinedInput, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

const StyleOutlinedInput = styled(OutlinedInput)(() => ({
  height: "2.3em",
  margin: "0 1em 0 1.7em",
  paddingTop: "0.5em",
  paddingBottom: "0.5em",
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fccb00",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#fccb00",
      },
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#747473",
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#747473",
    },
  },
}));

interface ButtonGroupProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus: () => void;
  onClickClear: () => void;
  placeholder: string;
}

const Textarea: React.VFC<ButtonGroupProps> = memo(({ onChange, onFocus, onClickClear, placeholder }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div>
      <StyleOutlinedInput
        color="primary"
        multiline={true}
        maxRows={2}
        margin="none"
        onChange={onChange}
        onFocus={() => {
          onFocus();
        }}
        placeholder={placeholder}
        endAdornment={
          <IconButton
            size="small"
            onClick={() => {
              const textareaForm = inputRef.current;
              if (textareaForm) textareaForm.value = "";
              onClickClear();
            }}
          >
            <Clear fontSize="small" />
          </IconButton>
        }
        inputRef={inputRef}
      />
    </div>
  );
});

export default Textarea;
