import React, { useContext, useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";

import { styled, Dialog, DialogTitle, DialogContent, Slider, Box, Grid, Typography } from "@mui/material";

import { WebSocketContext } from "../utils/webSocket";
import * as CONST from "../utils/constants";
import * as canvasAction from "../utils/canvasActions";
import { AlertSeverity } from "../utils/types";
import { getElementById } from "../utils/domHelpers";

import CustomButton from "../atoms/customButton";

interface HandWritingDialogProps {
  open?: boolean;
  onClose?: () => void;
  onSendClose?: () => void;
  onShowSnackbar?: (severity: AlertSeverity, Message: string) => void;
  colorPalette: string[];
  penColor: string;
  myRoomId: string;
}

const CanvasWrapper = styled(Box)(() => ({
  "& canvas": {
    cursor: "pointer",
    border: "2px solid #747474",
    borderRadius: "5px",
    boxSizing: "content-box",
  },
}));

const DeviceSettingDialog: React.FC<HandWritingDialogProps> = memo((props) => {
  const { t } = useTranslation();
  const latestOpen = useRef(props.open);
  const [handWritingSize, setHandWritingSize] = useState(CONST.HANDWRITING_CANVAS.SIZE_OPTIONS.DEFAULT);
  const { sendMessage } = useContext(WebSocketContext);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState<number | null>(null);
  const [lastY, setLastY] = useState<number | null>(null);

  const handleOpen = async (): Promise<void> => {
    latestOpen.current = true;
  };

  const handleClose = async (): Promise<void> => {
    latestOpen.current = false;
    props.onClose();
  };

  const clearCanvas = () => {
    const canvas = getElementById("hand-writing-canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvasAction.drawingHistories.splice(0, canvasAction.drawingHistories.length);
  };

  const drawStart = (e: React.PointerEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    setIsDrawing(true);
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    setLastX(x);
    setLastY(y);
    e.stopPropagation();
  };

  const onMove = (e: React.PointerEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    if (!isDrawing) return;
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    drawHandWriting(lastX, lastY, x, y, handWritingSize, props.penColor);
    setLastX(x);
    setLastY(y);
    e.stopPropagation();
  };

  const drawHandWriting = (x1: number, y1: number, x2: number, y2: number, width: number, color: string) => {
    const canvas = document.getElementById("hand-writing-canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const line = { x1, y1, x2, y2, width };
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.lineCap = "round";
    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.stroke();
    canvasAction.addDrawingHistories(line);
  };

  const drawEnd = (e: React.PointerEvent<HTMLCanvasElement>) => {
    setIsDrawing(false);
    setLastX(null);
    setLastY(null);
    e.stopPropagation();
  };

  const drawSend = () => {
    const timestamp = new Date().getTime();
    const baseCanvas = getElementById("canvas") as HTMLCanvasElement;
    const baseCtx = baseCanvas.getContext("2d");
    baseCtx.clearRect(0, 0, baseCanvas.width, baseCanvas.height);
    canvasAction.drawBoundaryLR();
    canvasAction.restoreBufferCanvasFromHistory("DrawArrow", "DrawInputText");

    canvasAction.setIsClickForPlacementHandWriting(false);
    canvasAction.deleteCanvasHistoryByDrawTarget("DrawHandWriting");

    const handWritingCanvas = getElementById("hand-writing-canvas") as HTMLCanvasElement;

    // HACK:ディスプレイキャリブレーションに応じた手描きサイズに
    // const drawingMargin_x = 410;
    // const drawingMargin_y = 640;
    const x =
      Math.max(canvasAction.glassCalibrationResult.topRight_L_X, canvasAction.glassCalibrationResult.topRight_R_X) *
      baseCanvas.height;
    const y =
      Math.min(canvasAction.glassCalibrationResult.topRight_L_Y, canvasAction.glassCalibrationResult.topRight_R_Y) *
      baseCanvas.width;

    baseCtx.drawImage(
      handWritingCanvas,
      0,
      0,
      handWritingCanvas.width,
      handWritingCanvas.height,
      x,
      y,
      handWritingCanvas.width * 0.25,
      handWritingCanvas.height * 0.25
    );

    const img = new Image();
    const dataURL = handWritingCanvas.toDataURL();
    img.src = dataURL;

    canvasAction.clearScreenByDrawTarget(sendMessage, props.myRoomId, "DrawHandWriting");

    //buffer
    const bufferCanvas = document.getElementById("buffer_canvas") as HTMLCanvasElement;
    const bufferCtx = bufferCanvas.getContext("2d");
    bufferCtx.clearRect(0, 0, bufferCanvas.width, bufferCanvas.height);
    bufferCtx.drawImage(
      handWritingCanvas,
      0,
      0,
      handWritingCanvas.width,
      handWritingCanvas.height,
      x,
      y,
      handWritingCanvas.width * 0.25,
      handWritingCanvas.height * 0.25
    );
    canvasAction.saveCanvasHistory("DrawHandWriting", timestamp);
    // canvasAction.setDrawStart(true);

    // NOTE:手描き画像の追従機能。カラーパレットの色反映に対応するため、各色の手描き画像を作成
    props.colorPalette.forEach((color: string) => {
      canvasAction.generateLiveHandwritingImage(color);
    });
    canvasAction.drawingHistories.splice(0, canvasAction.drawingHistories.length);

    try {
      // const clearOverlayRequestMessage = {
      //   messageType: CONST.MESSAGE_TYPE.CLEAROVERLAYIMAGE,
      //   roomId: props.myRoomId,
      //   timestamp: timestamp,
      // };
      // sendMessage(clearOverlayRequestMessage);

      const overlayRequestMessage = {
        messageType: CONST.MESSAGE_TYPE.OVERLAYIMAGE,
        image: img.src,
        roomId: props.myRoomId,
        timestamp: timestamp,
      };
      sendMessage(overlayRequestMessage);

      props.onSendClose();
      props.onShowSnackbar("success", t("handWritingDialog.SuccessMessage"));
    } catch (e) {
      console.error("HandWriting Request Error");
      props.onClose();
      props.onShowSnackbar("error", t("handWritingDialog.ErrorMessage"));
    }
  };

  return (
    <Dialog open={props.open} TransitionProps={{ onEnter: handleOpen }} onClose={handleClose} maxWidth="md">
      <DialogTitle>{t("handWritingDialog.title")}</DialogTitle>

      <DialogContent>
        <CanvasWrapper>
          <canvas
            id="hand-writing-canvas"
            width={CONST.HANDWRITING_CANVAS.WIDTH}
            height={CONST.HANDWRITING_CANVAS.HEIGHT}
            onPointerDown={drawStart}
            onPointerMove={onMove}
            onPointerUp={drawEnd}
            onPointerOut={drawEnd}
            style={{ userSelect: "none", touchAction: "none" }}
          />
        </CanvasWrapper>

        <Box sx={{ pt: 3, pb: 5, background: "#f8f8f8" }}>
          <Box sx={{ width: "50%", p: 3, m: "auto" }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">{t("handWritingDialog.size")}</Typography>
              </Grid>
              <Grid item xs>
                <Slider
                  size="small"
                  color="primary"
                  valueLabelDisplay="auto"
                  step={CONST.HANDWRITING_CANVAS.SIZE_OPTIONS.STEP}
                  min={CONST.HANDWRITING_CANVAS.SIZE_OPTIONS.MIN}
                  max={CONST.HANDWRITING_CANVAS.SIZE_OPTIONS.MAX}
                  value={handWritingSize}
                  marks
                  onChange={(_event: Event, value: number) => {
                    setHandWritingSize(value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={3}>
                <CustomButton
                  color="secondary"
                  onClick={() => {
                    drawSend();
                  }}
                >
                  {t("handWritingDialog.send")}
                </CustomButton>
              </Grid>
              <Grid item xs={2}>
                <CustomButton
                  color="secondary"
                  onClick={() => {
                    {
                      clearCanvas();
                    }
                  }}
                >
                  {t("handWritingDialog.clear")}
                </CustomButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default DeviceSettingDialog;
